import api from "@/utils/api";
import envConfig from "@/utils/envConfig";
import {getData} from "@/apiCalls/taskApi";
import {OPEN_BANKING_FIELDS} from "@/utils/dtoFields";
import {OpenBankingRequestScope} from "@/utils/openBankingUtils";

export const callOpenbanking = ({
  journeyId,
  phoneNumber,
  mail,
  country,
  scope,
  isContinuousAccess,
}: {
  journeyId: string;
  phoneNumber: string | null;
  mail?: string | null;
  country: string;
  isContinuousAccess: boolean;
  scope: OpenBankingRequestScope;
}) =>
  api
    .post(
      `${envConfig.OPENBANKING_ROUTE}/v1/generate-link?journeyId=${journeyId}`,
      {
        continuous: isContinuousAccess,
        requestOptions: {
          phoneNumber: phoneNumber,
          email: mail,
          country: country,
          scope: scope
        },
      }
    )
    .then((response) => response);

export const callOpenbankingTask = (journeyId: string, taskId: string, success: Function, failed: Function) => {
  return setInterval(function () {
    getData(journeyId, OPEN_BANKING_FIELDS)
      .then((response) => {
        if (response.data.data["open_banking_data_summary"].length > 0 && response.data.data["open_banking_data_summary"][0]) {
          success(response);
        }
      })
  }, 7500);
}

export const downloadOpenBankingInfos = (taskId: string, type: "INPUT" | "OUTPUT") => {
  return api.get(`${envConfig.OPENBANKING_ROUTE}/v1/download-infos/${taskId}?type=${type}`)
    .then((response) => response).catch((error) => error.response);
}

export const refreshOpenBankingLink = async (taskId: string) => {
  return api.get(`${envConfig.OPENBANKING_ROUTE}/v1/refresh-link/${taskId}`);
}

export const disabledOpenBankingLink = async (taskId: string) => {
  return api.delete(`${envConfig.OPENBANKING_ROUTE}/v1/deactivate-link/${taskId}`);
}
