import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "historic_group" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "tabs" }
const _hoisted_4 = { class: "page-navigation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XMasBonhomme = _resolveComponent("XMasBonhomme")!
  const _component_ConsumptionSearch = _resolveComponent("ConsumptionSearch")!
  const _component_MeeloTable = _resolveComponent("MeeloTable")!
  const _component_DeleteFolderModal = _resolveComponent("DeleteFolderModal")!
  const _component_button_meelo = _resolveComponent("button-meelo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_XMasBonhomme, {
      style: {top: '18vh', left: '76vw'},
      value: 2
    }),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.translate('HISTORIC_PAGE.HISTORIC_TITLE')), 1),
    (!_ctx.user?.roles?.includes('COMPANY_USER'))
      ? (_openBlock(), _createBlock(_component_ConsumptionSearch, {
          key: 0,
          users: _ctx.users,
          onSearch: _ctx.searchJourneys,
          onReset: _ctx.resetJourneys,
          onSelectAll: _ctx.allUsersSelected,
          onSelectCompany: _ctx.companySelected,
          onSelectMyTeam: _ctx.myTeamSelected,
          "should-reset": _ctx.toReset,
          user: _ctx.user
        }, null, 8, ["users", "onSearch", "onReset", "onSelectAll", "onSelectCompany", "onSelectMyTeam", "should-reset", "user"]))
      : _createCommentVNode("", true),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.kitNames, (kit) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (kit === 'KIT_B2C')
            ? (_openBlock(), _createElementBlock("li", {
                key: 0,
                class: _normalizeClass({'tab': true, 'active': _ctx.marketType === 'portal-b2c'}),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeTab('portal-b2c')))
              }, "B2C ", 2))
            : _createCommentVNode("", true),
          (kit === 'KIT_B2B')
            ? (_openBlock(), _createElementBlock("li", {
                key: 1,
                class: _normalizeClass({'tab': true, 'active': _ctx.marketType === 'portal-b2b'}),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeTab('portal-b2b')))
              }, "B2B ", 2))
            : _createCommentVNode("", true),
          (kit === 'KIT_OB')
            ? (_openBlock(), _createElementBlock("li", {
                key: 2,
                class: _normalizeClass({'tab': true, 'active': _ctx.marketType === 'open-banking'}),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeTab('open-banking')))
              }, _toDisplayString(_ctx.translate('OPEN_BANKING.TITLE')), 3))
            : _createCommentVNode("", true),
          (kit === 'KIT_DOC')
            ? (_openBlock(), _createElementBlock("li", {
                key: 3,
                class: _normalizeClass({'tab': true, 'active': _ctx.marketType === 'document-analysis'}),
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeTab('document-analysis')))
              }, _toDisplayString(_ctx.translate('DOCUMENT_ANALYSIS')), 3))
            : _createCommentVNode("", true)
        ], 64))
      }), 256))
    ]),
    _createVNode(_component_MeeloTable, {
      titles: _ctx.title,
      class: "meelo-table",
      "content-columns": _ctx.rows,
      onDeleteFolder: _ctx.openModalDeleteFolder,
      "is-loading": _ctx.loaderRows
    }, null, 8, ["titles", "content-columns", "onDeleteFolder", "is-loading"]),
    _createVNode(_component_DeleteFolderModal, {
      openModal: _ctx.modalDeleteFolder,
      "onUpdate:openModal": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modalDeleteFolder) = $event)),
      onConfirmSelection: _ctx.deleteFolder,
      "case-number": _ctx.businessIdToDelete
    }, null, 8, ["openModal", "onConfirmSelection", "case-number"]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.pageId <= _ctx.totalPage && _ctx.pageId > 0)
        ? (_openBlock(), _createBlock(_component_button_meelo, {
            key: 0,
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.movePages(-1))),
            label: _ctx.translate('TABLE.BACK')
          }, null, 8, ["label"]))
        : _createCommentVNode("", true),
      _createElementVNode("p", null, _toDisplayString(_ctx.pageId + 1) + " / " + _toDisplayString(_ctx.totalPage), 1),
      (_ctx.pageId < _ctx.totalPage - 1 && _ctx.pageId >= 0)
        ? (_openBlock(), _createBlock(_component_button_meelo, {
            key: 1,
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.movePages(1))),
            label: _ctx.translate('TABLE.NEXT')
          }, null, 8, ["label"]))
        : _createCommentVNode("", true)
    ])
  ]))
}