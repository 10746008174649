import api from '@/utils/api';
import envConfig from '@/utils/envConfig';
import { CustomerProfileV3 } from '@/types/CustomerProfile';
import _ from 'lodash';
import { isEmpty, isObject } from 'lodash';
import { createdAfter } from '@/utils/dateTime';
import { AxiosResponse } from 'axios';
import { ComputedRef } from 'vue';
import store from '@/store';
import { Motive } from '@/utils/journeyUtils';
import { createApiUrlWithParams } from '@/apiCalls/apiUtil';

interface ResponseElement {
  type: string;
  value: any;
  id: string;
  status: string;
  statusReason: string;
  createdAt: string;
  label: string | null
}

interface HistoricalParams {
  apiCall: 'me' | 'users' | 'companies/me' | 'team',
  countByPage: number,
  pageId: number,
  marketType: String[],
  fields: Array<Object>,
  userIds?: Array<number>,
  companyIds?: Array<number>
}

interface HistoricalDocumentParams {
  apiCall: 'me' | 'users' | 'companies/me' | 'team',
  countByPage: number,
  pageId: number,
  userIds?: Array<number>,
  companyIds?: Array<number>
}

export interface createJourneyIdResponse {
  businessId: string,
  client: string,
  companyId: number,
  createdAt: string,
  externalId: string,
  id: string,
  origin: string,
  statuses: string[],
  tags: string[],
  updatedAt: string,
  userdId: number
}

export const createJourneyIdByTask = (
  origin?: string,
  clientId?: string,
  externalId?: string,
  tags?: Array<string>
): Promise<createJourneyIdResponse> =>
  api
    .post(
      `${envConfig.TASK_ROUTE}/v3/journeys`,
      JSON.stringify({
        origin,
        clientId,
        externalId,
        tags
      })
    )
    .then((response) => response.data);

export const sendApplicantInformation = (
  data: CustomerProfileV3,
  journeyId: string,
  iban?: string
) => {
  return api.post(
    `${envConfig.TASK_ROUTE}/v3/journeys/${journeyId}/contexts`,
    {
      type: 'PERSONAL',
      subType: 'PERSON',
      version: 'V1',
      label: 'PERSON',
      data: {
        domain: 'PERSONAL',
        dtoName: 'PERSON',
        version: 'V1',
        strict: false,

        externalId: 'MEELO-PORTAL',
        firstname: data.identity.firstName,
        lastname: data.identity.lastName,
        mobilePhone: data.identity.mobileNumber,
        email: data.identity.email,
        birthDate: data.identity.birthDate,
        addresses: [
          {
            domain: 'ADDRESS',
            dtoName: 'ADDRESS',
            version: 'V1',
            strict: false,

            addressType: 'MAIN',
            street1: data.address.street,
            zipcode: data.address.zipCode,
            city: data.address.city,
            country: data.address.country
          }
        ],
        iban: iban
      }
    }
  );
};

export const sendAccountantVerificationData = (
  journeyId: string,
  data: {
    REGISTRATION_NUMBER?: string;
    PHONE?: string;
    EMAIL?: string;
    COUNTRY?: string;
  }
) => {
  return api.post(`${envConfig.TASK_ROUTE}/v3/journeys/${journeyId}/contexts`, {
    type: "PORTAL",
    subType: "CHARTERED_ACCOUNTANT",
    version: "V1",
    label: "CHECK_CHARTERED_ACCOUNTANT",
    data,
  });
};

function mapResponse(
  element: ResponseElement,
  index: number,
  responseKey: any[]
): any {
  if (element.status === 'FAILED') {
    if (element && element.value && element.value.data) {
      element.value.data.status = element.status;
      element.value.data.statusReason = element.statusReason;
    } else {
      const status = {
        data: {
          status: element.status,
          statusReason: element.statusReason
        }
      };
      element.value = { ...element.value, ...status };
    }
  }

  if ((_.isEqual(element.type, 'OPENBANKING') || _.isEqual(element.type, 'OPENBANKING_CONTINUOUS'))
    && _.isObject(element.value)
    && !_.isNull(element.value)
  ) {
    return { ...element.value, type: element.type, taskId: element.id, createdAd: element.createdAt };
  }

  if (element.type === 'OCR' || element.type === 'FILE_UPLOAD_REQUEST' || element.type === 'AUTHENTICITY' || element.type === 'ANALYSIS' || element.type === 'CHECK' || element.type === 'LIVE_CHECK' || element.type === 'FACE_MATCHING') {
    if (element.type === 'FACE_MATCHING' && element.value && element.value.ocrResult) {
      element.value = { ...element.value, ...element.value.ocrResult };
    }
    return element.value = {
      ...element.value,
      status: element.status,
      taskId: element.id,
      creationDate: element.createdAt
    };
  }

  if (element.type === 'CHECK_VALUE') {
    return {
      label: element.label,
      value: element.value
    }
  }

  if (element.type === 'SCORING' && element.status === 'CANCELLED') {
    return {
      isCancelled: true
    }
  }

  return element.value;
}

export const getData = (journeyId: string, fields: Array<Object>) =>
  api.post(`${envConfig.TASK_ROUTE}/v3/journeys/${journeyId}/contexts/data/fields`, {
    fields: fields
  }).then((response) => {
    let response_clone = response;
    if (Array.isArray(Object.keys(response.data.data))) {
      Object.keys(response.data.data).forEach((key) => {
        response_clone.data.data[key] = response.data.data[key].map(
          (element: ResponseElement, index: number) => {
            if (key === 'web_upload_link_result' || key === 'web_upload_link_causes') {
              return element;
            }
            return mapResponse(element, index, response.data.data[key]);
          }
        );
      });
    }
    //@ts-ignore
    response_clone.data.data['businessId'] = response.data.businessId;
    response_clone.data.data['clientId'] = response.data.clientId;
    store.dispatch('taskModule/updateTaskData', response_clone.data);
    return response_clone;
  });

export const getHistorical = async ({
                                      apiCall,
                                      countByPage,
                                      pageId,
                                      marketType,
                                      fields,
                                      userIds,
                                      companyIds
                                    }: HistoricalParams) => {
  let whereCondition;
  if (marketType.includes('portal-b2b')) {
    whereCondition = {
      'operator': 'OR',
      'or': [
        {
          'operator': 'NOT_EQUALS',
          'field': 'smb_score',
          'value': null
        },
        {
          'operator': 'NOT_EQUALS',
          'field': 'company_legal_unit',
          'value': null
        },
        {
          'operator': 'NOT_EQUALS',
          'field': 'web_score_b2b',
          'value': null
        }
      ]
    };
  } else if (marketType.includes('document-analysis')) {
    whereCondition = null;
  } else if (marketType.includes('open-banking')) {
    // TODO: should be improved
    whereCondition = {
      'operator': 'OR',
      'or': [
        {
          'operator': 'OR',
          'or': [
            {
              'operator': 'NOT_EQUALS',
              'field': 'continuous_open_banking_account',
              'value': null
            },
            {
              'operator': 'EQUALS',
              'field': 'open_banking_account',
              'value': null
            }
          ]

        },
        {
          'operator': 'OR',
          'or': [
            {
              'operator': 'EQUALS',
              'field': 'continuous_open_banking_account',
              'value': null
            },
            {
              'operator': 'NOT_EQUALS',
              'field': 'open_banking_account',
              'value': null
            }
          ]
        }
      ]
    };
  } else if (marketType.includes('portal-b2c')) {
    whereCondition = {
      'operator': 'OR',
      'or': [
        {
          'operator': 'AND',
          'and': [
            {
              'operator': 'NOT_EQUALS',
              'field': 'causes_web_score_b2c',
              'value': null
            },
            {
              'operator': 'NOT_EQUALS',
              'field': 'firstname_synthesis',
              'value': null
            }
          ]
        },
        {
          'operator': 'AND',
          'and': [
            {
              'operator': 'NOT_EQUALS',
              'field': 'web_score_b2c',
              'value': null
            },
            {
              'operator': 'NOT_EQUALS',
              'field': 'firstname_synthesis',
              'value': null
            }
          ]
        }
      ]
    };
  }
  const { data } = await api.post(
    `${envConfig.TASK_ROUTE}/v5/journeys/search/${apiCall}`,
    {
      pageId: pageId,
      countByPage: countByPage,
      where: whereCondition,
      fields: fields,
      createdAfter: createdAfter,
      sorts: [
        {
          field: 'JOURNEY_createdAt',
          direction: 'DESC'
        }
      ],
      userIds: userIds,
      companyIds: companyIds
    }
  );
  let data_clone = data;
  if (Array.isArray(data.journeys)) {
    data_clone.data = data.journeys.map((journey: any) => {
      Object.keys(journey.data).forEach((key) => {
        journey.data[key] = journey.data[key].map((e: any) => {
          if (e.type === 'OPENBANKING' || e.type === 'OPENBANKING_CONTINUOUS') {
            return typeof e.value == 'number' ? { value: e.value, status: e.status } : { ...e.value, status: e.status };
          }
          return e.value;
        });
      });
      return journey;
    });
    return data_clone;
  }
};

export const getHistoricalDocument = async ({
                                              apiCall,
                                              countByPage,
                                              pageId,
                                              userIds,
                                              companyIds
                                            }: HistoricalDocumentParams) => {
  const { data } = await api.post(
    `${envConfig.STORAGE_ROUTE}/v2/documents/history/${apiCall}`,
    {
      pageId: pageId,
      countByPage: countByPage,
      userIds: userIds,
      companyIds: companyIds
    }
  );

  return data;
};

export const updateJourneyIdStatus = async (journeyId: string, status: string, causes: string, id: string) => {
  api.put(`${envConfig.TASK_ROUTE}/v3/journeys/status`, {
    journeyId: journeyId,
    status: status,
    causes: causes,
    id: id
  }).then((response) => response.data);
};

export const getAllJourneyIdStatus = async (journeyId: string) => {
  return api.get(`${envConfig.TASK_ROUTE}/v3/journeys/status/${journeyId}/status`).then((response) => response.data);
};

export const addJourneyIdStatus = async (journeyId: string, status: string, causes: string) => {
  const { data } = await api.post(`${envConfig.TASK_ROUTE}/v3/journeys/status`, {
    journeyId: journeyId,
    status: status,
    causes: causes
  });

  return data;
};

export const getLatestJourneyIdStatus = async (journeyId: string) => {
  return api.get(`${envConfig.TASK_ROUTE}/v3/journeys/status/${journeyId}/status/latest`).then((response) => response.data);
};

export const getJourneyIdDetails = (journeyId: string) => {
  return api.get(`${envConfig.TASK_ROUTE}/v3/journeys/${journeyId}`);
};

// Motive API calls
export const getLatestMotiveFromJourneyId = async (journeyId: string): Promise<Motive> => {
  return await api.get(`${envConfig.TASK_ROUTE}/v3/journeys/motive/${journeyId}/motive/latest`)
    .then((response: any) => {
      return { id: response.data.motiveId, label: response.data.label, description: response.data.description };
    })
    .catch((error: any) => {
      console.error(error);
      return { id: '', label: '', description: '' };
    });
};


export const getAllMotives = async (page: number, size: number, label: string, status: string, paginated?: boolean): Promise<Motive[]> => {
  const baseUrl = createApiUrlWithParams(`${envConfig.TASK_ROUTE}/v3/journeys/motive/search/all`, {
    labelLike: label,
    page: page,
    size: size,
    status: status,
    enabled: true
  });
  return await api.get(baseUrl).then((response: any) => paginated ? response : response.data.content);
};

export const addMotiveToJourney = async (journeyId: string, motive: Motive) => {
  return api.post(`${envConfig.TASK_ROUTE}/v3/journeys/motive/${journeyId}/motive`,
    { motiveId: motive.id, description: motive.description, label: motive.label });
};

export const createMotive = async (motive: Motive) => {
  return api.post(`${envConfig.TASK_ROUTE}/v3/journeys/motive`,
    { status: motive.status ? motive.status : null, label: motive.label, enabled: true });
};

export const updateMotive = async (motiveId: string, motive: Motive) => {
  return api.put(`${envConfig.TASK_ROUTE}/v3/journeys/motive/${motiveId}`, {
    status: motive.status ? motive.status : null,
    label: motive.label,
    enabled: motive.enabled
  });
};

export const deleteMotive = async (motiveId: string) => {
  return api.delete(`${envConfig.TASK_ROUTE}/v3/journeys/motive/${motiveId}`);
};
