// WEB SCORE B2C
export const WEB_SCORE_B2C_FIELDS = [{
  'key': 'web_score_b2c',
  'subType': 'WEB_SCORE_B2C_V3',
  'name': 'SCORE',
  'type': 'SCORING'
}];

// WEB SCORE CAUSES B2C
export const CAUSES_WEB_SCORE_B2C_FIELDS = [{
  'key': 'causes_web_score_b2c',
  'subType': 'WEB_SCORE_B2C_V3',
  'name': 'CAUSES',
  'type': 'SCORING'
}];

//AMLCFT
export const AMLCFT_FIELDS = [{
  'key': 'amlcft_matches',
  'name': 'MATCHES',
  'type': 'SEARCH_CUSTOMER'
}];

//SCORE ALTARES
export const SCORE_ALTARES_FIELDS = [{
  'key': 'score_altares',
  'subType': 'ALTARES_SCORE',
  'name': 'SCORE',
  'type': 'SCORING'
}];

//CAUSES ALTARES
export const CAUSES_ALTARES_FIELDS = [{
  'key': 'altares_causes',
  'subType': 'ALTARES_SCORE',
  'name': 'CAUSES',
  'type': 'SCORING'
}];

//APPLICANT INFORMATION
export const APPLICANT_INFORMATION_FIELDS = [
  { 'key': 'applicant', 'subType': 'PERSON', 'name': 'PERSONAL', 'type': 'PERSONAL' }
];

//SCORE E-REPUTATION
export const SCORE_E_REPUTATION_FIELDS = [
  {
    'key': 'e_reputation_score',
    'subType': 'E_REPUTATION_SCORE_V1',
    'name': 'SCORE',
    'type': 'SCORING'
  },
  {
    'key': 'e_reputation_score_v2',
    'subType': 'E_REPUTATION_SCORE_V2',
    'name': 'SCORE',
    'type': 'SCORING'
  }
];

//CAUSES E-REPUTATION
export const CAUSES_E_REPUTATION_FIELDS = [
  {
    'key': 'e_reputation_causes',
    'subType': 'E_REPUTATION_SCORE_V1',
    'name': 'CAUSES',
    'type': 'SCORING'
  },
  {
    'key': 'e_reputation_causes_v2',
    'subType': 'E_REPUTATION_SCORE_V2',
    'name': 'CAUSES',
    'type': 'SCORING'
  }
];

//WEB SCORE B2B
export const WEB_SCORE_B2B_FIELDS = [{
  'key': 'web_score_b2b',
  'subType': 'WEB_SCORE_B2B_V2',
  'name': 'SCORE',
  'type': 'SCORING'
}];

//WEB SCORE B2B - V1
export const WEB_SCORE_B2B_V1_FIELDS = [{
  'key': 'web_score_b2b_v1',
  'subType': 'WEB_SCORE_B2B_V1',
  'name': 'SCORE',
  'type': 'SCORING'
}];

//WEB SCORE CAUSES B2B
export const CAUSES_WEB_SCORE_B2B_FIELDS = [{
  'key': 'causes_web_score_b2b',
  'subType': 'WEB_SCORE_B2B_V2',
  'name': 'CAUSES',
  'type': 'SCORING'
}];

//WEB SCORE CAUSES B2B - V1
export const CAUSES_WEB_SCORE_B2B_V1_FIELDS = [{
  'key': 'causes_web_score_b2b_v1',
  'subType': 'WEB_SCORE_B2B_V1',
  'name': 'CAUSES',
  'type': 'SCORING'
}];

//SMB SCORE
export const SMB_SCORE_FIELDS = [{
  'key': 'smb_score',
  'subType': 'SMB_SCORE_V1',
  'name': 'SCORE',
  'type': 'SCORING'
}];

//SMB CAUSES
export const SMB_CAUSES_FIELDS = [{
  'key': 'smb_causes',
  'subType': 'SMB_SCORE_V1',
  'name': 'CAUSES',
  'type': 'SCORING'
}];

//AUTHENTICITY RESULT
export const AUTHENTICITY_RESULT_FIELDS = [{
  'key': 'authenticity_result',
  'name': 'RESULT',
  'type': 'AUTHENTICITY'
}];

//TAX NOTICE ANALYSIS
export const TAX_NOTICE_ANALYSIS_FIELDS = [{
  'key': 'tax_notice_analysis',
  'name': 'RESULT',
  'type': 'CHECK'
}];

//OPEN BANKING -  DATA SUMMARY
export const OPEN_BANKING_DATA_SUMMARY_FIELDS = [{
  'key': 'open_banking_data_summary',
  'name': 'DATA_SUMMARY',
  'type': 'OPENBANKING'
}];

//OPEN BANKING - SCORE
export const OPEN_BANKING_SCORE_FIELDS = [{
  'key': 'open_banking_score',
  'name': 'OB_SCORE',
  'type': 'OPENBANKING'
}];

//OPEN BANKING - ACCOUNT
export const OPEN_BANKING_ACCOUNT_FIELDS = [{
  'key': 'open_banking_account',
  'name': 'ACCOUNT',
  'type': 'OPENBANKING'
}];

// CONTINUOUS OPEN BANKING - SCOPE
export const CONTINUOUS_OPEN_BANKING_SCOPE = [{
  'key': 'open_banking_scope',
  'name': 'OB_SCOPE',
  'type': 'OPENBANKING_CONTINUOUS'
}];

// OPEN BANKING - SCOPE
export const OPEN_BANKING_SCOPE = [{
  'key': 'open_banking_scope',
  'name': 'OB_SCOPE',
  'type': 'OPENBANKING'
}];

//CONTINUOUS OPEN BANKING - DATA SUMMARY
export const CONTINUOUS_OPEN_BANKING_DATA_SUMMARY_FIELDS = [{
  'key': 'open_banking_data_summary',
  'name': 'DATA_SUMMARY',
  'type': 'OPENBANKING_CONTINUOUS'
}];

//CONTINUOUS OPEN BANKING - SCORE
export const CONTINUOUS_OPEN_BANKING_SCORE_FIELDS = [{
  'key': 'open_banking_score',
  'name': 'OB_SCORE',
  'type': 'OPENBANKING_CONTINUOUS'
}];

//CONTINUOUS OPEN BANKING - ACCOUNT
export const CONTINUOUS_OPEN_BANKING_ACCOUNT_FIELDS = [{
  'key': 'open_banking_account',
  'name': 'ACCOUNT',
  'type': 'OPENBANKING_CONTINUOUS'
}];

// CONTINUOUS OPEN BANKING - Refresh Data
export const CONTINUOUS_OPEN_BANKING_REFRESH = [{
  'key': 'open_banking_refresh_data',
  'name': 'OB_REFRESH',
  'type': 'OPENBANKING_CONTINUOUS'
}];

// CONTINUOUS OPEN BANKING - Date time submisison
export const CONTINUOUS_OPEN_BANKING_DTS = [{
  'key': 'open_banking_refresh_dts',
  'name': 'OB_REFRESH_DTS',
  'type': 'OPENBANKING_CONTINUOUS'
}];

// CONTINUOUS OPEN BANKING - Date time first aggregation
export const CONTINUOUS_OPEN_BANKING_DTFA = [{
  'key': 'open_banking_refresh_dtfa',
  'name': 'OB_REFRESH_DTFA',
  'type': 'OPENBANKING_CONTINUOUS'
}];

// CONTINUOUS OPEN BANKING - Date time last aggregation
export const CONTINUOUS_OPEN_BANKING_DTLA = [{
  'key': 'open_banking_refresh_dtla',
  'name': 'OB_REFRESH_DTLA',
  'type': 'OPENBANKING_CONTINUOUS'
}];

// CONTINUOUS OPEN BANKING - Date time consent expiration
export const CONTINUOUS_OPEN_BANKING_DTCE = [{
  'key': 'open_banking_refresh_dtce',
  'name': 'OB_REFRESH_DTCE',
  'type': 'OPENBANKING_CONTINUOUS'
}];

// CONTINUOUS OPEN BANKING - SCORE & ACCOUNT
// Used as dtoFields with different keys for the getHistorical api call
export const HISTORICAL_CONTINUOUS_OPEN_BANKING_SCORE_FIELDS = [{
  'key': 'continuous_open_banking_score',
  'name': 'OB_SCORE',
  'type': 'OPENBANKING_CONTINUOUS'
}];
export const HISTORICAL_CONTINUOUS_OPEN_BANKING_ACCOUNT_FIELDS = [{
  'key': 'continuous_open_banking_account',
  'name': 'ACCOUNT',
  'type': 'OPENBANKING_CONTINUOUS'
}];

//CHECK IBAN
export const CHECK_IBAN_FIELDS = [{
  'key': 'check_iban_details',
  'name': 'IBAN_DETAILS',
  'type': 'CHECK_IBAN'
}];

// BANK NAME SEARCH
export const BANK_NAME_SEARCH_FIELDS = [{
  'key': 'bank_name_search',
  'name': 'RESULT',
  'type': 'BANK_SEARCH'
}];

//CHECK COMPANY - LEGAL UNIT
export const COMPANY_LEGAL_UNIT_FIELDS = [{
  'key': 'company_legal_unit',
  'name': 'LEGAL_UNIT',
  'type': 'COMPANY_DETAILS_INTERNATIONAL'
}];

//CHECK COMPANY - COUNTRY
export const COMPANY_COUNTRY_FIELDS = [{
  'key': 'company_country',
  'name': 'COUNTRY',
  'type': 'COMPANY_DETAILS_INTERNATIONAL'
}];

//CHECK COMPANY - REGISTRATION NUMBER
export const COMPANY_REGISTRATION_NUMBER_FIELDS = [{
  'key': 'company_registration_number',
  'name': 'REGISTRATION_NUMBER',
  'type': 'COMPANY_DETAILS_INTERNATIONAL'
}];

//CHECK COMPANY - ATTACHMENTS
export const COMPANY_ATTACHMENTS_FIELDS = [{
  'key': 'company_attachments',
  'name': 'ATTACHMENTS',
  'type': 'COMPANY_DETAILS_INTERNATIONAL'
}];

//CHECK COMPANY - LEGAL OBSERVATION
export const COMPANY_LEGAL_OBSERVATIONS_FIELDS = [{
  'key': 'company_legal_observations',
  'name': 'LEGAL_OBSERVATION',
  'type': 'COMPANY_DETAILS_INTERNATIONAL'
}];

//CHECK COMPANY - SHARE HOLDERS
export const COMPANY_SHARE_HOLDERS_FIELDS = [{
  'key': 'company_share_holders',
  'name': 'SHARE_HOLDERS',
  'type': 'COMPANY_DETAILS_INTERNATIONAL'
}];

//CHECK COMPANY - REPRESENTATIVES
export const COMPANY_REPRESENTATIVES_FIELDS = [{
  'key': 'company_representatives',
  'name': 'REPRESENTATIVES',
  'type': 'COMPANY_DETAILS_INTERNATIONAL'
}];

//CHECK COMPANY - PROCEEDINGS
export const COMPANY_PROCEEDINGS_FIELDS = [{
  'key': 'company_collective_proceedings',
  'name': 'COLLECTIVE_PROCEEDINGS',
  'type': 'COMPANY_DETAILS_INTERNATIONAL'
}];

//CHECK COMPANY - ESTABLISHMENTS
export const COMPANY_ESTABLISHMENTS_FIELDS = [{
  'key': 'company_establishments',
  'name': 'ESTABLISHMENTS',
  'type': 'COMPANY_DETAILS_INTERNATIONAL'
}];

//CHECK COMPANY - BUSINESS OWNERS
export const COMPANY_BUSINESS_OWNERS_FIELDS = [{
  'key': 'company_business_owners',
  'name': 'ESTABLISHMENTS',
  'type': 'COMPANY_DETAILS_INTERNATIONAL'
}];

//TAX REPORTS
export const TAX_REPORTS_RESULT_FIELDS = [{
  'key': 'tax_reports_result',
  'name': 'RESULT',
  'type': 'ANALYSIS'
}];

//ID CHECK
export const ID_CHECK_FIELDS = [{
  'key': 'id_check_data',
  'subType': 'ID_CHECK',
  'name': 'RESULT',
  'type': 'OCR'
}];

// BANK DETAILS
export const BANK_DETAILS_FIELDS = [{
  'key': 'bank_details',
  'subType': 'BANK_DETAILS',
  'name': 'DATA',
  'type': 'OCR'
}];

//TAX REPORT
export const TAX_REPORT_FIELDS = [{
  'key': 'tax_report_data',
  'subType': 'TAX_REPORT',
  'name': 'DATA',
  'type': 'OCR'
}];

//KBIS
export const KBIS_FIELDS = [{
  'key': 'kbis_data',
  'subType': 'KBIS',
  'name': 'DATA',
  'type': 'OCR'
}];

//PAYSLIP
export const PAYSLIP_FIELDS = [{
  'key': 'payslip_data',
  'subType': 'PAYSLIP',
  'name': 'DATA',
  'type': 'OCR'
}];

//TAX NOTICE
export const TAX_NOTICE_FIELDS = [{
  'key': 'tax_notice_data',
  'subType': 'TAX_NOTICE',
  'name': 'DATA',
  'type': 'OCR'
}];

//TAX NOTICE
export const FILE_FIELDS = [{
  'key': 'file',
  'name': 'RESULT',
  'type': 'FILE_UPLOAD_REQUEST'
}];

//LIVE CHECK
export const LIVE_CHECK_FIELDS = [{
  'key': 'live_check',
  'name': 'LIVE_CHECK_CAUSES',
  'type': 'LIVE_CHECK'
}];

// FACE MATCHING
export const FACE_MATCHING_FIELDS = [{
  'key': 'face_matching',
  'name': 'RESULT',
  'type': 'FACE_MATCHING'
}];

// CREDIT SAFE SCORE
export const CREDIT_SAFE_SCORE_FIELDS = [{
  'key': 'credit_safe_score',
  'subType': 'CREDIT_SAFE_SCORE',
  'name': 'SCORE',
  'type': 'SCORING'
}];

// CREDIT SAFE CAUSES
const CREDIT_SAFE_CAUSES_FIELDS = [{
  'key': 'credit_safe_causes',
  'subType': 'CREDIT_SAFE_SCORE',
  'name': 'CAUSES',
  'type': 'SCORING'
}] as const;

// APPLICANT - SYNTHESIS
export const APPLICANT_SYNTHESIS_FIELDS = [{
  'key': 'email_synthesis',
  'name': 'EMAIL',
  'type': 'SCORING'
}, {
  'key': 'lastname_synthesis',
  'name': 'LAST_NAME',
  'type': 'SCORING'
}, {
  'key': 'firstname_synthesis',
  'name': 'FIRST_NAME',
  'type': 'SCORING'
}, {
  'key': 'phone_synthesis',
  'name': 'MOBILE_NUMBER',
  'type': 'SCORING'
}, {
  'key': 'birthdate_synthesis',
  'name': 'BIRTH_DATE',
  'type': 'SCORING'
}];

// ENRICHMENT
export const ENRICHMENT_FIELDS = [{
  'key': 'enrichment',
  'name': 'RESULT',
  'type': 'GET_LINKEDIN_PROFILE'
}];

// WARNING LIST
export const WARNING_LIST_FIELDS = [{
  'key': 'warning_list',
  'name': 'MATCHES',
  'type': 'CHECK_VALUE',
  'subType': 'MEELO_BLACKLIST'
}];

//FACE MATCHING
export const SEARCH_IMAGE_FIELDS = [{
  'key': 'search_image',
  'name': 'PROFILES',
  'type': 'SOCIAL_FACE_MATCHING'
}];

//WEB UPLOAD LINK RESULT
export const WEB_UPLOAD_LINK_RESULT_FIELDS = [
  {
    'key': 'web_upload_link_result',
    'name': 'WEB_UPLOAD_LINK_RESULT',
    'type': 'WEB_UPLOAD',
    'subType': 'LINK_GENERATION'
  },
  {
    'key': 'web_upload_link_causes',
    'name': 'WEB_UPLOAD_LINK_CAUSES',
    'type': 'WEB_UPLOAD',
    'subType': 'LINK_GENERATION'
  }];

//DQE CONTROL
export const DQE_CONTROL_FIELDS = [{
  'key': 'dqe_control',
  'name': 'RESULT',
  'type': 'SEARCH_ID_MOBILE'
}];

export const ACCOUNTANT_VERIFICATION_DATA = [
  {
    'key': 'accountant_verification_data',
    'name': 'CHECK_CHARTERED_ACCOUNTANT',
    'type': 'PORTAL'
  }
];

export const WARNING_LIST_COMBINED_DATA = [
  {
    'key': 'combined_warning_list',
    'name': 'RESULT',
    'type': 'CHECK_VALUE',
    'subType': 'COMBINED_MEELO_BLACKLIST'
  }
];

export const CONTINUOUS_OPEN_BANKING_FIELDS = [...CONTINUOUS_OPEN_BANKING_SCOPE, ...CONTINUOUS_OPEN_BANKING_DATA_SUMMARY_FIELDS, ...CONTINUOUS_OPEN_BANKING_SCORE_FIELDS,
  ...CONTINUOUS_OPEN_BANKING_ACCOUNT_FIELDS, ...CONTINUOUS_OPEN_BANKING_REFRESH, ...CONTINUOUS_OPEN_BANKING_DTS, ...CONTINUOUS_OPEN_BANKING_DTFA,
  ...CONTINUOUS_OPEN_BANKING_DTLA, ...CONTINUOUS_OPEN_BANKING_DTCE];

export const OPEN_BANKING_FIELDS = [...OPEN_BANKING_DATA_SUMMARY_FIELDS, ...OPEN_BANKING_SCORE_FIELDS,
  ...OPEN_BANKING_ACCOUNT_FIELDS, ...OPEN_BANKING_SCOPE, ...CONTINUOUS_OPEN_BANKING_FIELDS];

export const DOCUMENTS_FIELDS = [...ID_CHECK_FIELDS, ...KBIS_FIELDS, ...TAX_REPORT_FIELDS,
  ...TAX_REPORTS_RESULT_FIELDS, ...TAX_NOTICE_ANALYSIS_FIELDS, ...PAYSLIP_FIELDS, ...TAX_NOTICE_FIELDS,
  ...AUTHENTICITY_RESULT_FIELDS, ...FILE_FIELDS, ...BANK_DETAILS_FIELDS, ...WEB_UPLOAD_LINK_RESULT_FIELDS, ...LIVE_CHECK_FIELDS, ...FACE_MATCHING_FIELDS];

export const ANALYSIS_B2C_FIELDS = [...WEB_SCORE_B2C_FIELDS, ...CAUSES_WEB_SCORE_B2C_FIELDS,
  ...SEARCH_IMAGE_FIELDS, ...AMLCFT_FIELDS, ...APPLICANT_INFORMATION_FIELDS, ...CHECK_IBAN_FIELDS, ...DOCUMENTS_FIELDS, ...ENRICHMENT_FIELDS,
  ...COMPANY_LEGAL_UNIT_FIELDS, ...WARNING_LIST_FIELDS, ...COMPANY_PROCEEDINGS_FIELDS, ...OPEN_BANKING_FIELDS, ...BANK_NAME_SEARCH_FIELDS, ...DQE_CONTROL_FIELDS, ...APPLICANT_SYNTHESIS_FIELDS];

export const ANALYSIS_B2B_FIELDS = [...COMPANY_LEGAL_UNIT_FIELDS, ...COMPANY_ATTACHMENTS_FIELDS,
  ...COMPANY_LEGAL_OBSERVATIONS_FIELDS, ...SCORE_E_REPUTATION_FIELDS, ...CAUSES_E_REPUTATION_FIELDS,
  ...CAUSES_ALTARES_FIELDS, ...SCORE_ALTARES_FIELDS, ...COMPANY_SHARE_HOLDERS_FIELDS, ...COMPANY_REPRESENTATIVES_FIELDS,
  ...COMPANY_ESTABLISHMENTS_FIELDS, ...COMPANY_BUSINESS_OWNERS_FIELDS, ...CAUSES_WEB_SCORE_B2B_FIELDS, ...WEB_SCORE_B2B_FIELDS,
  ...SMB_SCORE_FIELDS, ...SMB_CAUSES_FIELDS, ...CHECK_IBAN_FIELDS, ...AMLCFT_FIELDS, ...APPLICANT_INFORMATION_FIELDS,
  ...TAX_REPORTS_RESULT_FIELDS, ...OPEN_BANKING_FIELDS, ...ID_CHECK_FIELDS, ...KBIS_FIELDS,
  ...TAX_NOTICE_ANALYSIS_FIELDS, ...PAYSLIP_FIELDS, ...TAX_NOTICE_FIELDS,
  ...AUTHENTICITY_RESULT_FIELDS, ...FILE_FIELDS, ...CREDIT_SAFE_SCORE_FIELDS, ...CREDIT_SAFE_CAUSES_FIELDS,
  ...COMPANY_COUNTRY_FIELDS, ...COMPANY_REGISTRATION_NUMBER_FIELDS, ...ENRICHMENT_FIELDS, ...WARNING_LIST_FIELDS,
  ...SEARCH_IMAGE_FIELDS, ...WEB_SCORE_B2B_V1_FIELDS, ...CAUSES_WEB_SCORE_B2B_V1_FIELDS, ...BANK_NAME_SEARCH_FIELDS, ...APPLICANT_SYNTHESIS_FIELDS, ...ACCOUNTANT_VERIFICATION_DATA, ...WARNING_LIST_COMBINED_DATA];

export const RECOVERY_FIELDS = [...APPLICANT_INFORMATION_FIELDS, ...OPEN_BANKING_FIELDS,
  ...CAUSES_WEB_SCORE_B2C_FIELDS];

export const ALL_FIELDS = [...ANALYSIS_B2C_FIELDS, ...ANALYSIS_B2B_FIELDS];

export const HISTORICAL_B2C_FIELDS = [...APPLICANT_INFORMATION_FIELDS, ...CAUSES_WEB_SCORE_B2C_FIELDS,
  ...WEB_SCORE_B2C_FIELDS, ...CHECK_IBAN_FIELDS, ...ENRICHMENT_FIELDS, ...APPLICANT_SYNTHESIS_FIELDS];

export const HISTORICAL_B2B_FIELDS = [...APPLICANT_INFORMATION_FIELDS, ...COMPANY_LEGAL_UNIT_FIELDS,
  ...SMB_CAUSES_FIELDS, ...SMB_SCORE_FIELDS, ...CAUSES_WEB_SCORE_B2B_FIELDS, ...WEB_SCORE_B2B_FIELDS, ...WARNING_LIST_FIELDS,
  ...CHECK_IBAN_FIELDS, ...WEB_SCORE_B2B_V1_FIELDS, ...APPLICANT_SYNTHESIS_FIELDS, ...COMPANY_REGISTRATION_NUMBER_FIELDS];

export const HISTORICAL_OPEN_BANKING_FIELDS = [...OPEN_BANKING_ACCOUNT_FIELDS, ...OPEN_BANKING_SCORE_FIELDS, ...HISTORICAL_CONTINUOUS_OPEN_BANKING_SCORE_FIELDS, ...HISTORICAL_CONTINUOUS_OPEN_BANKING_ACCOUNT_FIELDS];

export const SYNTHESIS_FIELDS = [...ALL_FIELDS.filter((v, i, a) => a.indexOf(v) === i)];

