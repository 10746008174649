<template>
  <div class="container-b2b">
    <div class="container-b2b__analysis">
      <PrintFolderInfo
        :entries="{
        uniqId: journeyId,
        }"
      />
      <PrintInfoWithLabel title="PRINT.ANALYSIS_B2B.COMPANY_INFO"
                          :entries="computeCompanyInfoEntries(checkCompanyLegalUnitData, causesB2BDataV)" />
      <PrintCard
        v-if="((scoreB2BDataV && scoreB2BDataV.length > 0) || isScoreB2BLoading)  && permissionsSorted?.includes('KIT_B2B_WARNING_LIST_GLOBAL')"
        title="PRINT.ANALYSIS_B2B.WARNING_LIST">
        <OutputWarningList :warning-list="causesB2BDataV?.warningList" />
      </PrintCard>
      <PrintB2BBusinessOwner title="PRINT.ANALYSIS_B2B.BUSINESS_OWNERS_KNOWN" :entries="checkCompanyShareHoldersData" />
      <PrintB2BBusinessRepresent title="PRINT.ANALYSIS_B2B.BUSINESS_REPRESENTS_KNOWN"
                                 :entries="checkCompanyRepresentativesData" />
      <PrintApplicantOB title="PRINT.ANALYSIS_B2B.CLIENT_INFORMATION" :color="colorSMB"
                        :score="Math.round(scoreSMBData)" :entries="computeClientInfoEntries(prefillClientForm)" />
      <PrintCard
        v-if="((isScoreCompany || checkIbanData || amlCftMatchesData) && !scoreSMBBlock) || ((isScoreSmb|| isScoreSMBLoading) && scoreSMBBlock)"
        title="PRINT.ANALYSIS_B2B.SCORE_DETAILS">
        <BoxOutputScoreSMB :scoreSMB="scoreSMBData" :search-image="searchImageProfilesData" :causesSMB="causesSMBData"
                           :applicant="applicantData" :company="user?.company?.name"
                           :color="colorSMB" :permissions="permissionsSorted"
                           :can-score-s-m-b="scoreSMBBlock" />
      </PrintCard>
      <PrintCard
        v-if="((isScoreSmb || checkIbanData || amlCftMatchesData) && !companyScoreBlock) || ((isScoreCompany || isScoreB2BLoading) && companyScoreBlock)"
        title="PRINT.ANALYSIS_B2B.COMPANY_SCORE_DETAILS">
        <BoxOutputScoreCompany
          :scoringB2B="scoreB2BDataV" :causesB2B="causesB2BDataV"
          :company="user?.company?.name" :journey-id="journeyId" :color="colorB2B"
          :permissions="permissionsSorted" :permissions-company-alert="permissionsCompanyAlert"
          :can-company-score="companyScoreBlock"
        />
      </PrintCard>
      <PrintCard v-if="canShowBoxOutputCompanyLitigation" title="">
        <BoxOutputCompanyLitigation
          v-if="canShowBoxOutputCompanyLitigation" :data="causesB2BData"
          :is-loader="!causesB2BData" :can-check-litigation="companyLitigationBlock" />
      </PrintCard>
      <PrintCard title="PRINT.ANALYSIS_B2B.AMLCFT">
        <div class="print-b2c-aml-cft-container">
          <BoxOutputAmlcft
            v-if="((causesB2BDataV || causesSMBData || checkIbanData) && !amlcftBlock) || (amlCftMatchesData && amlcftBlock)"
            :amlcft="amlCftMatchesData ? amlCftMatchesData[0] : []" :show-content="amlCftMatchesData"
            :can-amlcft="amlcftBlock" />
        </div>
      </PrintCard>
      <PrintCard title="PRINT.ANALYSIS_B2B.COMPANY_FINANCE">
        <div class="print-b2b-company-finance-container">
          <BoxOutputCompanyFinances
            v-if="checkCompanyCountryData !== 'ES' && ((checkCompanyLegalUnitData && !companyFinancesBlock) || (taxReportsSummaryData && companyFinancesBlock))"
            :tax-reports="filteredTaxReportsSummaryData"
            :loader="taxReportLoading"
            :can-company-finances="permissionsSorted.includes('KIT_B2B_COMPANY_FINANCES_GLOBAL')" />
        </div>
      </PrintCard>
      <PrintCard
        v-if="(checkCompanyLegalUnitData && !companyLegalDocBlock) || (checkCompanyAttachmentsData && companyLegalDocBlock)"
        title="PRINT.ANALYSIS_B2B.COMPANY_LEGAL_DOC"
      >
        <BoxOutputCompanyLegalDoc
          :to-print="true"
          :legal-doc="checkCompanyAttachmentsData.legalActs"
          :registration-number="checkCompanyLegalUnitData.registrationNumber"
          :journey-id="journeyId"
          :can-company-legal-doc="permissionsSorted.includes('KIT_B2B_COMPANY_LEGAL_DOC_GLOBAL')" />
      </PrintCard>
      <PrintCard title="PRINT.ANALYSIS_B2B.ANNUAL_ACCOUNTS">
        <div class="print-b2b-annual-account-container">
          <BoxOutputAnnualAccounts
            v-if="(checkCompanyLegalUnitData && !annualAccountsBlock) || (checkCompanyAttachmentsData && annualAccountsBlock)"
            :tax-reports="checkCompanyAttachmentsData.taxReports" :journey-id="journeyId"
            :can-annual-accounts="permissionsSorted.includes('KIT_B2B_COMPANY_ANNUAL_ACCOUNT_GLOBAL')" />
        </div>
      </PrintCard>
      <PrintCard title="PRINT.ANALYSIS_B2B.ESTABLISHMENTS">
        <div class="print-b2b-establishments-container">
          <BoxOutputEstablishments
            v-if="(checkCompanyLegalUnitData && !establishmentsBlock) || (checkCompanyEstablishmentsData && establishmentsBlock)"
            :establishments="checkCompanyEstablishmentsData"
            :can-establishments="permissionsSorted.includes('KIT_B2B_COMPANY_ESTABLISHMENTS_GLOBAL')" />
        </div>
      </PrintCard>
      <PrintCard title="PRINT.ANALYSIS_B2B.COMPANY_EVOLUTION">
        <div class="print-b2b-company-evolution-container">
          <BoxOutputBodaccAnnouncements
            v-if="(checkCompanyLegalUnitData && !bodaccAnnouncementsBlock) || bodaccAnnouncementsBlock"
            :announcements="checkCompanyLegalObservationData"
            :can-bodacc-announcements="permissionsSorted.includes('KIT_B2B_COMPANY_EVOLUTION_GLOBAL')" />
        </div>
      </PrintCard>
      <PrintCard title="PRINT.ANALYSIS_B2B.SCORING"></PrintCard>
      <PrintCard title="PRINT.ANALYSIS_B2B.E_REPUTATION">
        <div class="print-b2b-company-evolution-container">
          <BoxOutputEReputation
            v-if="(checkCompanyLegalUnitData && !eReputationBlock) || (causesEReputationData && scoreEReputationData && eReputationBlock)"
            :e-reputation="causesEReputationData"
            :show-e-reputation-content="isEReputationLoading || (causesEReputationData && scoreEReputationData)"
            :show-e-reputation-loader="isEReputationLoading"
            :scoring-e-reputation="scoreEReputationData" :color="colorEReputation"
            :permissions="permissionsSorted" :can-e-reputation="eReputationBlock" />
        </div>
      </PrintCard>
      <PrintCard title="PRINT.ANALYSIS_B2B.IBAN">
        <div class="print-b2c-check-iban-container">
          <template v-if="isCheckIbanV1">
            <BoxOutputCheckIbanB2b
              v-if="((causesB2BDataV || causesSMBData || amlCftMatchesData) && !checkIbanBlock) || ((showCheckIbanLoader || messageCheckIban || checkIbanData) && checkIbanBlock)"
              :check-iban="checkIbanData" :loader="showCheckIbanLoader" :can-check-iban="checkIbanBlock"
            />
          </template>
          <template v-else>
            <CheckIbanBox
              v-if="displayCheckIbanBox"
              :check-iban="checkIbanData" :loader="showCheckIbanLoader" :can-check-iban="checkIbanBlock"
              :is-france="isCountryFR"
            />
          </template>
        </div>
      </PrintCard>
      <PrintApplicantOB
        :entries="{
          bankName: obFormattedFields.bankName,
          holder: obFormattedFields.holderName,
          account: obFormattedFields.accountName,
          iban: obFormattedFields.iban,
          mainAccount: obFormattedFields.topSecondaryAccount,
          monthSalaries: obFormattedFields.monthSalaries,
          monthRents: obFormattedFields.monthRents,
          monthEndRatio: obFormattedFields.monthEndRatio,
          balance: obFormattedFields.balance,
          topBankingSupervision: obFormattedFields.topBankingSupervision,
          topDeferredDebit: obFormattedFields.topDeferredDebit,
          monthEndBalance: obFormattedFields.monthEndBalance,
          globalTransactionsCount: obFormattedFields.globalTransactionsCount,
        }"
        :title="translate('PRINT.APPLICANT_OB.OB_TITLE')" :score="Math.round(obFormattedFields.score || 0)"
        :color="colorScoreOB"
      >
        <span class="ob-financial-analysis-period">
          {{
            `${translate('OPEN_BANKING.FINANCIAL_ANALYSIS_PERIOD')}: ${
              obFormattedFields.startStudyPeriod || ' -/- '
            } ${translate('OPEN_BANKING.TO')} ${
              obFormattedFields.endStudyPeriod || ' -/- '
            } `
          }}
        </span>
      </PrintApplicantOB>
      <PrintCard title="PRINT.OB_ANALYSIS_DETAIL">
        <div class="print-ob-analysis-detail-container">
          <BoxOutputOpenBankingDetail v-if="(Object.keys(obFormattedFields).length > 0) && openBankingDetailBlock"
                                      :ob-data="obFormattedFields"
                                      :permissions="permissionsSorted"
                                      :can-open-banking="permissionsOpenBankingDetails[0].length > 0"
          />
        </div>
      </PrintCard>
      <PrintCard title="PRINT.ANALYSIS_B2B.ALERTS">
        <div class="print-ob-analysis-alert-container">
          <BoxOutputOpenBankingAlert v-if="(Object.keys(obFormattedFields).length > 0) && openBankingAlertBlock"
                                     :ob-data="obFormattedFields"
                                     :can-open-banking="permissionsSorted.includes('KIT_B2B_OB_ALERTS_GLOBAL')"
          />
        </div>
      </PrintCard>
      <div class="to-print">
        <BoxOutputDocument
          v-if="((isScoreSmb || isScoreCompany) && !restitutionDocumentBlock) || (checkCompanyLegalUnitData  && restitutionDocumentBlock)"
          :showFolderInfoOnPrint="false"
          :isLoading="isLoading" :context-data="contextData" :can-restitution-document="restitutionDocumentBlock"
        />
      </div>
      <div class="no-print">
        <BoxInputStateFolder v-if="displayStateFolder" :case-number="businessId" :case-state="journeyIdStatus"
                             @delete-folder="openModalDeleteFolder" :states="states()" :journey-id="journeyId"
                             @save-folder="openModalEditFolder" :motive="motive" />
        <EditFolderModal v-model:open-modal="modalEditFolder" :case-number="businessId" :case-state="journeyIdStatus"
                         @confirm-selection="editFolder" @close-modal="undoFolder" />
        <DeleteFolderModal v-model:open-modal="modalDeleteFolder" @confirm-selection="deleteFolder"
                           :case-number="businessId" />
        <BoxOutputCheckList v-if="hasCheckList" :data="checkList" />
        <BoxInputSiren @company-search="companySearch" :show-button="showInputSirenButton"
                       :siren-warning-list="warningListData"
                       :show-siren="checkCompanyRegistrationNumberData"
                       :permissions="permissionsSorted" :default="$route.query.registrationNumber?.toString()"
                       :is-duplicate="isDuplicateFolder()"
                       :company-country="checkCompanyCountryData" :is-loading="warningListLoading"
        />
        <div class="container-b2b__analysis-selections">
          <BoxOutputOwnerRepresentative
            v-if="canShowBoxOutputBusinessOwner"
            :owner-representatives="businnessOwnerTransformer(checkCompanyCountryData, checkCompanyShareHoldersData)"
            @person-data-emitted="askToPrefillClientForm"
            :clearPerson="clearBusinessOwner" :disableButtons="disablePersonSelection"
            :permission-to-select="permissionsSorted.includes('KIT_B2B_BUSINESS_OWNERS_SELECTION')"
            :can-person="businessOwnersBlock"
            :title="translate('COMPANY.BUSINESS_OWNERS_KNOWN')"
            :country="checkCompanyCountryData"
            :type="'businessOwner'" />
          <BoxOutputOwnerRepresentative
            v-if="canShowBoxOutputRepresentative"
            :owner-representatives="representativeTransformer(checkCompanyCountryData, checkCompanyRepresentativesData)"
            @person-data-emitted="askToPrefillClientForm"
            :clearPerson="clearRepresentative" :disableButtons="disablePersonSelection"
            :permission-to-select="permissionsSorted.includes('KIT_B2B_REPRESENTATIVES_SELECTION')"
            :can-person="representativesBlock" :title="translate('COMPANY.REPRESENTATIVES_KNOWN')"
            :type="'representative'" :country="checkCompanyCountryData" />
        </div>
        <BoxInputPersonalInfo v-if="canShowBoxInputPersonalInfo" @show-score="computeScores" @check-amlcft="checkAmlcft"
                              @check-iban="checkIbanMethod" market-type="b2b" :propPrefillClientForm="prefillClientForm"
                              :disableFraudScoreButton="isDisableFraudScoreButton"
                              :disableIbanButton="isDisableIbanButton" :disableAmlcftButton="isDisableAmlcftButton"
                              :permissions="permissionsSorted"
                              :double-button="companyParameters?.displayScoreFraudCompanyAndSMB"
                              :disable-smb-score-button="disableSmbScoreButton || (scoreSMBData && scoreSMBData.length > 0)"
                              :disable-score-fraud-company-button="disableScoreFraudCompanyButton || (scoreB2BDataV && scoreB2BDataV.length > 0)"
                              :amlcft-block="amlcftBlock" :company-score-block="companyScoreBlock"
                              :score-smb-block="scoreSMBBlock" :check-iban-block="checkIbanBlock"
                              :can-web-score-b2c="canWebScoreB2c"
                              :company-country="checkCompanyCountryData"
        />
        <div v-if="canShowBoxInputPersonalInfo" class="container-b2b__analysis-result">
          <span class="container-b2b__analysis-result-title">{{ translate('RESULT') }}</span>
        </div>
        <BoxOutputCompanyInfo
          v-if="canShowBoxOutputCompanyInfo && !isString(responseStatus.companyCheckErrorMessage)"
          :show-information-company-content="checkCompanyLegalUnitData"
          :check-company="companyInfoTransformer(checkCompanyCountryData, { ...checkCompanyLegalUnitData, companyCountry: checkCompanyCountryData })"
          :company-causes="causesB2BDataV?.company"
          :permissions="permissionsSorted" :can-company-info="companyInfoBlock" />
        <OutputWarningList v-if="canShowOutputWarningList" :warning-list="getWarningListData"
                           :is-loading="isScoreB2BLoading || isScoreSMBLoading" />
        <BoxOutputScoreSMB
          v-if="canShowBoxOutputScoreSMB"
          :scoreSMB="scoreSMBData"
          :search-image="searchImageProfilesData" :causesSMB="causesSMBData"
          :applicant="applicantData" :company="user?.company?.name"
          :color="colorSMB" :permissions="permissionsSorted"
          :can-score-s-m-b="scoreSMBBlock" :display-multi-requests="!causesB2BDataV"
        />
        <BoxOutputScoreCompany
          v-if="canShowBoxOutputScoreCompany"
          :scoringB2B="scoreB2BDataV"
          :causesB2B="causesB2BDataV"
          :company="user?.company?.name" :journey-id="journeyId" :color="colorB2B"
          :permissions="permissionsSorted" :permissions-company-alert="permissionsCompanyAlert"
          :can-company-score="companyScoreBlock" />
        <BoxOutputCompanyLitigation
          v-if="canShowBoxOutputCompanyLitigation" :data="causesB2BDataV"
          :is-loader="!causesB2BDataV" :can-check-litigation="companyLitigationBlock" />
        <BoxOutputCompanyCartography
          v-if="canShowBoxOutputCompanyCartography"
          :dataChart="causesB2BDataV?.graph" @siren-chart="goOpenNewTabFromChart"
          :company-country="checkCompanyCountryData"
          :can-cartography="cartographyBlock" :key="causesB2BDataV?.graph" />
        <template v-if="canShowBoxOutputCheckIbanB2b">
          <BoxOutputCheckIbanB2b
            v-if="isCheckIbanV1"
            :check-iban="checkIbanData" :loader="showCheckIbanLoader" :can-check-iban="checkIbanBlock" />
          <CheckIbanBox
            v-else
            :check-iban="checkIbanData"
            :loader="showCheckIbanLoader"
            :can-check-iban="checkIbanBlock"
            :is-france="isCountryFR"
          />
        </template>
        <BoxOutputAmlcft
          v-if="canShowBoxOutputAmlcft"
          :amlcft="amlCftMatchesData ? amlCftMatchesData[0] : []" :show-content="amlCftMatchesData"
          :can-amlcft="amlcftBlock" />
        <div v-if="checkCompanyLegalUnitData && isDataAvalaible(checkCompanyLegalUnitData) && !isDuplicateFolder()"
             class="container-b2b__analysis-result">
          <span class="container-b2b__analysis-result-title">{{ translate('ADDITIONAL_CONTROLS') }}</span>
        </div>
        <DocumentAnalysis v-if="canShowDocumentAnalysis" :show-title="false" :siren="checkCompanyRegistrationNumberData"
                          @load-tax-report-result="reloadTaxReportResult" @check-list="checkListDocument" />
        <BoxOutputCompanyFinances
          v-if="checkCompanyCountryData !== 'ES' && canShowBoxOutputCompanyFinances"
          :tax-reports="filteredTaxReportsSummaryData"
          :loader="taxReportLoading"
          :can-company-finances="permissionsSorted.includes('KIT_B2B_COMPANY_FINANCES_GLOBAL')" />
        <div class="container-b2b__analysis-selections">
          <BoxOutputCompanyLegalDoc
            v-if="canShowBoxOutputCompanyLegalDoc"
            :legal-doc="checkCompanyAttachmentsData.legalActs"
            :registration-number="checkCompanyLegalUnitData.registrationNumber"
            :journey-id="journeyId"
            :can-company-legal-doc="permissionsSorted.includes('KIT_B2B_COMPANY_LEGAL_DOC_GLOBAL')"
            :country="checkCompanyCountryData"
          />
          <BoxOutputAnnualAccounts
            v-if="canShowBoxOutputAnnualAccounts"
            :country="checkCompanyCountryData"
            :tax-reports="checkCompanyAttachmentsData.taxReports" :journey-id="journeyId"
            :can-annual-accounts="permissionsSorted.includes('KIT_B2B_COMPANY_ANNUAL_ACCOUNT_GLOBAL')" />
        </div>
        <div class="container-b2b__analysis-selections">
          <BoxOutputEstablishments
            v-if="canShowBoxOutputEstablishments"
            :establishments="establishmentTransformer(checkCompanyCountryData, checkCompanyEstablishmentsData)"
            :company-country="checkCompanyCountryData"
            :can-establishments="permissionsSorted.includes('KIT_B2B_COMPANY_ESTABLISHMENTS_GLOBAL')" />
          <BoxOutputBodaccAnnouncements
            v-if="canShowBoxOutputBodaccAnnouncements"
            :announcements="bodaccAnnouncementTransformer(checkCompanyCountryData, checkCompanyLegalObservationData)"
            :can-bodacc-announcements="permissionsSorted.includes('KIT_B2B_COMPANY_EVOLUTION_GLOBAL')" />
        </div>
        <BoxOutputScoreCreditSafe
          v-if="canShowBoxOutputScoreCreditSafe"
          :causes-credit-safe="causesCreditSafeData"
          :is-company-f-r="isCountryFR"
          :is-data-loading="isScoreCreditSafeLoading"
          :is-error="displayCreditSafeError"
          :score-credit-safe="scoreCreditSafeData"
          :color-credit-safe-loc="colorCreditSafeLoc"
          :color-credit-safe-inter="colorCreditSafeInter"
          :can-credit-safe="permissionsSorted.includes('KIT_B2B_CREDIT_SAFE_GLOBAL')"
          @compute-credit-safe-score="computeCreditSafeScore"
        />
        <!--        <BoxInputEnrichment
                  v-if="canShowBoxInputEnrichment"
                  @check-linkedin="getEnrichment"
                  :disabled-linkedin-button="disabledEnrichmentButton"
                  :can-enrichment="permissionsSorted.includes('KIT_B2B_ENRICHMENT_GLOBAL')"
                />
                <BoxOutputEnrichment
                  v-if="canShowBoxOutputEnrichment"
                  :enrichment="enrichmentData"
                  :loader="enrichmentLoader"
                  :client-form="prefillClientForm"
                  :can-enrichment="permissionsSorted.includes('KIT_B2B_ENRICHMENT_GLOBAL')" />-->
        <BoxInputAccountantVerification
          v-if="canShowBoxInputAccountVerification"
          @accountant-verification="accountantVerification"
        />
        <BoxOutputAccountantVerification
          v-if="isAccountantVerificationDataAvailable"
          :warning-list="warningListCombinedData"
          :accountant-verification-data="accountantVerificationData"
          :is-loading="isLoadingAccountantWarningList"
        />
        <BoxOutputEReputation
          v-if="canShowBoxOutputEReputation"
          :e-reputation-data="causesEReputationData"
          :show-e-reputation-loader="isEReputationLoading"
          :scoring-e-reputation="scoreEReputationData" :color="colorEReputation"
          :permissions="permissionsSorted" :can-e-reputation="eReputationBlock" />
        <template v-if="!(Object.keys(obFormattedFields).length > 0)">
          <BoxInputOpenBanking v-if="canShowBoxInputOpenBanking" @generate-link="generateLink"
                               :prop-prefill-client-form="{}"
                               :company-country="checkCompanyCountryData"
                               :permissions="permissionsSorted" :can-open-baking="openBankingInputPermissions">
            <template v-if="openBankingLink != ''">
              <div class="openbanking-bloc-link" v-if="obStarted && !obEnded">
                <a :href="openBankingLink" target="_blank">
                  <Button class="openbanking-bloc-link-button" @click="goOpenBanking"
                          :label="translate('BUTTONS.JOURNEY_OPEN_BANKING')"
                  />
                </a>
              </div>
            </template>
          </BoxInputOpenBanking>
        </template>
        <BoxOutputOpenBanking
          v-if="canShowBoxOutputOpenBanking"
          :ob-data="obFormattedFields" :color-score="colorScoreOB"
          :refresh-loader="this.obRefreshedDataLoading"
          :disable-loader="this.obDisabledDataLoading"
          @refresh-task="this.handleTaskRefresh()"
          @disable-continuous-access="this.handleDisableContinuousAccess()"
          :can-open-banking="permissionsSorted.includes('KIT_B2B_OB_RESUME_GLOBAL')" :company="user?.company?.name" />
        <BoxOutputOpenBankingDetail
          v-if="canShowBoxOutputOpenBankingDetail"
          @to-account-summary-event="handleAccountSummary"
          :ob-data="obFormattedFields"
          :permissions="permissionsSorted"
          :can-open-banking="permissionsSorted.includes('KIT_B2B_OB_DETAILS_GLOBAL')"
          :company="user?.company?.name"
        />
        <BoxOutputOpenBankingAlert
          v-if="canShowBoxOutputOpenBankingAlert"
          :ob-data="obFormattedFields"
          :can-open-banking="permissionsSorted.includes('KIT_B2B_OB_ALERTS_GLOBAL')" />
        <div class="no-print">
          <BoxOutputOpenBankingBankStatement v-if="isAccountSummaryButtonClicked" :ob-data="obFormattedFields" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { searchAmlcft } from '@/apiCalls/amlcftApi';
import { checkIbanCompany, getBankName } from '@/apiCalls/checkIban';
import { companyDetails } from '@/apiCalls/companyCheck';
import { getEReputation, getScoringB2B, getScoringSMB } from '@/apiCalls/scoringApi';
import { computeScoreAltares, computeScoreCreditSafe } from '@/apiCalls/scoringProxy';
import { addJourneyIdStatus, sendApplicantInformation, sendAccountantVerificationData } from '@/apiCalls/taskApi';
import { businnessOwnerTransformer } from '@/utils/dataTransformer/config/businessOwner/businessOwnerConfig';
import { representativeTransformer } from '@/utils/dataTransformer/config/representative/representativeConfig';
import { companyInfoTransformer } from '@/utils/dataTransformer/config/companyInfo/companyInfoConfig';
import { establishmentTransformer } from '@/utils/dataTransformer/config/establishment/establishmentConfig';
import {
  bodaccAnnouncementTransformer
} from '@/utils/dataTransformer/config/bodaccAnnouncement/bodaccAnnouncementConfig';
import { isDataAvalaible } from '@/utils/utils';
import LabelValuePrinter from '@/components/LabelValuePrinter.vue';
import ScoreCircleChart from '@/components/ScoreCircleChart.vue';
import Applicant from '@/components/blocks/Applicant.vue';
import BoxInputEnrichment from '@/components/box-input/BoxInputEnrichment.vue';
import BoxInputOpenBanking from '@/components/box-input/BoxInputOpenBanking.vue';
import BoxInputPersonalInfo from '@/components/box-input/BoxInputPersonalInfo.vue';
import BoxInputSiren from '@/components/box-input/BoxInputSiren.vue';
import BoxOutputCheckIbanB2b from '@/components/box-output/BoxOutputCheckIbanB2b.vue';
import BoxOutputAmlcft from '@/components/box-output/BoxOutputAmlcft.vue';
import BoxOutputAnnualAccounts from '@/components/box-output/BoxOutputAnnualAccounts.vue';
import BoxOutputBodaccAnnouncements from '@/components/box-output/BoxOutputBodaccAnnouncements.vue';
import BoxOutputCompanyFinances from '@/components/box-output/BoxOutputCompanyFinances.vue';
import BoxOutputCompanyInfo from '@/components/box-output/BoxOutputCompanyInfo.vue';
import BoxOutputCompanyLegalDoc from '@/components/box-output/BoxOutputCompanyLegalDoc.vue';
import BoxOutputDocument from '@/components/box-output/BoxOutputDocument.vue';
import BoxOutputEReputation from '@/components/box-output/BoxOutputEReputation.vue';
import BoxOutputEnrichment from '@/components/box-output/BoxOutputEnrichment.vue';
import BoxOutputEstablishments from '@/components/box-output/BoxOutputEstablishments.vue';
import BoxOutputOpenBanking from '@/components/box-output/BoxOutputOpenBanking.vue';
import BoxOutputOpenBankingAlert from '@/components/box-output/BoxOutputOpenBankingAlert.vue';
import BoxOutputOpenBankingDetail from '@/components/box-output/BoxOutputOpenBankingDetail.vue';
import {
  default as BoxOutputScoreAltares,
  default as ScoringAltares
} from '@/components/box-output/BoxOutputScoreAltares.vue';
import {
  default as BoxOutputScoreCompany,
  default as ScoringLiteBoxB2B
} from '@/components/box-output/BoxOutputScoreCompany.vue';
import BoxOutputScoreSMB from '@/components/box-output/BoxOutputScoreSMB.vue';
import OutputWarningList, {
  default as ComponentOutputWarningList
} from '@/components/box-output/BoxOutputWarningList.vue';
import {
  default as Button,
  default as ButtonMeelo
} from '@/components/button/Button.vue';
import ContentCard from '@/components/card/ContentCard.vue';
import CalendarInput from '@/components/input/CalendarInput.vue';
import TextInput from '@/components/input/TextInput.vue';
import Loader from '@/components/loader/Loader.vue';
import PrintB2BBusinessOwner from '@/components/print/analysis-b2b/PrintB2BBusinessOwner.vue';
import PrintB2BBusinessRepresent from '@/components/print/analysis-b2b/PrintB2BBusinessRepresent.vue';
import PrintInfoWithLabel from '@/components/print/analysis-b2b/PrintInfoWithLabel.vue';
import PrintApplicantOB from '@/components/print/shared/PrintApplicantOB.vue';
import PrintCard from '@/components/print/shared/PrintCard.vue';
import PrintFolderInfo from '@/components/print/shared/PrintFolderInfo.vue';
import ScoringDetailedBox from '@/components/scoring-detailed-box/ScoringDetailedBox.vue';
import { translate } from '@/i18n';
import OpenBankingMixin from '@/mixins/openBankingMixin';
import TemplateMixin from '@/mixins/templateMixin';
import utilsMixin from '@/mixins/utilsMixin';
import { CustomerProfileV3 } from '@/types/CustomerProfile';
import { getFormattedRegistrationNumber } from '@/utils/formatRegistrationNumber';
import { computeClientInfoEntries, computeCompanyInfoEntries } from '@/utils/printAnalysisB2B';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { SearchImage } from '@/types/SearchImage';
import { searchImage } from '@/apiCalls/faceMatchingApi';
import BoxOutputCompanyCartography from '@/components/box-output/BoxOutputCompanyCartography.vue';
import _, { isNull, isArray, isEmpty, isObject, isString, isEqual, padStart, isNil, has } from 'lodash';
import DocumentAnalysis from '@/views/DocumentAnalysis.vue';
import BoxOutputCompanyLitigation from '@/components/box-output/BoxOutputCompanyLitigation.vue';
import BoxOutputOpenBankingBankStatement from '@/components/box-output/BoxOutputOpenBankingBankStatement.vue';
import BoxInputStateFolder from '@/components/box-input/BoxInputStateFolder.vue';
import DeleteFolderModal from '@/components/modal/DeleteFolderModal.vue';
import { states } from '@/utils/statesFolder';
import EditFolderModal from '@/components/modal/EditFolderModal.vue';
import BoxOutputScoreCreditSafe from '@/components/box-output/BoxOutputScoreCreditSafe.vue';
import BoxOutputOwnerRepresentative from '@/components/box-output/BoxOutputOwnerRepresentative.vue';
import CheckIbanBox from '@/components/check-iban/CheckIbanBox.vue';
import BoxOutputCheckList from '@/components/box-output/BoxOutputCheckList.vue';
import { setRule } from '@/apiCalls/rulesEngine';
import BoxInputAccountantVerification from '@/components/box-input/BoxInputAccountantVerification.vue';
import BoxOutputAccountantVerification from '@/components/box-output/BoxOutputAccountantVerification.vue';
import { searchCombinedWarningList, searchWarningList } from '@/apiCalls/warningListApi';

export default defineComponent({
  name: 'AnalysisB2B',
  mixins: [TemplateMixin, OpenBankingMixin, utilsMixin],
  data: () => ({
    isEReputationLoading: false,
    isScoreB2BLoading: false,
    isCheckCompanyLoading: false,
    isScoreSMBLoading: false,
    isScoreAltaresLoading: false,
    isScoreCreditSafeLoading: false, allDocumentType: [] as string[],
    allDocumentTypeValue: [] as string[],
    chosenTax: {} as any,
    chosenTaxWarning: false,
    documentId: '',
    showCheckIbanLoader: false,
    prefillClientForm: {
      lastName: { disabled: false, value: '' },
      firstNames: { disabled: false, value: '' },
      phone: { disabled: false, value: '+33' },
      email: { disabled: false, value: '' },
      companyName: { disabled: false, value: '' },
      country: { disabled: false, value: '' },
      city: { disabled: false, value: '' },
      zipcode: { disabled: false, value: '' },
      street: { disabled: false, value: '' },
      birthDate: { disabled: false, value: '' },
      iban: { disabled: false, value: '' }
    } as any,
    clearRepresentative: 0,
    clearBusinessOwner: 0,
    disableFraudScoreButton: false,
    disableSmbScoreButton: false,
    disableScoreFraudCompanyButton: false,
    disableIbanButton: false,
    disableAmlcftButton: false,
    messageCheckIban: false,
    colorScoreOB: '',
    isUploadEnrichment: false,
    disabledEnrichmentButton: false,
    taxReportLoading: false,
    colorCreditSafe: '',
    displayCreditSafeError: false,
    enrichmentLoader: false,
    searchImage: {} as SearchImage,
    ibanResponse: '',
    warningListLoading: false,
    isCheckIbanV1: false,
    isLoadingAccountantWarningList: false
  }),
  components: {
    BoxOutputCheckList,
    BoxOutputOwnerRepresentative,
    EditFolderModal,
    DeleteFolderModal,
    BoxOutputScoreCreditSafe,
    BoxOutputOpenBankingBankStatement,
    BoxInputStateFolder,
    BoxOutputCompanyLitigation,
    OutputWarningList,
    BoxOutputCompanyFinances,
    BoxOutputAnnualAccounts,
    BoxOutputBodaccAnnouncements,
    BoxOutputCompanyLegalDoc,
    BoxOutputEstablishments,
    BoxOutputOpenBankingAlert,
    BoxOutputOpenBankingDetail,
    BoxOutputOpenBanking,
    BoxInputOpenBanking,
    BoxOutputEReputation,
    BoxOutputScoreAltares,
    BoxOutputDocument,
    BoxOutputScoreCompany,
    BoxOutputAmlcft,
    BoxOutputScoreSMB,
    ComponentOutputWarningList,
    BoxOutputCompanyInfo,
    BoxInputPersonalInfo,
    BoxInputSiren,
    BoxOutputCheckIbanB2b,
    CheckIbanBox,
    Applicant,
    ScoringDetailedBox,
    ScoringAltares,
    ScoringLiteBoxB2B,
    Loader,
    Button,
    CalendarInput,
    ButtonMeelo,
    ScoreCircleChart,
    LabelValuePrinter,
    ContentCard,
    TextInput,
    BoxInputEnrichment,
    BoxOutputEnrichment,
    PrintFolderInfo,
    PrintInfoWithLabel,
    PrintB2BBusinessOwner,
    PrintB2BBusinessRepresent,
    PrintApplicantOB,
    PrintCard,
    BoxOutputCompanyCartography,
    DocumentAnalysis,
    BoxInputAccountantVerification,
    BoxOutputAccountantVerification
  },
  async created() {
    window.addEventListener('resetAnalysisB2b', this.cleanAnalysis);
    this.document;
    const registrationNumber = this.$route.query.registrationNumber?.toString();
    const country = this.$route.query.country?.toString();
    const defaultFirstName = this.$route.query.firstName;
    const defaultLastName = this.$route.query.lastName;
    const defaultBirthDate = this.$route.query.birthDate;
    const defaultMobilePhone = this.$route.query.mobilePhone;
    const defaultEmail = this.$route.query.email;
    if (defaultFirstName) {
      this.prefillClientForm.firstNames.value = defaultFirstName;
    }
    if (defaultLastName) {
      this.prefillClientForm.lastName.value = defaultLastName;
    }
    if (defaultBirthDate) {
      this.prefillClientForm.birthDate.value = defaultBirthDate;
    }
    if (defaultEmail) {
      this.prefillClientForm.email.value = defaultEmail;
    }
    if (defaultMobilePhone) {
      this.prefillClientForm.phone.value = defaultMobilePhone;
    }
    if (registrationNumber && country) {
      await this.companySearch(registrationNumber, country);
    }
  },
  beforeUnmount() {
    window.removeEventListener('resetAnalysisB2b', this.cleanAnalysis);
  },
  unmounted() {
    this.clearResponseStatus();
  },
  watch: {
    applicantData(data: any) {
      if (!data) return;
      const { email, firstname, lastname, addresses, mobilePhone, birthDate, iban }: {
        email?: string;
        mobilePhone?: string;
        firstname?: string;
        lastname?: string;
        birthDate?: string;
        addresses?: { street1: string; city: string; zipcode: string; country: string }[];
        iban?: string;
      } = data;

      this.fillForm('email', email);
      this.fillForm('phone', mobilePhone);
      this.fillForm('firstNames', firstname);
      this.fillForm('lastName', lastname);
      this.fillForm('birthDate', birthDate);
      this.fillForm('iban', iban);

      if (!isEmpty(this.ibanResponse)) {
        this.prefillClientForm['iban'].value = this.ibanResponse;
        this.prefillClientForm['iban'].disabled = true;
      }

      const address = addresses ? addresses[0] : { street1: '', city: '', zipcode: '', country: '' };
      this.prefillClientForm.street.value = address?.street1;
      this.prefillClientForm.street.disabled = !!address?.street1;
      this.prefillClientForm.city.value = address?.city;
      this.prefillClientForm.city.disabled = !!address?.city;
      this.prefillClientForm.zipcode.value = address?.zipcode;
      this.prefillClientForm.zipcode.disabled = !!address?.zipcode;
      this.prefillClientForm.country.value = address?.country;
      this.prefillClientForm.country.disabled = !!address?.country;

      if (this.isDuplicateFolder()) {
        this.allowModificationTextInput(this.prefillClientForm);
      }
    },
    async obFormattedFields() {
      this.colorScoreOB = await this.defineColor(
        'score_ob',
        this.obFormattedFields.score,
        this.journeyId
      );
    },
    async 'contextData.check_iban_details'(newValue: any) {
      const iban = this.prefillClientForm.iban.value;
      if (iban && newValue.length > 0) {

        if (newValue[0] && newValue[0].checks && newValue[0].checks.length > 0) {
          this.isCheckIbanV1 = newValue[0].checks[0].hasOwnProperty('scope');
        }

        if (this.isCountryFR) {
          const bank = await getBankName(iban, this.journeyId);
          this.contextData.check_iban_details[0] = Object.assign({}, this.contextData.check_iban_details[0], {
            bank: {
              ...bank.data.result,
              iban
            }
          });
        }

      }
    },
    '$route'(val) {
      if (val.query.duplicate) {
        this.hasCheckList = false;
        this.allowModificationTextInput(this.prefillClientForm);
      }
    }
  },
  computed: {
    isFR() {
      return this.checkCompanyCountryData === 'FR';
    },
    disablePersonSelection() {
      return !!(this.applicantData?.firstname || this.applicantData?.lastname);
    },
    headquarter() {
      return this.checkCompanyEstablishmentsData?.filter((e: any) => e.headquarter)[0];
    },
    ...mapGetters(['user', 'kits', 'permissions', 'permissionsSorted', 'responseStatus', 'companyParameters']),
    bodaccAnnouncementsBlock() {
      return (this.checkCompanyLegalUnitData && !this.permissionsSorted?.includes('KIT_B2B_COMPANY_EVOLUTION_GLOBAL')) || (this.checkCompanyLegalObservationData && this.permissionsSorted.includes('KIT_B2B_COMPANY_EVOLUTION_GLOBAL'));
    },
    scoreAltaresBlock() {
      return (this.isScoreSmb && !this.permissionsSorted?.includes('KIT_B2B_ALTARES_GLOBAL')) || (this.checkCompanyLegalUnitData && this.permissionsSorted?.includes('KIT_B2B_ALTARES_GLOBAL'));
    },
    scoreCreditSafeBlock() {
      return (!this.permissionsSorted.includes('KIT_B2B_CREDIT_SAFE_GLOBAL')) || (this.checkCompanyLegalUnitData && this.permissionsSorted?.includes('KIT_B2B_CREDIT_SAFE_GLOBAL'));
    },
    openBankingInputPermissions() {
      return this.permissionsSorted?.some((elt: string | string[]) => elt.includes('KIT_B2B_OB_INPUT_MAIL')
        || elt.includes('KIT_B2B_OB_INPUT_TEXT_MESSAGE'));
    },
    canShowBoxOutputBusinessOwner() {
      return (this.checkCompanyLegalUnitData && this.isDataAvalaible(this.checkCompanyLegalUnitData) && !this.businessOwnersBlock) || (this.checkCompanyShareHoldersData && this.isDataAvalaible(this.checkCompanyShareHoldersData) && this.businessOwnersBlock) && !this.isDuplicateFolder();
    },
    canShowBoxOutputRepresentative() {
      return (this.checkCompanyLegalUnitData && this.isDataAvalaible(this.checkCompanyLegalUnitData) && !this.representativesBlock) ||
        ((this.checkCompanyRepresentativesData) && this.isDataAvalaible(this.checkCompanyRepresentativesData) && this.representativesBlock) && !this.isDuplicateFolder();
    },
    canShowBoxInputPersonalInfo() {
      return this.checkCompanyLegalUnitData && this.isDataAvalaible(this.checkCompanyLegalUnitData) && !this.isDuplicateFolder();
    },
    canShowBoxOutputCompanyInfo() {
      return (this.checkCompanyLegalUnitData && this.isDataAvalaible(this.checkCompanyLegalUnitData) && !this.companyInfoBlock) || (((this.checkCompanyLegalUnitData && this.isDataAvalaible(this.checkCompanyLegalUnitData)) || this.isCheckCompanyLoading) && this.companyInfoBlock) && !this.isDuplicateFolder();
    },
    canShowOutputWarningList() {
      return (((this.scoreB2BDataV && this.scoreB2BDataV.length > 0) || (this.scoreSMBData && this.scoreSMBData.length > 0)) || this.isScoreB2BLoading || this.isScoreSMBLoading) && this.permissionsSorted?.includes('KIT_B2B_WARNING_LIST_GLOBAL') && !this.isDuplicateFolder();
    },
    canShowBoxOutputScoreSMB() {
      return ((this.isScoreCompany || this.checkIbanData || this.amlCftMatchesData) && !this.scoreSMBBlock) || ((this.isScoreSmb || this.isScoreSMBLoading) && this.scoreSMBBlock) && !this.isDuplicateFolder();
    },
    canShowBoxOutputScoreCompany() {
      return !has(this.causesB2BDataV, 'isCancelled') && ((this.isScoreSmb || this.checkIbanData || this.amlCftMatchesData) && !this.companyScoreBlock) || (((this.isScoreCompany || this.isScoreCompanyV1) || this.isScoreB2BLoading) && this.companyScoreBlock) && !this.isDuplicateFolder();
    },
    isDisableFraudScoreButton() {
      return this.disableFraudScoreButton || (this.isDuplicateFolder() ? false : ((this.scoreSMBData && this.scoreSMBData.length > 0) || (this.scoreB2BDataV && this.scoreB2BDataV.length > 0)));
    },
    isDisableIbanButton() {
      return this.disableIbanButton || (this.isDuplicateFolder() ? false : this.checkIbanData);
    },
    isDisableAmlcftButton() {
      return this.disableAmlcftButton || (this.isDuplicateFolder() ? false : this.amlCftMatchesData);
    },
    canShowBoxOutputCompanyCartography() {
      return ((this.isScoreSmb || this.checkIbanData || this.amlCftMatchesData) && !this.cartographyBlock) || ((this.isScoreCompany || this.isScoreB2BLoading) && this.cartographyBlock) && !this.isDuplicateFolder();
    },
    canShowBoxOutputCheckIbanB2b() {
      return ((this.causesB2BDataV || this.causesSMBData || this.amlCftMatchesData) && !this.checkIbanBlock) || ((this.showCheckIbanLoader || this.messageCheckIban || this.checkIbanData) && this.checkIbanBlock) && !this.isDuplicateFolder();
    },
    canShowBoxOutputAmlcft() {
      return ((this.causesB2BDataV || this.causesSMBData || this.checkIbanData) && !this.amlcftBlock) || (this.amlCftMatchesData && this.amlcftBlock) && !this.isDuplicateFolder();
    },
    canShowDocumentAnalysis() {
      return ((this.isScoreSmb || this.isScoreCompany) || (this.checkCompanyLegalUnitData && this.isDataAvalaible(this.checkCompanyLegalUnitData))) && !this.isDuplicateFolder();
    },
    canShowBoxOutputCompanyFinances() {
      return (this.checkCompanyLegalUnitData && this.isDataAvalaible(this.checkCompanyLegalUnitData) && !this.companyFinancesBlock) || (this.checkCompanyLegalUnitData && this.isDataAvalaible(this.checkCompanyLegalUnitData) && this.taxReportsSummaryData && this.companyFinancesBlock) && !this.isDuplicateFolder();
    },
    canShowBoxOutputCompanyLegalDoc() {
      return (this.checkCompanyLegalUnitData && this.isDataAvalaible(this.checkCompanyLegalUnitData) && !this.companyLegalDocBlock) || (this.checkCompanyAttachmentsData && this.isDataAvalaible(this.checkCompanyAttachmentsData) && this.companyLegalDocBlock) && !this.isDuplicateFolder();
    },
    canShowBoxOutputAnnualAccounts() {
      return (this.checkCompanyLegalUnitData && this.isDataAvalaible(this.checkCompanyLegalUnitData) && !this.annualAccountsBlock) || (this.checkCompanyAttachmentsData && this.isDataAvalaible(this.checkCompanyAttachmentsData) && this.annualAccountsBlock) && !this.isDuplicateFolder();
    },
    canShowBoxOutputEstablishments() {
      return (this.checkCompanyLegalUnitData && this.isDataAvalaible(this.checkCompanyLegalUnitData) && !this.establishmentsBlock) || (this.checkCompanyEstablishmentsData && this.isDataAvalaible(this.checkCompanyEstablishmentsData) && this.establishmentsBlock) && !this.isDuplicateFolder();
    },
    canShowBoxOutputBodaccAnnouncements() {
      return (this.checkCompanyLegalUnitData && this.isDataAvalaible(this.checkCompanyLegalUnitData) && !this.bodaccAnnouncementsBlock) || (this.checkCompanyLegalObservationData && this.isDataAvalaible(this.checkCompanyLegalObservationData) && this.bodaccAnnouncementsBlock) && !this.isDuplicateFolder();
    },
    canShowBoxOutputCompanyLitigation() {
      return (this.isScoreCompany || this.isScoreB2BLoading) && this.checkCompanyCountryData === 'FR' && !this.isDuplicateFolder();
    },
    canShowBoxOutputScoreCreditSafe() {
      return this.checkCompanyLegalUnitData && this.isDataAvalaible(this.checkCompanyLegalUnitData) && !this.isDuplicateFolder();
    },
    canShowBoxInputEnrichment() {
      return this.checkCompanyLegalUnitData && this.isDataAvalaible(this.checkCompanyLegalUnitData) && this.enrichmentInputBlock && !this.isDuplicateFolder();
    },
    canShowBoxOutputEnrichment() {
      return this.enrichmentOutputBlock && !this.isDuplicateFolder();
    },
    canShowBoxOutputEReputation() {
      return (this.checkCompanyLegalUnitData && !this.eReputationBlock) || (this.isEReputationLoading && this.eReputationBlock) || (this.causesEReputationData && this.scoreEReputationData && this.eReputationBlock) && !this.isDuplicateFolder();
    },
    canShowBoxInputOpenBanking() {
      return this.checkCompanyLegalUnitData && this.isDataAvalaible(this.checkCompanyLegalUnitData) && !this.isDuplicateFolder();
    },
    canShowBoxInputDocument() {
      return ((this.isScoreSmb || this.isScoreCompany) && !this.uploadDocumentBlock) || (this.checkCompanyLegalUnitData && this.uploadDocumentBlock) && !this.isDuplicateFolder();
    },
    canShowBoxOutputDocument() {
      return ((this.isScoreSmb || this.isScoreCompany) && !this.restitutionDocumentBlock) || (this.checkCompanyLegalUnitData && this.restitutionDocumentBlock) && !this.isDuplicateFolder();
    },
    canShowBoxOutputScoreAltares() {
      return this.checkCompanyLegalUnitData && !this.scoreAltaresBlock && !this.isDuplicateFolder();
    },
    canShowBoxInputAccountVerification() {
      return !this.showInputSirenButton && !this.isAccountantVerificationDataAvailable && !isEmpty(this.checkCompanyLegalUnitData) && (this.isScoreSmb || this.isScoreCompany) && !this.isDuplicateFolder();
    },
    showInputSirenButton() {
      return !this.checkCompanyLegalUnitData && !this.isCheckCompanyLoading;
    },
    document() {
      if (this.permissionsSorted.includes('KIT_B2B_UPLOAD_ID_CHECK')) {
        this.allDocumentType.push(translate('DOCUMENT_CONTROL.ID_CARD'));
        this.allDocumentTypeValue.push('ID_CHECK');
      }

      if (this.permissionsSorted.includes('KIT_B2B_UPLOAD_AUTHENTICITY')) {
        this.allDocumentType.push(translate('DOCUMENT_CONTROL.AUTHENTICITY') + ' (' + translate('ONLY_PDF') + ')');
        this.allDocumentTypeValue.push('OTHER');
      }
    },
    filteredTaxReportsSummaryData() {
      return this.getBoxOutputCompanyFinanceData(this.taxReportsSummaryData);
    },
    getWarningListData() {
      if (this.causesB2BDataV) return this.causesB2BDataV.warningList; //if both scores -> return B2B WL
      else if (this.causesSMBData) return this.causesSMBData?.warningList;
    },
    displayCheckIbanBox() {
      return ((this.causesB2BDataV || this.causesSMBData || this.amlCftMatchesData) && !this.checkIbanBlock) || ((this.showCheckIbanLoader || this.messageCheckIban || this.checkIbanData) && this.checkIbanBlock);
    },
    isAccountantVerificationDataAvailable() {
      return !isEmpty(this.accountantVerificationData) && !this.isDuplicateFolder();
    }
  },
  methods: {
    fillForm(attribute: keyof {
      lastName: { disabled: boolean; value: string };
      firstNames: { disabled: boolean; value: string };
      phone: { disabled: boolean; value: string };
      email: { disabled: boolean; value: string };
      companyName: { disabled: boolean; value: string };
      country: { disabled: boolean; value: string };
      city: { disabled: boolean; value: string };
      zipcode: { disabled: boolean; value: string };
      street: { disabled: boolean; value: string };
      birthDate: { disabled: boolean; value: string };
      iban: { disabled: boolean; value: string };
    }, value?: string) {
      if (value) {
        this.prefillClientForm[attribute].value = value;
        this.prefillClientForm[attribute].disabled = true;
        return;
      }
      this.prefillClientForm[attribute].disabled = false;
    },
    bodaccAnnouncementTransformer,
    establishmentTransformer,
    companyInfoTransformer,
    businnessOwnerTransformer,
    representativeTransformer,
    isNil,
    isString,
    states() {
      return states;
    },
    isNull,
    isEmpty,
    isArray,
    isObject,
    getFormattedRegistrationNumber,
    translate,
    computeCompanyInfoEntries,
    computeClientInfoEntries,
    goOpenNewTabFromChart(siren: string, companyCountry: string) {
      const { firstname, lastname, birthDate, email, mobilePhone } = this.applicantData || {};
      const queryParams = {
        registrationNumber: siren,
        country: companyCountry,
        firstName: isString(firstname) ? firstname : '',
        lastName: isString(lastname) ? lastname : '',
        birthDate: isString(birthDate) ? birthDate : '',
        email: isString(email) ? email : '',
        mobilePhone: isString(mobilePhone) ? mobilePhone : ''
      };
      const url = this.$router.resolve({
        name: 'AnalysisB2B',
        query: queryParams
      }).href;
      window.open(url, '_blank');
    },
    cleanAnalysis() {
      this.isEReputationLoading = false;
      this.isScoreB2BLoading = false;
      this.isCheckCompanyLoading = false;
      this.isScoreSMBLoading = false;
      this.isScoreAltaresLoading = false;
      this.cleanOpenBanking();
      this.contextData = {};
      this.prefillClientForm = {
        lastName: { disabled: false, value: '' },
        firstNames: { disabled: false, value: '' },
        phone: { disabled: false, value: '+33' },
        email: { disabled: false, value: '' },
        country: { disabled: false, value: '' },
        city: { disabled: false, value: '' },
        zipcode: { disabled: false, value: '' },
        street: { disabled: false, value: '' },
        birthDate: { disabled: false, value: '' },
        iban: { disabled: false, value: '' }
      };
      this.disableAmlcftButton = false;
      this.disableSmbScoreButton = false;
      this.disableScoreFraudCompanyButton = false;
      this.disableFraudScoreButton = false;
      this.disableIbanButton = false;
      this.isScoreCreditSafeLoading = false;
      this.clearResponseStatus();
      this.disabledEnrichmentButton = false;
      this.ibanResponse = '';
      this.journeyIdStatus = '';
      this.hasCheckList = false;
    },
    askToPrefillClientForm(data: any) {
      this.prefillClientForm.lastName.value = data.lastName;
      this.prefillClientForm.firstNames.value = data.firstNames
        ?.split(', ', 1)
        ?.map((e: string) => e.trim())
        ?.toString();
      this.prefillClientForm.birthDate.value = data.birthDate;
      this.prefillClientForm.street.value = data?.address?.address;
      this.prefillClientForm.zipcode.value = data?.address?.zipcode;
      this.prefillClientForm.city.value = data?.address?.city;
      this.prefillClientForm.country.value = data?.address?.country;

      if (data.type === 'businessOwner') {
        this.clearRepresentative++;
      } else {
        this.clearBusinessOwner++;
      }
    },
    async companySearch(registrationNumber: string, country?: string) {
      if (this.isDuplicateFolder()) {
        this.disableFraudScoreButton = false;
        this.disableSmbScoreButton = false;
        this.disableScoreFraudCompanyButton = false;
        this.disableIbanButton = false;
        this.disableAmlcftButton = false;
      }
      if (!_.isNull(this.journeyId) || this.isDuplicateFolder()) {
        this.warningListLoading = true;
        this.isCheckCompanyLoading = true;
        this.contextData = {};
        this.contextData.company_registration_number = [registrationNumber];
        await this.createJourneyId('portal-b2b');
        let formattedRegistrationNumber = registrationNumber.replaceAll(' ', '');
        if (isEqual(country, 'BE')) formattedRegistrationNumber = padStart(formattedRegistrationNumber, 10, '0');
        const response = await companyDetails(formattedRegistrationNumber, this.journeyId, country);
        await this.getWarningList(this.journeyId, 'MEELO_BLACKLIST', 'AND', {
          REGISTRATION_NUMBER: formattedRegistrationNumber,
          COUNTRY: country
        }, 'SIREN');
        this.warningListLoading = false;
        if (response.data.status !== 'FAILED') {
          this.contextData.company_legal_unit = response?.data?.result != null ? [response.data?.result.company.legalUnit] : [];
          this.contextData.company_attachments = response?.data?.result != null ? [response.data?.result.company.attachments] : [];
          this.contextData.company_legal_observations = response?.data?.result != null ? [response.data?.result.company.legalObservations] : [];
          this.contextData.company_establishments = response?.data?.result != null ? [response.data?.result.company.establishments] : [];
          this.contextData.company_representatives = response?.data?.result != null ? [response.data?.result.company.representatives] : [];
          this.contextData.company_share_holders = response?.data?.result != null ? [response.data?.result.company.shareHolders] : [];
          this.contextData.company_country = response?.data?.result != null ? [response.data?.result.company.country] : [];
          this.contextData.company_registration_number = response?.data?.result != null ? [response.data?.result.company.registrationNumber] : [];
          this.isCheckCompanyLoading = false;

          this.getEReputation();
          await this.analyseTaxReport();
        } else {
          await addJourneyIdStatus(this.journeyId, 'DELETED', 'string');
          //const toast = useToast();
          //toast.error(this.translate("ERRORS.COMPANY_NOT_FOUND"));
        }
      }
    },
    computeAltaresScore() {
      this.isScoreAltaresLoading = true;
      computeScoreAltares(this.checkCompanyLegalUnitData.registrationNumber, this.journeyId).then(async (result) => {
        this.contextData.altares_causes = result?.causes != null ? [result.causes] : [];
        this.contextData.score_altares = [result.result.score];
        if (this.scoreAltaresData && this.colorAltares !== '#D9D9D9') this.colorAltares = await this.defineColor('', this.scoreAltaresData, this.journeyId);
        if (this.causesAltaresData.scoreSector && this.colorScoreSector !== '#D9D9D9') this.colorScoreSector = await this.defineColor('', this.causesAltaresData.scoreSector, this.journeyId);
        if (this.causesAltaresData.defaultProbability && this.colorDefaultProbability !== '#D9D9D9') this.colorDefaultProbability = await this.defineColor('default_probability', this.causesAltaresData.defaultProbability, this.journeyId);
        this.isScoreAltaresLoading = false;
      });
    },
    computeCreditSafeScore() {
      this.isScoreCreditSafeLoading = true;
      computeScoreCreditSafe(this.checkCompanyRegistrationNumberData, this.checkCompanyCountryData, this.journeyId).then(async (response) => {
        if (response.status === 'FAILED') {
          this.displayCreditSafeError = true;
        }
        this.contextData.credit_safe_causes = response.causes ? [response.causes] : [];
        this.isScoreCreditSafeLoading = false;

        const checklist = await setRule('CHECKLIST_B2B', this.journeyId);
        this.checkList = checklist.result;
        this.hasCheckList = true;
      });
    },
    async computeScores(customerProfileV3: CustomerProfileV3, label?: string) {
      const { data: { data: applicantInfo } } = await sendApplicantInformation(customerProfileV3, this.journeyId);
      this.contextData.applicant = applicantInfo != null ? [applicantInfo] : [];
      if (this.companyParameters.displayScoreFraudCompanyAndSMB === 'true' && label !== '') {
        if (label === 'smbScore' && this.scoreSMBBlock) await this.computeScoreSMB(customerProfileV3);
        if (label === 'scoreFraudCompany' && this.companyScoreBlock) this.computeScoreB2B(customerProfileV3);
      } else {
        if (this.companyScoreBlock) this.computeScoreB2B(customerProfileV3);
        if (this.scoreSMBBlock) await this.computeScoreSMB(customerProfileV3);
      }
    },
    computeScoreB2B(customerProfileV3: CustomerProfileV3) {
      if (this.disableScoreFraudCompanyButton) return;
      const companyProfile = {
        registrationNumber: this.checkCompanyRegistrationNumberData,
        name: this.checkCompanyLegalUnitData.corporateName,
        address: {
          street: this.headquarter?.address.street,
          city: this.headquarter?.address.city,
          zipCode: this.headquarter?.address.zipcode,
          country: this.checkCompanyCountryData
        }
      };
      if (this.companyParameters.displayScoreFraudCompanyAndSMB) this.disableScoreFraudCompanyButton = true;
      this.isScoreB2BLoading = true;
      getScoringB2B(customerProfileV3, companyProfile, this.journeyId).then(
        async (res) => {
          this.contextData.web_score_b2b = [res.result.score];
          this.contextData.causes_web_score_b2b = res?.causes != null ? [res.causes] : [];
          if (this.scoreB2BDataV && this.colorB2C !== '#D9D9D9') {
            this.colorB2B = await this.defineColor('score_b2b', this.scoreB2BDataV, this.journeyId);
          }

          //Check list
          const checklist = await setRule('CHECKLIST_B2B', this.journeyId);
          this.checkList = checklist.result;
          this.hasCheckList = true;

          this.isScoreB2BLoading = false;
        }
      ).catch(response => {
        this.phoneNotValid(response.response.data.detail);
        this.isScoreB2BLoading = false;
      });
    },
    async computeScoreSMB(customerProfileV3: CustomerProfileV3) {
      if (this.disableSmbScoreButton) return;
      if (this.companyParameters.displayScoreFraudCompanyAndSMB) this.disableSmbScoreButton = true;
      this.disableFraudScoreButton = true;
      this.isScoreSMBLoading = true;
      getScoringSMB(customerProfileV3, this.journeyId).then(async (response: any) => {
        if (response.status) this.disableFraudScoreButton = false;
        this.contextData.smb_score = response?.result?.score != null ? [response?.result.score] : [];
        this.colorSMB = await this.defineColor(
          'score_smb',
          this.scoreSMBData,
          this.journeyId
        );
        this.contextData.smb_causes = response?.causes != null ? [response.causes] : [];

        //Check list
        const checklist = await setRule('CHECKLIST_B2B', this.journeyId);
        this.checkList = checklist.result;
        this.hasCheckList = true;

        this.isScoreSMBLoading = false;
      }).catch(response => {
        this.phoneNotValid(response.response.data.detail);
        this.isScoreSMBLoading = false;
      });

      searchImage(customerProfileV3, this.journeyId).then((response: any) => {
        this.contextData.search_image = response !== null ? [response.result.profiles] : [];
      });

    },
    getEReputation() {
      this.isEReputationLoading = true;

      const headquarterInfo = this.checkCompanyEstablishmentsData.find((company: any) => company.headquarter);


      if (headquarterInfo) {
        const { businessName, address } = headquarterInfo;

        const companyProfile = {
          name: !isNil(businessName) ? businessName : this.checkCompanyLegalUnitData.corporateName,
          address: {
            street: address.address,
            city: address.city,
            zipCode: address.zipcode,
            country: this.checkCompanyCountryData
          }
        };

        getEReputation(companyProfile, this.journeyId, 450, 200).then(async (res) => {
          this.contextData.e_reputation_causes = res?.causes != null ? [res?.causes] : [];
          this.contextData.e_reputation_score = res?.result !== null ? [res.result.score] : [];
          this.colorEReputation = await this.defineColor('score_e-reputation', this.scoreEReputationData, this.journeyId);
          this.isEReputationLoading = false;

          //Check list
          const checklist = await setRule('CHECKLIST_B2B', this.journeyId);
          this.checkList = checklist.result;
          this.hasCheckList = true;
        });
      }
    },
    async checkIbanMethod(iban: string, customerProfileV3: CustomerProfileV3) {
      this.ibanResponse = iban;
      this.showCheckIbanLoader = true;
      const {
        data: { data: applicantInfo }
      } = await sendApplicantInformation(customerProfileV3, this.journeyId, iban);
      this.contextData.applicant = applicantInfo != null ? [applicantInfo] : [];
      this.disableIbanButton = true;
      let timer = setTimeout(() => {
        this.showCheckIbanLoader = false;
        this.messageCheckIban = true;
      }, 120000);


      const ibanCompanyRequest = {
        iban: iban.replaceAll(' ', ''),
        provider: this.checkCompanyCountryData === 'FR' ? 'OPTION_1' : 'OPTION_2',
        country: this.checkCompanyCountryData,
        registrationNumber: this.checkCompanyLegalUnitData ? this.checkCompanyLegalUnitData?.registrationNumber : ''
      };

      const responseIban = await checkIbanCompany(this.journeyId, ibanCompanyRequest);

      if (responseIban) clearTimeout(timer);
      this.contextData.check_iban_details = responseIban.data?.result ? [{
        checks: responseIban.data?.result?.checks,
        analysisStatus: responseIban.data?.result?.analysisStatus
      }] : [];

      //Check list
      const checklist = await setRule('CHECKLIST_B2B', this.journeyId);
      this.checkList = checklist.result;
      this.hasCheckList = true;
      this.showCheckIbanLoader = false;
    },
    async checkAmlcft(customerProfileV3: CustomerProfileV3) {
      this.disableAmlcftButton = true;
      const {
        data: { data: applicantInfo }
      } = await sendApplicantInformation(customerProfileV3, this.journeyId);
      this.contextData.applicant = applicantInfo != null ? [applicantInfo] : [];
      const response = await searchAmlcft(
        customerProfileV3.identity.lastName,
        customerProfileV3.identity.firstName,
        customerProfileV3.identity.birthDate,
        this.journeyId
      );
      this.contextData.amlcft_matches = response ? [response.data.result] : [];
    },
    ...mapActions(['clearResponseStatus']),
    getBoxOutputCompanyFinanceData(taxReportData: any) {
      if (Array.isArray(taxReportData) && !_.isEmpty(taxReportData)) {
        if (_.isEmpty(this.checkCompanyRegistrationNumberData)) return taxReportData;
        return taxReportData.filter((elt: any) => this.checkCompanyRegistrationNumberData.includes(elt.context.siren));
      }
      return taxReportData;
    },
    reloadTaxReportResult(taxReportsData: any[]) {
      this.contextData.tax_reports_result = [...this.contextData.tax_reports_result, ...taxReportsData];
    },
    isDataAvalaible,
    async accountantVerification(data: {
      REGISTRATION_NUMBER?: string;
      PHONE?: string;
      EMAIL?: string;
      COUNTRY?: string;
    }) {
      this.contextData.accountant_verification_data = [data];
      this.isLoadingAccountantWarningList = true;
      sendAccountantVerificationData(this.journeyId, data).then(async () => {
          const response = await searchCombinedWarningList(this.journeyId, 'MEELO_BLACKLIST', data, 'CHECK_CHARTERED_ACCOUNTANT');

          if (!isNull(response) && response?.data?.length > 0) {
            this.contextData.combined_warning_list = response.data;
          }

          this.isLoadingAccountantWarningList = false;
        }
      );
    }
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.container-b2b {
  &__button-menu {
    text-align: center;
  }

  &__analysis {
    margin: 0 2rem 2rem 2rem;

    &__siren,
    &__iban {
      margin: 1rem;

      &-form {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        &-input {
          width: 30rem;
        }
      }
    }

    &__information-company {
      margin: 1rem;

      &-button {
        text-align: center;

        button {
          margin: 0.1rem;
        }
      }

      &-label {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &__form-scoring,
    &__check-iban {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    &__representatives {
      &__result {
        margin: 1rem;

        &-firstname {
          padding: 0.1rem;
        }
      }
    }

    &__ereputation {
      margin: 1rem;
      color: $blueMeelo;

      &-cloud {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        &-word,
        &-adjectives {
          text-align: center;
        }
      }

      &-form {
        display: grid;
        grid-template-columns: 30rem 10rem;
        justify-content: center;
      }

      &-error-message {
        grid-area: 1 / 1 / 2 / 6;
        margin: auto;
      }
    }

    &__financial {
      margin: 1rem;

      &-message-error {
        text-align: center;
      }
    }

    &__scoring-b2c {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    &__upload {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    &__scoring-altares {
      &-button {
        text-align: center;
      }
    }

    &__scoring-credit-safe {
      &-button {
        text-align: center;
      }
    }

    &-selections {
      display: grid;
      gap: 30px;
      grid-template-columns: repeat(2, 1fr);
      margin: 1rem;
    }

    &-result {
      margin: 2rem 0 2rem 0;
      text-align: center;

      &-title {
        margin: 1rem;
        font-size: 30px;
        text-transform: uppercase;
        color: $blueMeelo;
        font-family: $fontFamilyBold;
      }
    }
  }
}

.grey-list-no-data,
.grey-list {
  margin: 1rem;
}

.grey-list-matches {
  margin-bottom: 0;
}

.openbanking-bloc-link {
  text-align: center;
}
</style>
