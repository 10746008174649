import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "synthesis" }
const _hoisted_2 = { class: "pagebreak" }
const _hoisted_3 = { class: "synthesis-applicant-left-block" }
const _hoisted_4 = {
  key: 0,
  class: "synthesis-applicant-left-block-applicant"
}
const _hoisted_5 = { class: "synthesis-applicant-b2c-left-block-applicant" }
const _hoisted_6 = { class: "synthesis-applicant-b2c-right-block" }
const _hoisted_7 = { class: "synthesis-applicant-b2c-right-block-score" }
const _hoisted_8 = { class: "synthesis-applicant-b2c-right-block-score__title" }
const _hoisted_9 = {
  key: 5,
  class: "company-document"
}
const _hoisted_10 = {
  key: 16,
  class: "company-document"
}
const _hoisted_11 = {
  key: 17,
  class: "company-document"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrintCoverPage = _resolveComponent("PrintCoverPage")!
  const _component_BoxOutputCheckList = _resolveComponent("BoxOutputCheckList")!
  const _component_LabelValuePrinter = _resolveComponent("LabelValuePrinter")!
  const _component_ContentCard = _resolveComponent("ContentCard")!
  const _component_ScoreCircleChart = _resolveComponent("ScoreCircleChart")!
  const _component_BoxOutputCompanyInfo = _resolveComponent("BoxOutputCompanyInfo")!
  const _component_BoxOutputWarningListRegistrationNumber = _resolveComponent("BoxOutputWarningListRegistrationNumber")!
  const _component_OutputWarningList = _resolveComponent("OutputWarningList")!
  const _component_BoxOutputOwnerRepresentative = _resolveComponent("BoxOutputOwnerRepresentative")!
  const _component_ScoringDetailedBox = _resolveComponent("ScoringDetailedBox")!
  const _component_BoxOutputScoreSMB = _resolveComponent("BoxOutputScoreSMB")!
  const _component_BoxOutputScoreCompany = _resolveComponent("BoxOutputScoreCompany")!
  const _component_BoxOutputCompanyLitigation = _resolveComponent("BoxOutputCompanyLitigation")!
  const _component_BoxOutputCompanyCartography = _resolveComponent("BoxOutputCompanyCartography")!
  const _component_CheckIbanBox = _resolveComponent("CheckIbanBox")!
  const _component_BoxOutputAmlcft = _resolveComponent("BoxOutputAmlcft")!
  const _component_BoxOutputDoc = _resolveComponent("BoxOutputDoc")!
  const _component_BoxOutputCompanyFinances = _resolveComponent("BoxOutputCompanyFinances")!
  const _component_BoxOutputCompanyLegalDoc = _resolveComponent("BoxOutputCompanyLegalDoc")!
  const _component_BoxOutputAnnualAccounts = _resolveComponent("BoxOutputAnnualAccounts")!
  const _component_BoxOutputEstablishments = _resolveComponent("BoxOutputEstablishments")!
  const _component_BoxOutputBodaccAnnouncements = _resolveComponent("BoxOutputBodaccAnnouncements")!
  const _component_BoxOutputScoreCreditSafe = _resolveComponent("BoxOutputScoreCreditSafe")!
  const _component_BoxOutputAccountantVerification = _resolveComponent("BoxOutputAccountantVerification")!
  const _component_BoxOutputEReputation = _resolveComponent("BoxOutputEReputation")!
  const _component_BoxOutputOpenBanking = _resolveComponent("BoxOutputOpenBanking")!
  const _component_BoxOutputOpenBankingDetail = _resolveComponent("BoxOutputOpenBankingDetail")!
  const _component_BoxOutputOpenBankingAlert = _resolveComponent("BoxOutputOpenBankingAlert")!
  const _component_BoxOutputCheckEmployer = _resolveComponent("BoxOutputCheckEmployer")!
  const _component_BoxOutputClientContext = _resolveComponent("BoxOutputClientContext")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PrintCoverPage, {
      "doc-creator": _ctx.dataFolder.analyst,
      "doc-creation-date": _ctx.dataFolder.createdAt,
      contextData: _ctx.contextData
    }, null, 8, ["doc-creator", "doc-creation-date", "contextData"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.hasCheckList && (_ctx.isScoreFraud || _ctx.canShowBoxOutputCompanyInfo))
        ? (_openBlock(), _createBlock(_component_BoxOutputCheckList, {
            key: 0,
            data: _ctx.checkList
          }, null, 8, ["data"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(`${_ctx.isScoreSmb || _ctx.isScoreCompany ? 'synthesis-informations' : 'synthesis-informations-b2c'}`)
      }, [
        (_ctx.folder.journeyId.value)
          ? (_openBlock(), _createBlock(_component_ContentCard, {
              key: 0,
              class: "synthesis-folder",
              title: _ctx.translate('TABLE.FOLDER')
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.folder, (data) => {
                  return (_openBlock(), _createBlock(_component_LabelValuePrinter, {
                    label: data.text,
                    value: data.value
                  }, null, 8, ["label", "value"]))
                }), 256))
              ]),
              _: 1
            }, 8, ["title"]))
          : _createCommentVNode("", true),
        (_ctx.isScoreSmb || _ctx.isScoreCompany)
          ? (_openBlock(), _createBlock(_component_ContentCard, {
              key: 1,
              class: "synthesis-applicant",
              title: _ctx.translate('APPLICANT')
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  (_ctx.applicantData || _ctx.contextData.firstname_synthesis)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getApplicantDataB2B(), (applicant) => {
                          return (_openBlock(), _createBlock(_component_LabelValuePrinter, {
                            label: applicant.label,
                            value: applicant.value,
                            copyToClipboard: applicant.copy_to_clipboard
                          }, null, 8, ["label", "value", "copyToClipboard"]))
                        }), 256))
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["title"]))
          : _createCommentVNode("", true)
      ], 2),
      (_ctx.isScoreFraud)
        ? (_openBlock(), _createBlock(_component_ContentCard, {
            key: 1,
            class: "synthesis-applicant-b2c",
            title: _ctx.translate('APPLICANT')
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contextData.firstname_synthesis, (firstname, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "synthesis-applicant-b2c-left-block"
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getApplicantDataB2C(firstname, index), (applicant) => {
                      return (_openBlock(), _createBlock(_component_LabelValuePrinter, {
                        label: applicant.label,
                        value: applicant.value,
                        copyToClipboard: applicant.copy_to_clipboard
                      }, null, 8, ["label", "value", "copyToClipboard"]))
                    }), 256))
                  ])
                ]))
              }), 128)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scoreFraudData, (score) => {
                return (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.translate('CONFIDENCE_INDEX')), 1),
                    _createVNode(_component_ScoreCircleChart, {
                      class: "circle-chart score-fraud",
                      "circle-value": Math.round(score),
                      "circle-width": '32%',
                      "circle-color": _ctx.colorB2C,
                      "helper-text-display": true,
                      "circle-title": "",
                      tooltip: "",
                      "type-score": ""
                    }, null, 8, ["circle-value", "circle-color"])
                  ])
                ]))
              }), 256))
            ]),
            _: 1
          }, 8, ["title"]))
        : _createCommentVNode("", true),
      (_ctx.canShowBoxOutputCompanyInfo)
        ? (_openBlock(), _createBlock(_component_BoxOutputCompanyInfo, {
            key: 2,
            "show-information-company-content": _ctx.checkCompanyLegalUnitData,
            "check-company": _ctx.companyInfoTransformer(_ctx.checkCompanyCountryData, { ..._ctx.checkCompanyLegalUnitData, companyCountry: _ctx.checkCompanyCountryData }),
            "company-causes": _ctx.causesB2BDataV?.company,
            permissions: _ctx.permissionsSorted,
            "can-company-info": _ctx.companyInfoBlock,
            class: "pagebreak"
          }, null, 8, ["show-information-company-content", "check-company", "company-causes", "permissions", "can-company-info"]))
        : _createCommentVNode("", true),
      (_ctx.isCompanyWarningList)
        ? (_openBlock(), _createBlock(_component_BoxOutputWarningListRegistrationNumber, {
            key: 3,
            "is-loading": false,
            "siren-warning-list": _ctx.isCompanyWarningList,
            class: "warning-list warning-list-siren"
          }, null, 8, ["siren-warning-list"]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
            (_ctx.canShowOutputWarningList)
              ? (_openBlock(), _createBlock(_component_OutputWarningList, {
                  key: 0,
                  "warning-list": _ctx.getWarningListData()
                }, null, 8, ["warning-list"]))
              : _createCommentVNode("", true)
          ], 64)),
      (!_ctx.isScoreFraud)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            (_ctx.canShowBoxOutputForBusinessOwner)
              ? (_openBlock(), _createBlock(_component_BoxOutputOwnerRepresentative, {
                  key: 0,
                  "owner-representatives": _ctx.businnessOwnerTransformer(_ctx.checkCompanyCountryData, _ctx.checkCompanyShareHoldersData),
                  "permission-to-select": _ctx.permissionsSorted.includes('KIT_B2B_BUSINESS_OWNERS_SELECTION'),
                  "can-person": _ctx.businessOwnersBlock,
                  title: _ctx.translate('COMPANY.BUSINESS_OWNERS_KNOWN'),
                  type: 'businessOwner'
                }, null, 8, ["owner-representatives", "permission-to-select", "can-person", "title"]))
              : _createCommentVNode("", true),
            (_ctx.canShowBoxForRepresentative)
              ? (_openBlock(), _createBlock(_component_BoxOutputOwnerRepresentative, {
                  key: 1,
                  "owner-representatives": _ctx.representativeTransformer(_ctx.checkCompanyCountryData, _ctx.checkCompanyRepresentativesData),
                  "permission-to-select": _ctx.permissionsSorted.includes('KIT_B2B_REPRESENTATIVES_SELECTION'),
                  "can-person": _ctx.representativesBlock,
                  title: _ctx.translate('COMPANY.REPRESENTATIVES_KNOWN'),
                  type: 'representative'
                }, null, 8, ["owner-representatives", "permission-to-select", "can-person", "title"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (!_ctx.isNil(_ctx.causesFraudData))
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 6 }, _renderList(_ctx.contextData?.causes_web_score_b2c, (causes, index) => {
            return (_openBlock(), _createBlock(_component_ScoringDetailedBox, {
              key: index,
              "customer-profile": _ctx.prefillClientForm,
              "causes-fraud": causes,
              email: _ctx.contextData.email_synthesis[index],
              phone: _ctx.contextData.phone_synthesis[index],
              "can-scoring": _ctx.scoringDetailedBoxBlock,
              permissions: _ctx.permissionsSorted,
              "search-image": _ctx.searchImageProfilesData,
              company: _ctx.user?.company?.name,
              "dqe-loader": false,
              "dqe-control-result": _ctx.dqeControlData
            }, null, 8, ["customer-profile", "causes-fraud", "email", "phone", "can-scoring", "permissions", "search-image", "company", "dqe-control-result"]))
          }), 128))
        : _createCommentVNode("", true),
      (_ctx.isScoreSmb)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 7 }, _renderList(_ctx.scoreSMBData, (score, index) => {
            return (_openBlock(), _createBlock(_component_BoxOutputScoreSMB, {
              key: index,
              scoreSMB: score,
              causesSMB: _ctx.contextData?.smb_causes[index],
              applicant: _ctx.applicantData,
              color: _ctx.colorSMB,
              permissions: _ctx.permissionsSorted,
              "can-score-s-m-b": _ctx.scoreSMBBlock,
              email: _ctx.contextData.email_synthesis[index],
              phone: _ctx.contextData.phone_synthesis[index],
              "search-image": _ctx.searchImageProfilesData,
              class: "pagebreak",
              "display-multi-requests": !_ctx.causesB2BDataV,
              company: _ctx.user?.company?.name
            }, null, 8, ["scoreSMB", "causesSMB", "applicant", "color", "permissions", "can-score-s-m-b", "email", "phone", "search-image", "display-multi-requests", "company"]))
          }), 128))
        : _createCommentVNode("", true),
      (_ctx.isScoreCompany)
        ? (_openBlock(), _createBlock(_component_BoxOutputScoreCompany, {
            key: 8,
            scoringB2B: _ctx.scoreB2BDataV,
            "check-company": _ctx.checkCompanyDataCompany,
            causesB2B: _ctx.causesB2BDataV,
            color: _ctx.colorB2B,
            permissions: _ctx.permissionsSorted,
            "permissions-company-alert": _ctx.permissionsCompanyAlert,
            "can-company-score": _ctx.companyScoreBlock,
            class: "score-company pagebreak",
            "display-multi-requests": _ctx.causesB2BDataV,
            company: _ctx.user?.company?.name
          }, null, 8, ["scoringB2B", "check-company", "causesB2B", "color", "permissions", "permissions-company-alert", "can-company-score", "display-multi-requests", "company"]))
        : _createCommentVNode("", true),
      (_ctx.causesB2BDataV && _ctx.isCountryFR)
        ? (_openBlock(), _createBlock(_component_BoxOutputCompanyLitigation, {
            key: 9,
            class: "litigation-document",
            data: _ctx.causesB2BDataV,
            "can-company-litigation": _ctx.companyLitigationBlock
          }, null, 8, ["data", "can-company-litigation"]))
        : _createCommentVNode("", true),
      (_ctx.causesB2BDataV)
        ? (_openBlock(), _createBlock(_component_BoxOutputCompanyCartography, {
            key: 10,
            dataChart: _ctx.causesB2BDataV.graph,
            "can-cartography": _ctx.cartographyBlock
          }, null, 8, ["dataChart", "can-cartography"]))
        : _createCommentVNode("", true),
      (_ctx.checkIbanData)
        ? (_openBlock(), _createBlock(_component_CheckIbanBox, {
            key: 11,
            "check-iban": _ctx.checkIbanData,
            birthdate: _ctx.contextData?.applicant?.birthDate,
            "is-france": _ctx.isCountryFR || _ctx.isCountryFR && _ctx.checkIbanData?.subType === 'COMPANY_IBAN' || _ctx.checkIbanData?.subType === 'PERSONAL_IBAN',
            "can-check-iban": _ctx.permissionsSorted.includes('KIT_B2C_CHECK_IBAN_B2C_GLOBAL') || _ctx.permissionsSorted.includes('KIT_B2B_CHECK_IBAN_B2B_GLOBAL'),
            class: "pagebreak"
          }, null, 8, ["check-iban", "birthdate", "is-france", "can-check-iban"]))
        : _createCommentVNode("", true),
      (_ctx.amlCftMatchesData)
        ? (_openBlock(), _createBlock(_component_BoxOutputAmlcft, {
            key: 12,
            amlcft: _ctx.amlCftMatchesData[0],
            "show-content": _ctx.amlCftMatchesData,
            "can-amlcft": _ctx.canAmlcft
          }, null, 8, ["amlcft", "show-content", "can-amlcft"]))
        : _createCommentVNode("", true),
      (_ctx.hasDocument)
        ? (_openBlock(), _createBlock(_component_BoxOutputDoc, {
            key: 13,
            "can-show": true,
            desc: _ctx.translate('DOCUMENT_CONTROL.ID_ANALYSIS_DESCRIPTION'),
            "is-loading": false,
            "context-data": _ctx.contextData,
            "can-upload-document": false,
            "can-restitute-document": true,
            title: "DOCUMENT_CONTROL.ID_ANALYSIS_TITLE",
            "doc-category": "ID"
          }, null, 8, ["desc", "context-data"]))
        : _createCommentVNode("", true),
      (_ctx.hasDocument)
        ? (_openBlock(), _createBlock(_component_BoxOutputDoc, {
            key: 14,
            "can-show": true,
            desc: _ctx.translate('DOCUMENT_CONTROL.DOCUMENT_ANALYSIS_DESCRIPTION'),
            "is-loading": false,
            "context-data": _ctx.contextData,
            "can-upload-document": false,
            "can-restitute-document": true,
            "doc-category": "OTHER",
            title: "DOCUMENT_CONTROL.DOCUMENT_ANALYSIS_TITLE"
          }, null, 8, ["desc", "context-data"]))
        : _createCommentVNode("", true),
      (_ctx.canShowBoxOutputCompanyFinances)
        ? (_openBlock(), _createBlock(_component_BoxOutputCompanyFinances, {
            key: 15,
            "tax-reports": _ctx.taxReportsSummaryData,
            loader: _ctx.taxReportLoading,
            "can-company-finances": _ctx.companyFinancesBlock,
            class: "pagebreak"
          }, null, 8, ["tax-reports", "loader", "can-company-finances"]))
        : _createCommentVNode("", true),
      (!_ctx.isScoreFraud)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            (_ctx.canShowBoxOutputCompanyLegalDoc)
              ? (_openBlock(), _createBlock(_component_BoxOutputCompanyLegalDoc, {
                  key: 0,
                  "legal-doc": _ctx.checkCompanyAttachmentsData?.legalActs,
                  "registration-number": _ctx.checkCompanyLegalUnitData.registrationNumber,
                  "journey-id": _ctx.journeyId,
                  "can-company-legal-doc": _ctx.companyLegalDocBlock,
                  class: "pagebreak"
                }, null, 8, ["legal-doc", "registration-number", "journey-id", "can-company-legal-doc"]))
              : _createCommentVNode("", true),
            (_ctx.canShowBoxOutputAnnualAccounts)
              ? (_openBlock(), _createBlock(_component_BoxOutputAnnualAccounts, {
                  key: 1,
                  "tax-reports": _ctx.checkCompanyAttachmentsData?.taxReports,
                  "journey-id": _ctx.journeyId,
                  "can-annual-accounts": _ctx.annualAccountsBlock,
                  class: "pagebreak"
                }, null, 8, ["tax-reports", "journey-id", "can-annual-accounts"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (!_ctx.isScoreFraud)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            (_ctx.canShowBoxOutputEstablishments)
              ? (_openBlock(), _createBlock(_component_BoxOutputEstablishments, {
                  key: 0,
                  establishments: _ctx.establishmentTransformer(_ctx.checkCompanyCountryData, _ctx.checkCompanyEstablishmentsData),
                  "can-establishments": _ctx.establishmentsBlock,
                  class: "pagebreak"
                }, null, 8, ["establishments", "can-establishments"]))
              : _createCommentVNode("", true),
            (_ctx.canShowBoxOutputBodaccAnnouncements)
              ? (_openBlock(), _createBlock(_component_BoxOutputBodaccAnnouncements, {
                  key: 1,
                  announcements: _ctx.bodaccAnnouncementTransformer(_ctx.checkCompanyCountryData, _ctx.checkCompanyLegalObservationData),
                  "can-bodacc-announcements": _ctx.bodaccAnnouncementsBlock,
                  class: "pagebreak"
                }, null, 8, ["announcements", "can-bodacc-announcements"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.causesCreditSafeData && _ctx.scoreCreditSafeData)
        ? (_openBlock(), _createBlock(_component_BoxOutputScoreCreditSafe, {
            key: 18,
            "score-credit-safe": _ctx.scoreCreditSafeData,
            "causes-credit-safe": _ctx.causesCreditSafeData,
            "is-company-f-r": _ctx.isCountryFR,
            "color-credit-safe-loc": _ctx.colorCreditSafeLoc,
            "color-credit-safe-inter": _ctx.colorCreditSafeInter,
            "can-credit-safe": _ctx.permissionsSorted.includes('KIT_B2B_CREDIT_SAFE_GLOBAL'),
            class: "pagebreak"
          }, null, 8, ["score-credit-safe", "causes-credit-safe", "is-company-f-r", "color-credit-safe-loc", "color-credit-safe-inter", "can-credit-safe"]))
        : _createCommentVNode("", true),
      (_ctx.canShowBoxOutputAccountantVerification)
        ? (_openBlock(), _createBlock(_component_BoxOutputAccountantVerification, {
            key: 19,
            "warning-list": _ctx.warningListCombinedData,
            "accountant-verification-data": _ctx.accountantVerificationData,
            "is-loading": false
          }, null, 8, ["warning-list", "accountant-verification-data"]))
        : _createCommentVNode("", true),
      (_ctx.canShowBoxOutputEReputation)
        ? (_openBlock(), _createBlock(_component_BoxOutputEReputation, {
            key: 20,
            "scoring-e-reputation": _ctx.scoreEReputationData,
            color: _ctx.colorEReputation,
            "e-reputation-data": _ctx.causesEReputationData,
            permissions: _ctx.permissionsSorted,
            "can-e-reputation": _ctx.eReputationBlock
          }, null, 8, ["scoring-e-reputation", "color", "e-reputation-data", "permissions", "can-e-reputation"]))
        : _createCommentVNode("", true),
      ((Object.keys(_ctx.obFormattedFields).length > 0))
        ? (_openBlock(), _createBlock(_component_BoxOutputOpenBanking, {
            key: 21,
            "ob-data": _ctx.obFormattedFields,
            "color-score": _ctx.colorScoreOB,
            "can-open-banking": _ctx.openBankingResumeBlock
          }, null, 8, ["ob-data", "color-score", "can-open-banking"]))
        : _createCommentVNode("", true),
      ((Object.keys(_ctx.obFormattedFields).length > 0) && _ctx.checkDataAvailability)
        ? (_openBlock(), _createBlock(_component_BoxOutputOpenBankingDetail, {
            key: 22,
            class: "pagebreak",
            "ob-data": _ctx.obFormattedFields,
            permissions: _ctx.permissionsSorted,
            "can-open-banking": _ctx.openBankingDetailBlock
          }, null, 8, ["ob-data", "permissions", "can-open-banking"]))
        : _createCommentVNode("", true),
      ((Object.keys(_ctx.obFormattedFields).length > 0) && _ctx.checkDataAvailability)
        ? (_openBlock(), _createBlock(_component_BoxOutputOpenBankingAlert, {
            key: 23,
            "ob-data": _ctx.obFormattedFields,
            "can-open-banking": _ctx.openBankingAlertBlock,
            class: "pagebreak"
          }, null, 8, ["ob-data", "can-open-banking"]))
        : _createCommentVNode("", true),
      (_ctx.canShowBoxOutputCheckEmployer)
        ? (_openBlock(), _createBlock(_component_BoxOutputCheckEmployer, {
            key: 24,
            "check-employer-data": _ctx.checkCompanyLegalUnitData,
            "check-warning-list": _ctx.warningListData,
            "check-procedure": _ctx.checkEmployerCollectiveProceedingsData
          }, null, 8, ["check-employer-data", "check-warning-list", "check-procedure"]))
        : _createCommentVNode("", true),
      (_ctx.folder.journeyId.value)
        ? (_openBlock(), _createBlock(_component_BoxOutputClientContext, {
            key: 25,
            "journey-id": _ctx.journeyId,
            "toggle-expanded": true
          }, null, 8, ["journey-id"]))
        : _createCommentVNode("", true)
    ])
  ]))
}