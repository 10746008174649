export default {
  "ACCOUNT_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il mio account"])},
    "GENERAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni generali"])},
    "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda"])},
    "USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome utente"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
    "PHONE_NUMBER_NOT_SPECIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non specificato"])},
    "TWO_FACTOR_AUTHENTICATION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurazione dell'autenticazione a due fattori"])},
      "REQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesto dall'azienda"])},
      "CHECKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificato"])},
      "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica le mie informazioni"])},
      "MODE": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modalità di autenticazione"])},
        "SMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
        "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabilitato"])}
      }
    },
    "INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mie informazioni"])}
  },
  "LOGIN_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua email"])},
    "PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua password"])},
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le credenziali non corrispondono a nessun account."])},
    "ATTEMPTS_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tentativi consecutivi errati bloccheranno il tuo account."])},
    "LOCKED_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo account è stato bloccato."])},
    "FORGOT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho dimenticato la mia password"])},
    "TWO_FACTOR_AUTHENTICATION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticazione a due fattori"])},
      "ALERT_MESSAGE_CHECKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa operazione sovrascriverà il tuo vecchio metodo di autenticazione a due fattori. Sei sicuro di voler continuare?"])},
      "ALERT_MESSAGE_ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa operazione attiverà l'autenticazione a due fattori."])},
      "ALERT_MESSAGE_DISABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciò disabiliterà l'autenticazione a due fattori."])},
      "DISABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabilita l'autenticazione a due fattori"])},
      "DISABLE_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi disabilitare l'autenticazione a due fattori. Tuttavia, ti consigliamo di lasciare questa funzionalità attiva per aumentare la sicurezza del tuo account."])},
      "COMPANY_2FA_REQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicaci quale metodo di autenticazione desideri utilizzare."])},
      "EMAIL_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti verrà inviato un codice di verifica all'indirizzo email"])},
      "SMS_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti verrà inviato un codice di verifica tramite SMS. Inserisci il tuo numero di telefono:"])},
      "TITLE_MODAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modalità di autenticazione a due fattori"])},
      "MESSAGE_SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai ricevuto un codice"])},
      "FINALISATION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per completare l'autenticazione a due fattori, inserisci il codice che ti è stato inviato"])}
    }
  },
  "PRIVATE_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File privato"])},
  "BUSINESS_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File aziendale"])},
  "OPEN_BANKING_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Open Banking"])},
  "DOCUMENT_SYNTHESIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sintesi del documento"])},
  "FILE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del file"])},
  "SYNTESIS_DOWNLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Sintesi"])},
  "DOWNLOADING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download in corso..."])},
  "RESET_PASSWORD_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta password"])},
    "INIT_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizializza password"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua email"])},
    "FORGOT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password dimenticata"])},
    "NEW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password"])},
    "CONFIRM_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma nuova password"])},
    "RESET_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta password"])},
    "SEND_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia email di reimpostazione"])},
    "SUCCESS_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È stata inviata un'email di reimpostazione della password."])},
    "EMAIL_NOT_SENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile inviare l'email."])},
    "INVALID_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password deve essere lunga almeno 8 caratteri e contenere una lettera maiuscola, una minuscola, un numero e un carattere speciale."])},
    "PASSWORD_NOT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le password non corrispondono."])},
    "PASSWORD_RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua password è stata reimpostata correttamente."])},
    "PASSWORD_RESET_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante il ripristino della password."])}
  },
  "CGS": {
    "BEGINNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuando, accetti la"])},
    "LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["politica sulla privacy"])},
    "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di Meelo"])}
  },
  "IDENTITY": {
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
    "FIRSTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di nascita"])},
    "PLACE_OF_BIRTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luogo di nascita"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo email"])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono cellulare"])},
    "LASTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
    "USAGE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome d'uso"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
  },
  "ADDRESS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
    "TITLE_POSTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo postale"])},
    "NUMBER_AND_STREET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero e nome della via"])},
    "ZIP_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice postale"])},
    "CITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città"])},
    "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese"])}
  },
  "COMPANY": {
    "NO_REPRESENTATIVES_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun leader e rappresentante conosciuto"])},
    "NO_BUSINESS_OWNERS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun azionista e proprietario conosciuto"])},
    "LIQUIDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidazione"])},
    "RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radiare"])},
    "SIREN_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di identificazione"])},
    "ORIGIN": {
      "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francia"])},
      "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgio"])},
      "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spagna"])},
      "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austria"])},
      "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germania"])},
      "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danimarca"])},
      "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonia"])},
      "EN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inghilterra"])},
      "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finlandia"])},
      "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regno Unito"])},
      "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irlanda"])},
      "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italia"])},
      "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lituania"])},
      "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lettonia"])},
      "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paesi Bassi"])},
      "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norvegia"])},
      "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polonia"])},
      "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portogallo"])},
      "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svezia"])}
    },
    "REGISTRATION_NUMBER": {
      "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di identificazione dell'azienda o numero SIREN"])},
      "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di registrazione nazionale o Numero di partita IVA"])},
      "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice di Identificazione Fiscale"])}
    },
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di identificazione"])},
    "SIRET_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero SIRET"])},
    "ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
    "MAIN_ACTIVITY_DECLARED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività principale dichiarata"])},
    "ACTIVITY_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice NAF o APE"])},
    "INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni dell'azienda"])},
    "CREATED_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di creazione"])},
    "CORPORATE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denominazione sociale"])},
    "LEGAL_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma giuridica"])},
    "CAPITAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitale sociale"])},
    "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identità dell'azienda"])},
    "BUSINESS_OWNERS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco dei beneficiari effettivi"])},
    "BUSINESS_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficiario effettivo conosciuto"])},
    "DIRECT_BUSINESS_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficiario diretto"])},
    "INDIRECT_BUSINESS_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficiario indiretto tramite persona giuridica"])},
    "IN_POSITION_SINCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In carica dal"])},
    "MOTHBALLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sospensione"])},
    "CURRENT_PROCEDURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedure in corso"])},
    "COLLECTIVE_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedura collettiva"])},
    "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reputazione online"])},
    "GLOBAL_NOTE_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio globale"])},
    "ADVICE_NOTE_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di recensioni"])},
    "WORDING_CLOUD_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricorrenza delle parole nelle recensioni"])},
    "ADJECTIVES_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recensioni positive / Recensioni negative"])},
    "WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sito web"])},
    "NUMBER_ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di sedi"])},
    "ESTABLISHMENTS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco delle sedi"])},
    "REPRESENTATIVES_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco dei rappresentanti"])},
    "REPRESENTATIVES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rappresentanti"])},
    "REPRESENTATIVES_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amministratori e rappresentanti conosciuti"])},
    "BUSINESS_OWNERS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azionisti e beneficiari conosciuti"])},
    "RECOMMENDED_OUTSTANDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debito raccomandato"])},
    "HEADQUARTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sede principale"])},
    "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizione"])},
    "TOTAL_SHARE_HOLDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partecipazione totale"])},
    "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese d'origine dell'azienda"])},
    "TYPE_PERSON": {
      "NATURAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona fisica"])},
      "LEGAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona giuridica"])}
    },
    "FUNCTIONS": {
      "PRESIDENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presidente"])},
      "CHAIRMAN_OF_THE_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presidente del consiglio di amministrazione"])},
      "CHAIRMAN_AND_CHIEF_EXECUTIVE_OFFICER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presidente direttore generale"])},
      "MANAGING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amministratore"])},
      "NON_ASSOCIATE_MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amministratore non associato"])},
      "CO_MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co-amministratore"])},
      "MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direttore generale"])},
      "GENERAL_MANAGER_NOT_A_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direttore generale non amministratore"])},
      "SOLE_MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direttore generale unico"])},
      "CHIEF_OPERATING_OFFICER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direttore generale delegato"])},
      "VICE_CHAIRMAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vice presidente"])},
      "DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amministratore"])},
      "DEPUTY_ADMINISTRATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amministratore delegato"])},
      "CHAIRMAN_OF_THE_EXECUTIVE_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presidente del comitato esecutivo"])},
      "CHAIRMAN_OF_THE_SUPERVISORY_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presidente del consiglio di sorveglianza"])},
      "MANAGING_PARTNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Socio amministratore"])},
      "CONTROLLER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller di gestione"])},
      "STATUTORY_AUDITOR_TITULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisore legale dei conti - titolare"])},
      "STATUTORY_AUDITOR_DEPUTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisore legale dei conti - supplente"])},
      "STATUTORY_AUDITOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisore legale dei conti"])},
      "LIQUIDATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidatore"])},
      "PROVISIONAL_ADMINISTRATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amministratore provvisorio"])},
      "REPRESENTATIVE_IN_FRANCE_OF_A_FOREIGN_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rappresentante in Francia di una società estera"])},
      "MEMBER_OF_THE_BOARD_OF_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membro del consiglio di amministrazione"])},
      "CHAIRMAN_OF_THE_BOARD_OF_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presidente del consiglio di amministrazione"])}
    },
    "TYPE": {
      "SOCIETY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Società"])},
      "COLLECTIVE_INVESTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investimento collettivo"])},
      "GIE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo di interesse economico"])}
    },
    "OF_SHARES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di azioni"])},
    "OF_VOTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di voti"])},
    "LEGAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni legali"])},
    "WORKFORCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personale"])},
    "VAT_REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di partita IVA"])},
    "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di chiusura"])},
    "ACTIVITY_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato dell'attività"])},
    "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
    "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inattivo"])},
    "UNVERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non domiciliato"])},
    "RECOMMENDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raccomandazione"])},
    "FIELD_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settore di attività"])},
    "ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sedi"])},
    "OFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sede"])},
    "IN_OPERATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In funzione"])},
    "CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiusa"])},
    "FINANCES": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanze aziendali"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun dato finanziario disponibile"])},
      "RATIOS": {
        "grossMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margine lordo (€)"])},
        "netMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margine netto (€)"])},
        "valueAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore aggiunto (€)"])},
        "ebitda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margine operativo lordo (€)"])},
        "ebit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultato operativo (€)"])},
        "profiteBeforeTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultato corrente prima delle imposte (€)"])},
        "extraordinaryResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultato straordinario (€)"])},
        "netIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultato netto (€)"])},
        "shortTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debiti a breve termine (€)"])},
        "longMidTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debiti a lungo e medio termine (€)"])},
        "turnover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatturato (€)"])},
        "balanceSheetTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale del bilancio (€)"])},
        "capitalAndReserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitale proprio (€)"])},
        "workingCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fondo di manovra (€)"])},
        "workingCapitalRequirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requisito di capitale circolante (€)"])},
        "financialLiquidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidità finanziaria (€)"])},
        "intangibleAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività immateriali (€)"])},
        "tangibleFixedAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività materiali fisse (€)"])},
        "participatingInterestsAndRelatedDebtsReceivable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partecipazioni e crediti correlati (€)"])},
        "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazzino (€)"])},
        "othersDebtsReceivable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altri crediti (€)"])},
        "tradeDebtorsAndRelatedAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crediti commerciali e conti correlati (€)"])},
        "financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidità (€)"])},
        "chargesCa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese CA (€)"])},
        "appropriationsToDepreciationAndProvisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnazioni a svalutazioni e rettifiche (€)"])},
        "totalExtraordinaryIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrate straordinarie totali (€)"])},
        "totalExtraordinaryCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese straordinarie totali (€)"])},
        "corporateTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta sulle società (€)"])},
        "operatingLiabilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passività operative (€)"])},
        "capital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitale (€)"])},
        "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riserva (€)"])},
        "profitOrLossForTheFinancialYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultato dell'esercizio (€)"])},
        "investmentGrantsTaxRegulatedProvisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contributi all'investimento e rettifiche fiscali (€)"])},
        "loansAndDebtsPayableToCreditInstitutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestiti e debiti verso istituti di credito (€)"])},
        "loansAndSundryFinancialDebtsPayable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestiti e debiti finanziari diversi (€)"])},
        "changeInStocksIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altri proventi (€)"])},
        "purchasesOfGoodsForResale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisti di merci per la rivendita (€)"])},
        "changeInStocksCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variazione delle scorte (€)"])},
        "otherExternalServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altri servizi esterni (€)"])},
        "taxesLeviesAndSimilarPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasse, imposte e versamenti simili (€)"])},
        "personnelCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costi del personale (€)"])},
        "otherCurrentOperatingCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altre spese correnti di esercizio (€)"])},
        "financialIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricavi finanziari (€)"])},
        "financialCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oneri finanziari (€)"])},
        "fpTb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patrimonio netto su totale del bilancio (%)"])},
        "supplierDeadlines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scadenze dei fornitori (giorni)"])},
        "customerDeadlines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scadenze dei clienti (giorni)"])},
        "caf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacità di autofinanziamento (€)"])},
        "rnCa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultato netto su fatturato (%)"])},
        "etp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equivalente a tempo pieno"])},
        "dividends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dividendi pagati agli azionisti (€)"])},
        "soldeFinancier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo finanziario (€)"])},
        "salesGrowthRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso di crescita delle vendite (%)"])},
        "grossMarginRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso di margine lordo (%)"])},
        "ebitdaMarginRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso di margine EBITDA (%)"])},
        "operatingMarginRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso di margine operativo (%)"])}
      },
      "BLOCKS": {
        "intermediateOperationTotals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo intermedio di gestione"])},
        "debts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debiti"])},
        "balanceSheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilancio"])},
        "fixedAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività fisse"])},
        "currentAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività circolanti"])},
        "profitAndLossAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto economico"])},
        "balancePassive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passivo di bilancio"])},
        "capitalAndReserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitale proprio"])},
        "operatingIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrate operative"])},
        "operatingCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costi operativi"])},
        "operatingResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultato operativo"])},
        "ratios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporti finanziari"])},
        "increase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aumento"])}
      }
    },
    "LEGAL_DOCUMENT": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documenti legali"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun documento legale disponibile"])},
      "NO_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per consultare il documento, si prega di scaricarlo."])}
    },
    "BODACC_ANNOUNCEMENT": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evoluzione aziendale"])},
      "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
      "NATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natura"])},
      "INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazione"])},
      "DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettaglio"])}
    },
    "ANNUAL_ACCOUNTS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilanci annuali"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun bilancio annuale disponibile"])},
      "COMPANY_FINANCIAL_STATEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilancio aziendale"])}
    },
    "CHECKS": {
      "MORE_THAN_ONE_TAX_REPORT_WITH_SAME_SUBMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Più di un bilancio presentato alla stessa data"])},
      "LESS_THAN_X_YEARS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda con meno di 3 anni di attività"])},
      "MANAGEMENT_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio della gestione inferiore a 3 anni"])},
      "SHARE_CAPITAL_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio di capitale entro 3 anni"])},
      "HEADQUARTER_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimento della sede entro 3 anni"])},
      "ACTIVITY_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio di attività entro 3 anni"])},
      "MULTI_MANAGEMENT_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Più di 2 cambiamenti nella gestione negli ultimi 10 anni"])},
      "HAS_CESSATION_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cessazione dell'attività"])},
      "HAS_ALL_ESTABLISHMENTS_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le strutture sono chiuse"])},
      "HAS_ACTIVITY_CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Più di due cambiamenti di attività almeno negli ultimi 10 anni"])},
      "HAS_HEADQUARTER_CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Più di due trasferimenti della sede almeno negli ultimi 10 anni"])},
      "LOCATION_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizione a rischio"])},
      "DOMICILIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda domiciliata"])},
      "WEBSITE_DOMAIN_REGISTRATION_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di registrazione del dominio del sito web"])},
      "EMAIL_DOMAIN_REGISTRATION_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di registrazione del dominio dell'email"])},
      "EMAIL_DOMAIN_EQUALS_WEBSITE_DOMAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispondenza tra il dominio dell'email e il dominio del sito web"])},
      "RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radiazione"])},
      "COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedimenti collettivi"])},
      "OTHER_COMPANIES_IN_COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altre aziende in procedimenti collettivi"])},
      "OTHER_COMPANIES_IN_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altre aziende radiate"])},
      "ACTIVITIES_CODE_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settore di attività a rischio"])},
      "HEADQUARTER_DISTANCE_TOO_HIGH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notevole distanza tra l'indirizzo della sede legale e la residenza del amministratore"])},
      "LEGAL_SITUATION_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presenza di contenzioso"])},
      "MORE_THAN_THREE_YEARS_WITHOUT_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo di inattività superiore a 3 anni"])},
      "MULTI_HEADQUARTER_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Più di 2 trasferimenti di sede negli ultimi 10 anni"])},
      "MULTI_ACTIVITY_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Più di 2 cambiamenti di attività negli ultimi 10 anni"])},
      "CESSATION_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cessazione dell'attività"])},
      "ALL_ESTABLISHMENTS_ARE_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le sedi sono chiuse"])},
      "CONTACTABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contattabilità dei dati"])},
      "HEADQUARTER_ADDRESS_IN_WARNING_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frode rilevata a questo indirizzo"])},
      "MULTI_REGISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Più di una registrazione dalla fondazione"])},
      "MORE_THAN_ONE_ESTABLISHMENT_DOMICILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almeno un'azienda è domiciliata"])},
      "MORE_THAN_ONE_TAX_REPORT_WITH_SUBMISSION_DATE_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Più di un rapporto fiscale con una data di presentazione chiusa"])},
      "MORE_THAN_ONE_TAX_REPORT_WITH_SAME_SUBMISSION_DATE_AND_YEAR_DIFFERENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Più di un rapporto fiscale con la stessa data di presentazione e un anno diverso"])},
      "MORE_THAN_TEN_TAX_REPORTS_WITH_DIFFERENT_SUBMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Più di dieci rapporti fiscali con date di presentazione diverse"])},
      "RECENT_COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedimenti collettivi recenti"])},
      "OTHER_COMPANIES_IN_SAME_ACTIVITY_SECTOR_IN_COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altre aziende nello stesso settore di attività in procedimenti collettivi"])},
      "ALREADY_SOLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda già venduta"])},
      "ALREADY_DEREGISTERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda già cancellata"])},
      "COMPANY_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assenza di storicità (nessun bilancio, sito web, e-reputazione..)"])},
      "MATCHING_RATIOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilancio(i) identico(i) a una o più altre aziende"])},
      "MATCHING_YEARS": {
        "TAX_REPORT_OF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dichiarazione fiscale di"])},
        "SAME_AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identica a quella di"])},
        "OF_THE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["della società"])}
      }
    },
    "BENEFITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agevolazioni"])},
    "BONUSES": {
      "CUSTOMER_IS_IN_REPRESENTATIVES_OR_BUSINESS_OWNERS_OR_SHARE_HOLDERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente è un rappresentante o un beneficiario effettivo"])},
      "CUSTOMER_IS_IN_LINKEDIN_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente è collegato all’azienda su LinkedIn"])},
      "TAX_REPORTS_SUBMISSION_DATES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’azienda pubblica regolarmente le sue dichiarazioni fiscali"])},
      "STATE_WORKFORCE_MINIMUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’azienda ha un numero sufficiente di dipendenti"])},
      "EMAIL_OR_WEBSITE_DOMAIN_REGISTRATION_NOT_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il nome di dominio dell’email o del sito web ha almeno 3 anni"])}
    },
    "DOMICILIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domicilio"])},
    "BRANCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filiale"])},
    "CHECK_EMPLOYER": {
      "NO_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile verificare le informazioni"])},
      "FALSE_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'azienda è radiata"])},
      "TRUE_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'azienda non è radiata"])},
      "COLLECTIVE_PROCEEDING": {
        "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato rilevato alcun procedimento collettivo in corso"])},
        "MORE_THAN_ONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rilevato uno o più procedimenti collettivi pendenti"])},
        "RECENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rilevati uno o più procedimenti collettivi in corso, di cui almeno uno recente (< 3 anni)"])}
      }
    }
  },
  "ERRORS": {
    "MAINTENANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ops! Sembra ci sia un problema. Il nostro team sta già lavorando per risolverlo. Per favore, riprova più tardi."])},
    "NOT_FOUND_SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di identificazione inserito non esiste."])},
    "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna informazione recuperata"])},
    "TAX_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il nostro strumento di trattamento della dichiarazione fiscale non accetta documenti precedenti al 2018."])},
    "TAX_REPORT_TOO_OLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dichiarazione fiscale troppo vecchia"])},
    "DOCUMENT_PROCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante la lettura del documento"])},
    "COMPANY_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di identificazioneinserito non è associato a nessuna azienda attiva."])},
    "ENRICHMENT_PROCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna informazione trovata."])},
    "ERROR_OCCURRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore, si prega di riprovare più tardi"])},
    "NO_DATA_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun dato trovato, si prega di avviare una nuova analisi"])},
    "CALL_FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiamata terminata, si prega di notare che alcuni dati non sono accessibili. Per un rapporto completo, si prega di avviare una nuova analisi"])},
    "SIREN_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi completata, nessun dato trovato con il numero di identificazione fornito"])},
    "CREDIT_SAFE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun dato recuperato"])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun dato disponibile"])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbiamo riscontrato un errore con il numero di telefono inserito. Si prega di verificare le informazioni inserite"])},
    "UNPROCESSABLE_CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore, per favore riprova in una nuova cartella o contatta il supporto"])},
    "CANNOT_ACCESS_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non puoi accedere a questa cartella."])}
  },
  "BUTTONS": {
    "VALIDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
    "VERIFY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica"])},
    "CONNEXION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connessione"])},
    "VALIDATE_INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma Informazioni"])},
    "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
    "VERIFY_IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica Documento"])},
    "START_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizia Open Banking"])},
    "JOURNEY_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completa il percorso Open Banking nel browser"])},
    "FINISH_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termina Analisi"])},
    "NEW_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizia una Nuova Analisi"])},
    "EXPORT_PDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica Riassunto"])},
    "PREVIOUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precedente"])},
    "DETAILS_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli dell'Analisi"])},
    "VERIFY_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica Indirizzo"])},
    "SEND_TEXT_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia SMS"])},
    "SEND_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia E-mail"])},
    "BACK_HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna alla Pagina Principale"])},
    "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML/CFT"])},
    "VALIDATE_AND_SEND_SMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma e Invia SMS"])},
    "VALIDATE_AND_SEND_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma e Invia E-mail"])},
    "IFRAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iframe"])},
    "RUN_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esegui Analisi"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi IBAN"])},
    "FRAUD_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio"])},
    "SCORE_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggi di Frode"])},
    "SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona"])},
    "SELECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionato"])},
    "CONSULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulta"])},
    "CHOOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli"])},
    "DEACTIVATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disattiva"])},
    "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
    "CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
    "ENGLISH_VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versione Inglese"])},
    "FRENCH_VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versione Francese"])},
    "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
    "MY_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La mia azienda"])},
    "MY_USERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei utenti"])},
    "CONSUMPTION_MONITORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoraggio del consumo"])},
    "EXTRACT_MEMORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estrai memoria"])},
    "RULES_ENGINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motore di Regole"])},
    "BATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch"])},
    "MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il mio account"])},
    "UPLOAD_HERE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica qui"])},
    "LINK_TEXT_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link tramite SMS"])},
    "NEW_REQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova richiesta"])},
    "RESTART_REQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripetere la richiesta"])},
    "LINK_BROWSER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collegamento del browser"])},
    "LINK_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link tramite e-mail"])},
    "CREATE_A_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare un utente"])},
    "UNBLOCK_A_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sbloccare un utente"])},
    "SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cercare"])},
    "ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungere"])},
    "CONFIRM_AND_CONTINUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermare e continuare"])},
    "RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripristinare"])},
    "MODIFY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificare"])},
    "PASSWORD_RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset della password"])},
    "ACTIVATE_DEACTIVATE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivare / Disattivare account"])},
    "RESETTING_API_KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripristino della chiave API"])},
    "DELETE_THE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare l'account"])},
    "RETURN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritorno"])},
    "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvare"])},
    "CONSULT_FINOVOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulta l'analisi di Finovox"])},
    "LINKEDIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andare su LinkedIn"])},
    "DEPLOY_EVERYTHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribuire tutto"])},
    "REDUCE_EVERYTHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprimi tutto"])},
    "SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviare"])},
    "SCORE_SMB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio identità"])},
    "SCORE_COMPANY_FRAUD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio frode aziendale"])},
    "CLOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])},
    "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanti"])},
    "SELECT_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona tutto"])},
    "MY_TEAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il mio team"])},
    "DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare"])},
    "COMPLETE_UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completare il caricamento"])},
    "LIVE_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiarimento dei dubbi"])}
  },
  "APPLICANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedente"])},
  "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
  "VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contattabile"])},
  "LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collegato"])},
  "MAIL_ACCOUNTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account collegati alla posta"])},
  "PHONE_ACCOUNTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account collegati al telefono"])},
  "APPLICANT_RESULTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultati del richiedente"])},
  "CONTACT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni di contatto"])},
  "CLIENT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sul cliente"])},
  "VERIFICATION_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica del documento"])},
  "VERIFICATION_TAX_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica della dichiarazione fiscale"])},
  "CONFIDENCE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio di affidabilità dell'identità"])},
  "SMB_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio di frode per l'identità aziendale"])},
  "CONFIDENCE_INDEX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indice di fiducia dell'identità"])},
  "DETAILED_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli del punteggio"])},
  "CONFIDENCE_EXPLICATIONS": {
    "UNDER_TWENTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati forniti non correlati alla persona"])},
    "BETWEEN_TWENTY_AND_FIFTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati dubbi, verifica dell'identità consigliata"])},
    "ABOVE_FIFTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati forniti esistenti e correlati alla persona"])}
  },
  "DOCUMENT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi dei documenti"])},
  "ANALYSIS_RESULT_RETRIEVAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recupero dei risultati dell'analisi in corso ..."])},
  "UPLOAD_FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento del documento completato, puoi chiudere questa finestra."])},
  "FACE_MATCHING_LINK_CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti è stato inviato un link via SMS, controlla il tuo telefono"])},
  "LINK_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collegamento con l'azienda"])},
  "IDENTITY_CONSISTENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coerenza dell'identità"])},
  "COMPANY_CONSISTENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coerenza con l'azienda"])},
  "OTHER_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altra persona"])},
  "SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situazione"])},
  "NOT_FOUND": {
    "NO_BUSINESS_OWNERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun beneficiario effettivo noto."])},
    "NO_REPRESENTATIVES_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun rappresentante noto."])},
    "NO_LEADERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun leader recuperato..."])}
  },
  "OPEN_BANKING": {
    "END_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dati sono stati trasmessi con successo. Puoi chiudere questa pagina per vedere il risultato dell'analisi."])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking"])},
    "RESULT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi dell'OpenBanking"])},
    "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli dell'analisi dell'OpenBanking"])},
    "BANK_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banca"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "INCOMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrate"])},
    "OUTCOMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uscite"])},
    "HOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolare"])},
    "ACCOUNT_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del conto"])},
    "MONTH_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stipendio mensile medio"])},
    "MONTH_RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affitto mensile medio"])},
    "BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo attuale"])},
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti preghiamo di chiedere al tuo cliente di avere il suo smartphone e i suoi dati bancari a portata di mano."])},
    "CATEGORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
    "FINANCIAL_ANALYSIS_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo di analisi finanziaria"])},
    "ANALYSIS_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo analizzato"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["al"])},
    "CATEGORIES": {
      "MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo massimo delle transazioni"])},
      "MONTH_MAX_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data della transazione massima"])},
      "MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo medio mensile totale"])},
      "MONTH_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo medio mensile - Salari"])},
      "MONTH_SALARIES_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data media mensile - Salari"])},
      "MONTH_INTERESTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo medio mensile - Interessi"])},
      "MONTH_TRANSFERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo medio mensile - Bonifici"])},
      "MONTH_RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo medio mensile - Affitti"])},
      "MONTH_WITHDRAWALS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo medio mensile - Prelievi bancomat"])},
      "MONTH_INSURANCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo medio mensile - Assicurazioni"])},
      "MONTH_UTILITIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo medio mensile - Servizi pubblici"])},
      "MONTH_GROCERIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo medio mensile - Spese alimentari"])},
      "MONTH_FOOD_CONSUMPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo medio mensile - Consumi alimentari"])},
      "MONTH_SHOPPINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo medio mensile - Acquisti"])},
      "MONTH_HEALTH_WELLNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo medio mensile - Salute e benessere"])},
      "MONTH_TRANSPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo medio mensile - Trasporti"])},
      "MONTH_TELECOMMUNICATION_SUBSCRIPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo medio mensile - Abbonamenti di telecomunicazione"])},
      "MONTH_AUDIO_VISUAL_SUBSCRIPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo medio mensile - Abbonamenti audiovisivi"])},
      "MONTH_INCOMES_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media mensile dei redditi"])},
      "MONTH_INCOMES_SALARIES_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media mensile degli stipendi"])},
      "MONTH_INCOMES_ALLOWANCES_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media mensile delle indennità"])},
      "MONTH_INCOMES_PENSIONS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media mensile delle pensioni"])},
      "MONTH_INCOMES_AMOUNT_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media mensile più alta"])},
      "MONTH_INCOMES_AMOUNT_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media mensile più bassa"])},
      "MONTH_OUTCOMES_RENTS_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affitti mensili medi"])},
      "MONTH_OUTCOMES_CREDITS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crediti mensili medi"])},
      "MONTH_OUTCOMES_CREDITS_DATE_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data media delle scadenze dei crediti"])},
      "MONTH_OUTCOMES_PENSIONS_DATE_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di pagamento di una pensione"])},
      "RECOMMENDED_DEBIT_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data ottimale per addebito"])},
      "RECOMMENDED_DEBIT_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo ottimale per addebito"])},
      "MONTHLY_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo mensile"])},
      "MONTH_OUTCOMES_RENTS_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo minimo degli affitti"])},
      "MONTH_OUTCOMES_RENTS_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo massimo degli affitti"])},
      "MONTH_OUTCOMES_UTILITIES_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo minimo dei servizi"])},
      "MONTH_OUTCOMES_UTILITIES_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo massimo dei servizi"])},
      "MONTH_OUTCOMES_CREDITS_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo minimo dei crediti"])},
      "MONTH_OUTCOMES_CREDITS_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo massimo dei crediti"])},
      "MONTH_OUTCOMES_CREDITS_COUNT_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero minimo di addebiti al mese"])},
      "MONTH_OUTCOMES_CREDITS_COUNT_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero massimo di addebiti al mese"])},
      "BANK_CARD_AND_DEBITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carta bancaria e addebiti"])},
      "DEBITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addebiti"])},
      "BANK_CARD_PRESENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presenza di carte bancarie collegate"])},
      "BANK_CARD_QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità di carte che hanno effettuato transazioni in uscita"])},
      "BANK_CARD_NUMBERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numeri di carte identificati per transazioni in uscita"])},
      "MONTH_NEGATIVE_BALANCE_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data più precoce"])},
      "MONTH_NEGATIVE_BALANCE_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data più tardiva"])},
      "MAX_BALANCE_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data minima mensile più precoce in cui il saldo è massimo"])},
      "LAST_SALARY_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data dell'ultimo stipendio"])},
      "MONTH_END_RATIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentuale delle spese sulle entrate"])},
      "MONTH_NEGATIVE_BALANCE_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data media del passaggio in rosso del conto"])},
      "AVERAGE_MONTHLY_ENTRIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medie mensili delle voci"])},
      "TOTAL_FOR_THE_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale nel periodo"])},
      "AVERAGE_MONTHLY_TAX_REVENUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrate fiscali mensili medie"])},
      "AVERAGE_MONTHLY_EXITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uscite mensili medie"])},
      "ANNUAL_NET_INCOME_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo del reddito netto annuale"])},
      "ANNUAL_REVENUE_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo del fatturato annuale"])},
      "MONTHLY_TAX_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo mensile delle imposte"])},
      "AVERAGE_MONTHLY_PAYROLL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media mensile della massa salariale"])},
      "OTHER_ACCOUNTS": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altri conti"])},
        "MONTH_INCOMES_SAVINGS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media mensile del reddito da risparmio"])},
        "MONTH_OUTCOMES_SAVINGS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media mensile dell'uscita dal risparmio"])},
        "MONTH_INCOMES_TRANSFERS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media mensile dei trasferimenti in entrata"])},
        "MONTH_OUTCOMES_TRANSFERS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media mensile dei trasferimenti in uscita"])}
      }
    },
    "DEBT_RATIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporto di debito"])},
    "MONTH_OUTCOMES_CREDITS_COUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero medio di crediti in corso"])},
    "REMAINS_TO_LIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residuo da vivere"])},
    "START_STUDY_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di inizio del periodo di analisi finanziaria"])},
    "END_STUDY_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di fine del periodo di analisi finanziaria"])},
    "MONTH_END_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo mensile finale"])},
    "TOP_JOINT_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Conto Congiunto"])},
    "TOP_RECOMMENDED_DATE_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data consigliata di addebito"])},
    "TOP_BANKING_SUPERVISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervisione bancaria"])},
    "GLOBAL_TRANSACTIONS_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero totale di transazioni"])},
    "MOVEMENT_SUMMARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sommario dei movimenti"])},
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichetta"])},
    "ACCOUNT_STATEMENT": {
      "BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza l'estratto conto"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESTRATTO(I) CONTO(I)"])},
      "ACCOUNT": {
        "CHECKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTO CORRENTE"])},
        "SAVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTO DI RISPARMIO"])}
      },
      "AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
      "PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione in attesa"])},
      "NO_DATA_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun dato trovato, avviare una nuova ricerca"])},
      "PRIVACY_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per motivi di sicurezza e protezione dei dati, l'estratto conto di OpenBanking non sarà più disponibile dopo un certo periodo di tempo."])},
      "CATEGORY": {
        "INCOME": {
          "ADVERTISINGPAYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento Pubblicitario"])},
          "ALLOWANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indennità"])},
          "ALLOWANCE:FAMILY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indennità Familiare"])},
          "ALLOWANCE:UNEMPLOYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indennità di Disoccupazione"])},
          "ALLOWANCE:OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altra Indennità"])},
          "ALLOWANCE:HEALTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegno sanitario"])},
          "AUDIOVISUALSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonamento Audiovisivo"])},
          "AUTOMOBILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese per l’auto"])},
          "AUTOMOBILE:MAINTENANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manutenzione dell'Automobile"])},
          "AUTOMOBILE:PARKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcheggio dell'Automobile"])},
          "AUTOTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimento Automatico"])},
          "BANKINGFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione Bancaria"])},
          "BANKINGFEE:CONTRACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissioni relative al contratto bancario"])},
          "BANKINGFEE:USE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissioni relative all'utilizzo di un prodotto bancario"])},
          "CASHCOLLECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raccolta Contante"])},
          "CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegno"])},
          "CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credito"])},
          "CREDIT:CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credito al Consumo"])},
          "CREDIT:CONSUMER:SPLIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credito al consumo suddiviso"])},
          "CREDIT:HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credito Abitativo"])},
          "CRYPTOCURRENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criptovaluta"])},
          "CULTUREEVENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento Culturale"])},
          "DATAPURCHASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisto Dati"])},
          "DEPOSIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposito"])},
          "DONATIONANDCHARITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donazione e Beneficenza"])},
          "EDUCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educazione"])},
          "EDUCATION:DRIVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educazione alla Guida"])},
          "EDUCATION:SCHOOL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educazione Scolastica"])},
          "FINEFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tariffa di Multa"])},
          "FITNESSSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonamento Fitness"])},
          "FOODCONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo di Cibo"])},
          "FOODCONSUMPTION:DELIVERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo alimentare tramite consegna"])},
          "FRAUDULENTTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimento Fraudolento"])},
          "GAMBLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gioco d'Azzardo"])},
          "GROCERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generi Alimentari"])},
          "GROCERY:DRIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spesa in modalità drive"])},
          "HEALTHWELLNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salute e Benessere"])},
          "HEALTHWELLNESS:BODY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese relative alla cura del corpo"])},
          "HEALTHWELLNESS:CONSULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese relative alle consulenze mediche"])},
          "HEALTHWELLNESS:SHOP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese relative agli acquisti medici"])},
          "HOSTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ospitalità"])},
          "INDEMNITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indennità"])},
          "INSURANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurazione"])},
          "INSURANCE:HEALTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurazione sanitaria"])},
          "INSURANCE:OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurazione"])},
          "INTEREST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interesse"])},
          "INTERNATIONALTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimento Internazionale"])},
          "INTERVENTIONFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tariffa di Intervento"])},
          "INVESTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investimento"])},
          "LEGALFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tariffa Legale"])},
          "LEISURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo Libero"])},
          "PENSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensione"])},
          "PETCARE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cura degli Animali Domestici"])},
          "READINGSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonamento alla Lettura"])},
          "REIMBURSEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimborso"])},
          "REJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifiuto"])},
          "REJECT:CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifiuto del Credito"])},
          "REJECT:OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro Rifiuto"])},
          "RENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affitto"])},
          "RENT:CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affitto al Consumatore"])},
          "RENT:HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affitto Abitativo"])},
          "SALARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stipendio"])},
          "SALARY:REGULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stipendio Normale"])},
          "SALARY:TEMPORARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stipendio Temporaneo"])},
          "SALARY:ADVANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anticipo sullo stipendio"])},
          "SALARY:BONUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus e incentivi"])},
          "SALARY:FEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note spese"])},
          "SAVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risparmio"])},
          "SECURITYSERVICESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonamento ai Servizi di Sicurezza"])},
          "SEIZUREFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tariffa di Pignoramento"])},
          "SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio"])},
          "SHOPPING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisti"])},
          "SHOPPING:TOBACCOBAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisti in bar e tabaccherie"])},
          "SOCIALFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costi relativi ai contributi sociali"])},
          "SOFTWARESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonamento Software"])},
          "STOCKMARKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercato Azionario"])},
          "SYNDICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sindacato"])},
          "TAXE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tassa"])},
          "TELECOMSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonamento Telecom"])},
          "TELECOMSUB:BOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costi relativi agli abbonamenti di telecomunicazioni per internet"])},
          "TELECOMSUB:PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costi relativi agli abbonamenti di telecomunicazioni per la telefonia"])},
          "TRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimento"])},
          "TRANSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasporto"])},
          "TRANSPORT:ENERGY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costi di approvvigionamento carburante"])},
          "TRANSPORT:MOBILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costi di mobilità per i trasporti"])},
          "TRANSPORT:PARKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costi di parcheggio"])},
          "TRANSPORT:TOLL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costi di pedaggio"])},
          "UNPAID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Pagato"])},
          "UTILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilità"])},
          "UTILITY:ENERGY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilità Energetica"])},
          "UTILITY:WATER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilità Idrica"])},
          "UTILITY:OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altra Utilità"])},
          "VIDEOGAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videogioco"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])}
        },
        "OUTCOME": {
          "ADVERTISINGPAYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento Pubblicitario"])},
          "ALLOWANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indennità"])},
          "ALLOWANCE:FAMILY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indennità Familiare"])},
          "ALLOWANCE:UNEMPLOYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indennità di Disoccupazione"])},
          "ALLOWANCE:OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altra Indennità"])},
          "ALLOWANCE:HEALTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegno sanitario"])},
          "AUDIOVISUALSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonamento Audiovisivo"])},
          "AUTOMOBILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese per l’auto"])},
          "AUTOMOBILE:MAINTENANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manutenzione dell'Automobile"])},
          "AUTOMOBILE:PARKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcheggio dell'Automobile"])},
          "AUTOTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimento Automatico"])},
          "BANKINGFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione Bancaria"])},
          "BANKINGFEE:CONTRACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissioni relative al contratto bancario"])},
          "BANKINGFEE:USE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissioni relative all'utilizzo di un prodotto bancario"])},
          "CASHCOLLECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raccolta Contante"])},
          "CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegno"])},
          "CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credito"])},
          "CREDIT:CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credito al Consumo"])},
          "CREDIT:CONSUMER:SPLIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credito al consumo suddiviso"])},
          "CREDIT:HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credito Abitativo"])},
          "CRYPTOCURRENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criptovaluta"])},
          "CULTUREEVENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento Culturale"])},
          "DATAPURCHASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisto Dati"])},
          "DONATIONANDCHARITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donazione e Beneficenza"])},
          "EDUCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educazione"])},
          "EDUCATION:DRIVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educazione alla Guida"])},
          "EDUCATION:SCHOOL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educazione Scolastica"])},
          "FINEFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tariffa di Multa"])},
          "FITNESSSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonamento Fitness"])},
          "FOODCONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo di Cibo"])},
          "FOODCONSUMPTION:DELIVERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo alimentare tramite consegna"])},
          "FRAUDULENTTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimento Fraudolento"])},
          "GAMBLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gioco d'Azzardo"])},
          "GROCERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generi Alimentari"])},
          "GROCERY:DRIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spesa in modalità drive"])},
          "HEALTHWELLNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salute e Benessere"])},
          "HEALTHWELLNESS:BODY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese relative alla cura del corpo"])},
          "HEALTHWELLNESS:CONSULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese relative alle consulenze mediche"])},
          "HEALTHWELLNESS:SHOP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese relative agli acquisti medici"])},
          "HOSTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ospitalità"])},
          "INDEMNITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indennità"])},
          "INSURANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurazione"])},
          "INSURANCE:HEALTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurazione sanitaria"])},
          "INSURANCE:OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurazione"])},
          "INTEREST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interesse"])},
          "INTERNATIONALTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimento Internazionale"])},
          "INTERVENTIONFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tariffa di Intervento"])},
          "INVESTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investimento"])},
          "LEGALFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tariffa Legale"])},
          "LEISURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo Libero"])},
          "PENSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensione"])},
          "PETCARE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cura degli Animali Domestici"])},
          "READINGSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonamento alla Lettura"])},
          "REIMBURSEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimborso"])},
          "REJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifiuto"])},
          "REJECT:CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifiuto del Credito"])},
          "REJECT:OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro Rifiuto"])},
          "RENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affitto"])},
          "RENT:CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affitto al Consumatore"])},
          "RENT:HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affitto Abitativo"])},
          "SALARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stipendio"])},
          "SALARY:REGULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stipendio Normale"])},
          "SALARY:TEMPORARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stipendio Temporaneo"])},
          "SALARY:ADVANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anticipo sullo stipendio"])},
          "SALARY:BONUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus e incentivi"])},
          "SALARY:FEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note spese"])},
          "SAVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risparmio"])},
          "SECURITYSERVICESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonamento ai Servizi di Sicurezza"])},
          "SEIZUREFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tariffa di Pignoramento"])},
          "SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio"])},
          "SHOPPING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisti"])},
          "SHOPPING:TOBACCOBAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisti in bar e tabaccherie"])},
          "SOCIALFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costi relativi ai contributi sociali"])},
          "SOFTWARESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonamento Software"])},
          "STOCKMARKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercato Azionario"])},
          "SYNDICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sindacato"])},
          "TAXE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tassa"])},
          "TELECOMSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonamento Telecom"])},
          "TELECOMSUB:BOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costi relativi agli abbonamenti di telecomunicazioni per internet"])},
          "TELECOMSUB:PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costi relativi agli abbonamenti di telecomunicazioni per la telefonia"])},
          "TRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimento"])},
          "TRANSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasporto"])},
          "TRANSPORT:ENERGY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costi di approvvigionamento carburante"])},
          "TRANSPORT:MOBILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costi di mobilità per i trasporti"])},
          "TRANSPORT:PARKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costi di parcheggio"])},
          "TRANSPORT:TOLL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costi di pedaggio"])},
          "UNPAID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Pagato"])},
          "UTILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilità"])},
          "UTILITY:ENERGY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilità Energetica"])},
          "UTILITY:WATER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilità Idrica"])},
          "UTILITY:OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altra Utilità"])},
          "VIDEOGAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videogioco"])},
          "WITHDRAWAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prelievo"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])}
        }
      }
    },
    "RECOMMENDATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raccomandazioni"])},
    "APPROVALS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approvazioni"])},
    "INDEBTEDNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indebitamento"])},
    "HABITAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alloggio"])},
    "EXISTING_CREDIT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi dei crediti esistenti"])},
    "ALERTS": {
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
      "CAUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causa"])},
      "AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
      "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun avviso rilevato"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvisi"])}
    },
    "SERVICES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizi"])},
    "WATER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["acqua"])},
    "GAS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gas"])},
    "ELECTRICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elettricità"])},
    "BANK_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte bancarie"])},
    "DEFERRED_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rilevamento addebiti differiti"])},
    "GLOBAL_TRANSACTION_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero totale di transazioni"])},
    "MONTH_NEGATIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passaggio al rosso nel periodo"])},
    "MAIN_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto principale"])},
    "NO_TRANSACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna transazione di reddito rilevata."])},
    "ALERT": {
      "ABSENCE_OF_INCOME": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assenza di reddito"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna transazione di reddito rilevata"])}
      },
      "PRESENCE_OF_REJECTS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presenza di rifiuti"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È stata rilevata almeno 1 transazione con rifiuti"])}
      },
      "PRESENCE_OF_UNPAIDS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presenza di insolvenze"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È stata rilevata almeno 1 transazione con insolvenze"])}
      },
      "PRESENCE_OF_PENSIONS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presenza di pensioni"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È stata rilevata almeno 1 transazione con pensioni"])}
      },
      "PRESENCE_OF_GAMBLINGS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presenza di giochi d'azzardo"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almeno il 10% delle spese globali è utilizzato per il gioco d'azzardo"])}
      },
      "PRESENCE_OF_ALLOWANCES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presenza di assegni familiari"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È stata rilevata almeno 1 transazione con assegni familiari"])}
      },
      "PRESENCE_OF_SEIZURE_FEES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presenza di spese di pignoramento"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È stata rilevata almeno 1 transazione con spese di pignoramento"])}
      },
      "MONTH_NEGATIVE_BALANCE": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo negativo del mese"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbiamo rilevato uno o più giorni con un saldo negativo"])}
      },
      "BANK_WITH_EXTREME_RISK_LEVEL": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banca con elevato livello di rischio"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbiamo rilevato uno o più conti presso una banca considerata estremamente rischiosa"])}
      },
      "BANK_WITH_HIGH_RISK_LEVEL": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banca con alto livello di rischio"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbiamo rilevato uno o più conti presso una banca considerata molto rischiosa"])}
      },
      "TRANSACTION_HISTORY_DATE": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data della cronologia delle transazioni"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono stati trasmessi meno di 2 mesi di cronologia delle transazioni per questa analisi"])}
      },
      "TRANSACTION_HISTORY_DATE_ONE_MONTH": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data storico transazioni"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno di 1 mese di storico delle transazioni è stato trasmesso per questa analisi"])}
      },
      "PRESENCE_OF_CASH_COLLECTIONS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presenza di aziende di recupero crediti"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almeno 1 transazione con un'agenzia di recupero rilevata"])}
      },
      "PRESENCE_OF_CRYPTOCURRENCIES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presenza di criptovalute"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almeno il 10% delle spese globali è utilizzato per criptovalute"])}
      },
      "TRANSACTION_HISTORY_COUNT": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di operazioni nella cronologia delle transazioni"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono state trasmesse meno di 10 transazioni per questa analisi"])}
      },
      "PRESENCE_OF_CREDITS_PER_MONTH": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presenza di crediti al mese"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono state rilevate almeno 3 transazioni di credito al mese"])}
      },
      "PRESENCE_OF_INTERVENTION_FEES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presenza di spese di intervento"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È stata rilevata almeno 1 transazione con spese di intervento"])}
      },
      "PRESENCE_OF_WITHDRAWALS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presenza di prelievi al mese"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almeno il 25% delle spese globali è utilizzato per prelievi"])}
      },
      "PRESENCE_OF_INTERNATIONALS_TRANSFERS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presenza di bonifici internazionali"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È stata rilevata almeno 1 transazione con bonifici internazionali"])}
      },
      "TRANSACTION_HISTORY_FOR_ANALYSIS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cronologia delle transazioni per analisi"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di transazioni trasmesse non è sufficiente per consentire un'analisi tempestiva"])}
      },
      "ABSENCE_OF_TAXE": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assenza di tasse"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stata rilevata nessuna transazione fiscale"])}
      },
      "LOSS_OF_SALARY": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdita di stipendio"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È stata rilevata una perdita di stipendio"])}
      },
      "ABSENCE_OF_SOCIAL_FEES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assenza di contributi sociali"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stata rilevata alcuna transazione di contributi sociali"])}
      },
      "PRESENCE_OF_AUTOTRANSFERS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presenza di trasferimenti automatici"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almeno il 50% dei redditi o delle spese globali è utilizzato per trasferimenti automatici"])}
      },
      "PRESENCE_OF_UNEMPLOYMENT_ALLOWANCES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presenza di indennità di disoccupazione"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È stata rilevata almeno una transazione con indennità di disoccupazione"])}
      },
      "ACCOUNT_WITH_BANKING_SUPERVISION": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto con supervisione bancaria"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbiamo rilevato uno o più conti con supervisione bancaria"])}
      },
      "ACCOUNT_NEWLY_CREATED": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto appena creato"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbiamo rilevato uno o più conti appena creati"])}
      },
      "PRESENCE_OF_FRAUDULENT_TRANSFERS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presenza di trasferimenti fraudolenti"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È stata rilevata almeno 1 transazione con trasferimenti fraudolenti"])}
      },
      "NEW_SALARY": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo stipendio"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È stato rilevato un nuovo stipendio"])}
      },
      "NUMBER_OF_CURRENCIES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di valute"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono state rilevate almeno due valute diverse"])}
      },
      "BALANCE_DECREASING_TREND": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tendenza decrescente del saldo"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbiamo rilevato una forte diminuzione del saldo nel tempo"])}
      }
    },
    "NET_INCOME_AND_ANNUAL_TURNOVER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reddito netto e fatturato annuo"])},
    "TAXES_AND_PAYROLL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasse e Libro Paga"])},
    "DAILY_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo giornaliero"])},
    "MONTHLY_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo mensile"])},
    "DAILY_AND_MONTHLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilancio quotidiano e mensile"])},
    "WRONG_ANALYSIS_B2C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione, l'analisi è stata effettuata su un account personale e non su un account aziendale"])},
    "WRONG_ANALYSIS_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione, l'analisi è stata effettuata su un account aziendale e non su un account personale"])},
    "PROVIDER_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante il recupero dei dati. Avviare nuovamente il file."])},
    "UNDEFINED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non definita"])},
    "AVERAGE_NUMBER_OF_TRANSACTIONS_BY_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero medio di transazioni / mese"])},
    "MONTHLY_AVERAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media mensile"])},
    "SUM_OVER_THE_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somma sul periodo"])},
    "ANNUAL_ESTIMATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stima annuale"])},
    "SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stipendi"])},
    "ALLOWANCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegni"])},
    "PENSIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensioni"])},
    "TOTAL_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale entrate"])},
    "RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affitti"])},
    "LOANS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crediti"])},
    "TOTAL_OUTCOMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale uscite"])},
    "INSTITUTION_ASSOCIATED_WITH_LOAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istituzione(i) associata(e) a un credito"])},
    "REVENUE_INCL_VAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricavi CA inclusa IVA"])},
    "OUTGOING_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stipendi in uscita"])},
    "OUTGOING_SOCIAL_CHARGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oneri sociali in uscita"])},
    "OTHER_EXPENSES_INCL_VAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altri costi inclusa IVA"])},
    "CREDITS_INCL_VAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crediti inclusa IVA"])},
    "TAXES_INCL_VAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasse incluse IVA"])},
    "INTRA_COMPANY_TRANSFERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flussi intra-aziendali (auto trasferimenti)"])},
    "CONTINUOUS_ACCESS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso continuo"])},
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'accesso continuo ti offre la possibilità di aggiornare i dati bancari fino a 180 giorni dopo che il cliente ha effettuato l'open-banking."])},
      "BOX": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione dell'aggregazione dei dati"])},
        "DATA": {
          "FIRST_AGGREGATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data della prima aggregazione"])},
          "LAST_AGGREGATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data dell'ultima aggregazione"])},
          "EXPIRATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di scadenza del consenso"])},
          "REFRESH_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di aggiornamenti"])},
          "FIRST_REFRESH_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data del primo aggiornamento"])},
          "LAST_REFRESH_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data dell'ultimo aggiornamento"])},
          "DISABLED_REFRESH_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di disattivazione dell'aggiornamento"])}
        },
        "ACTIONS": {
          "REFRESH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna i dati"])},
          "DISABLE_REFRESH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interrompere l'accesso continuo"])}
        },
        "MODAL": {
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interrompere l'accesso continuo dell'OpenBanking"])},
          "1ST_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stai per interrompere l'accesso continuo dell'OpenBanking."])},
          "2ND_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sarai più in grado di aggiornare i dati aggregati."])}
        },
        "TOAST": {
          "DISABLE_REFRESH": {
            "SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'accesso continuo è stato disattivato con successo."])},
            "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante la disattivazione dell'accesso continuo all'OpenBanking."])}
          },
          "REFRESH": {
            "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dati sono in fase di aggiornamento."])},
            "SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dati sono stati aggiornati con successo."])},
            "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante l'aggiornamento dei dati."])}
          }
        }
      }
    }
  },
  "SOCIAL_FACE_MATCHING": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerca immagini"])},
    "CATCHED_IMAGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immagini recuperate"])},
    "NO_IMAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna immagine trovata"])}
  },
  "AMLCFT": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lotta contro il riciclaggio e il terrorismo"])},
    "FREEZING_ASSETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congelamento dei beni"])},
    "PPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona politicamente esposta"])},
    "PPE_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa persona è identificata come politicamente esposta"])},
    "ADVERSE_MEDIA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stampa"])},
    "ADVERSE_MEDIA_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ci sono diversi articoli stampa che riguardano il richiedente."])},
    "ADVERSE_MEDIA_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di stampa"])},
    "COLUMN_TABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCBFT"])},
    "SANCTIONED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanzionata"])},
    "SANCTIONED_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa persona è stata identificata come sanzionata."])},
    "SANCTIONED_EXPLANATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciò può riguardare il congelamento dei suoi beni, legami con il terrorismo e/o il riciclaggio."])},
    "CLASS_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capi di Stato, membri dell'esecutivo nazionale, membri dei legislatori nazionali, alti funzionari dell'esercito e delle forze dell'ordine, alti funzionari di agenzie nazionali, membri dei consigli di amministrazione delle banche centrali, alti funzionari della giustizia (livello nazionale), Top - alti dirigenti dei partiti politici"])},
    "CLASS_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membri del consiglio di amministrazione di organizzazioni internazionali e federazioni sportive internazionali, ambasciatori, alti commissari e altre posizioni diplomatiche di alto livello, membri dell'esecutivo regionale, membri delle assemblee legislative regionali, alti funzionari giudiziari (livello regionale)"])},
    "CLASS_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amministratori delegati e consigli di amministrazione di aziende e organizzazioni pubbliche"])},
    "CLASS_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sindaci e membri delle autorità locali (livello sub-regionale)"])},
    "LEVEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livello"])},
    "ADVERSE_MEDIA_DETAILS": {
      "financial-crime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crimine finanziario"])},
      "violent-crime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crimine violento"])},
      "sexual-crime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crimine sessuale"])},
      "terrorism": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terrorismo"])},
      "fraud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frode"])},
      "narcotics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stupefacenti"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generale"])},
      "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proprietà"])},
      "financial-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML CFT Finanziario"])},
      "fraud-linked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collegato alla frode"])},
      "narcotics-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML CFT Stupefacenti"])},
      "violence-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML CFT Violenza"])},
      "cybercrime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crimine informatico"])},
      "general-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML CFT Generale"])},
      "regulatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regolamentare"])},
      "financial-difficulty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Difficoltà finanziarie"])},
      "violence-non-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non AML CFT Violenza"])},
      "other-financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro finanziario"])},
      "other-serious": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro grave"])},
      "other-minor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro minore"])}
    },
    "LINKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collegamenti"])},
    "NO_RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa persona non è identificata né come sanzionata né come politicamente esposta."])}
  },
  "TOOLTIP": {
    "CURRENT_PROCEDURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda con un procedimento legale in corso."])},
    "MOTHBALLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda messa in stand-by e riattivata successivamente."])},
    "MOBILE_PHONE_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica del numero di cellulare."])},
    "SOCIAL_FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immagini trovate su Google correlate alle informazioni inserite."])},
    "EMAIL_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica dell'email."])},
    "AVAILABLES_PICTURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immagini disponibili sui social media in relazione alle informazioni del richiedente."])},
    "NUMBER_ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di stabilimenti di proprietà dell'azienda"])},
    "FREEZING_ASSETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì se ci sono attualmente beni congelati."])},
    "SCORE_WEB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio di frode sull'esistenza e l'affidabilità dei dati di contatto del richiedente."])},
    "SCORE_SECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio di rischio sull'analisi finanziaria e sulla solvibilità dell'azienda."])},
    "DEFAULT_PROBABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misura del rischio di insolvenza della struttura."])},
    "SCORE_LAST_UPDATE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggi aggiornati il"])},
    "SCORE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il punteggio valuta la solidità finanziaria dell'azienda in base al settore di attività e ai bilanci finanziari."])},
    "SCORE_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio di rischio sull'analisi finanziaria del conto bancario."])},
    "MONTH_END_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo mensile medio nel periodo studiato."])},
    "EMAIL_DISPOSABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email temporanea"])},
    "EMAIL_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail rischiosa"])},
    "MOBILE_PHONE_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di telefono collegato"])},
    "EMAIL_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email collegata"])},
    "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua a compilare il fascicolo"])},
    "VIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza il fascicolo"])},
    "DUPLICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartella duplicata"])},
    "DELETE_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare la cartella"])},
    "BLOCK_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile riaprire un fascicolo completato."])}
  },
  "EMAIL_DISPOSABLE": {
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'email fornita è collegata a un dominio usa e getta."])}
  },
  "DOCUMENT_CONTROL": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllo dei documenti"])},
    "CHOOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli un tipo di documento"])},
    "ID_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carta d'identità"])},
    "ID_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carta d'identità"])},
    "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carta d'identità"])},
    "DRIVING_LICENSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patente di guida"])},
    "PASSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passaporto"])},
    "RESIDENCE_PERMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permesso di soggiorno"])},
    "KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis - Azienda o situazione"])},
    "INDIVIDUAL_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis - Persona fisica"])},
    "COMPANY_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis - Azienda"])},
    "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticità - Analisi della non alterazione digitale"])},
    "AUTHENTICITY_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli di autenticità"])},
    "TAX_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dichiarazione fiscale"])},
    "TAX_REPORT_S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dichiarazione fiscale semplificata"])},
    "TAX_REPORT_C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dichiarazione fiscale completa"])},
    "TAX_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avviso di imposta - Redditi o immobili"])},
    "INCOME_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta sul reddito"])},
    "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro tipo"])},
    "PROPERTY_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta sulla proprietà"])},
    "BANK_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli bancari"])},
    "BANK_STATEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estratto conto"])},
    "BLOC_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllo dei documenti"])},
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'analisi del documento è fallita"])},
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di completare il caricamento del documento nella finestra a sinistra per poter visualizzare l’analisi del documento."])},
    "PAYSLIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busta paga"])},
    "PAYSLIP_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheda di pagamento"])},
    "RIB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIB"])},
    "KBIS_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS"])},
    "TAX_NOTICE_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avviso di pagamento delle imposte"])},
    "OTHER_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro documento"])},
    "NEW_DOCUMENT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza un nuovo documento"])},
    "NEW_FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllo biometrico"])},
    "FACE_MATCHING_DESC_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi eseguire una nuova analisi dell'identità con controllo biometrico?"])},
    "FACE_MATCHING_DESC_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di inserire il numero di telefono del cliente, affinché possa ricevere via SMS il link per eseguire la sua analisi."])},
    "ID_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi dell'identità"])},
    "ID_ANALYSIS_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui è possibile avviare un'analisi dei documenti d'identità come carte d'identità nazionali, passaporti, permessi di soggiorno, patenti di guida."])},
    "DOCUMENT_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi del documento"])},
    "DOCUMENT_ANALYSIS_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui è possibile avviare un'analisi del documento per una dichiarazione dei redditi, KBIS, scheda di pagamento, avviso di pagamento delle imposte o qualsiasi altro documento in formato PDF."])},
    "SELECT_DOCUMENT_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare uno o più tipi di documento"])},
    "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sconosciuto"])},
    "UNKNOWN_DOC_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il documento fornito non è stato identificato come un documento di identità valido. Prima di caricarlo nuovamente, assicurati che sia di buona qualità, leggibile e completo. Se hai caricato una carta d’identità nazionale, assicurati di aver aggiunto sia il fronte che il retro nello stesso invio."])},
    "NO_DOCUMENT_UPLOADED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun documento caricato."])},
    "FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllo biometrico"])},
    "SELFIE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selfie"])},
    "TABLE": {
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
      "DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento"])},
      "COMPLIANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conformità"])},
      "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticità"])},
      "ID_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova analisi dell'identità"])},
      "DOCUMENT_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova analisi documentale"])},
      "RESULT": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli dell'analisi"])},
        "CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica"])},
        "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultati"])},
        "WAITING_CONFIRMATION_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi in attesa di conferma da parte tua"])},
        "IN_PROGRESS_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi in corso"])},
        "LIVE_CHECK": {
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivazione della verifica dei dubbi"])},
          "DESC_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Stai per attivare la verifica dei dubbi del documento <", _interpolate(_named("documentName")), ">."])},
          "DESC_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La verifica umana è disponibile solo nei primi 10 minuti dopo il caricamento del documento."])},
          "DESC_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'elaborazione dell'analisi può richiedere del tempo, riceverai una notifica non appena l'analisi sarà completata."])},
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Verifica dei dubbi ti consente di richiedere una verifica manuale del documento da parte di un operatore umano in meno di quindici minuti. Attenzione, è disponibile solo nei 10 minuti successivi al caricamento del documento."])},
          "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica dei dubbi in corso"])},
          "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica dei dubbi disponibile"])},
          "IN_PROGRESS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica dei dubbi in corso."])},
          "IN_PROGRESS_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I controlli sottostanti verranno aggiornati non appena l'analisi manuale sarà completata. Per favore, attendi."])},
          "FAILED_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi della verifica dei dubbi del documento non disponibile."])},
          "FAILED_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore tecnico durante l'attivazione dell'analisi della verifica dei dubbi del documento."])},
          "SUCCESS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi della verifica dei dubbi del documento completata."])},
          "ANALYSIS_RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultato dell'analisi"])},
          "ANALYST_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggio dell'analista"])}
        },
        "CHECKS": {
          "EMISSION_COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coerenza nazionalità del documento"])},
          "EMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coerenza tipo documento / data di emissione"])},
          "EXPIRATION_DATE_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validità della data di scadenza"])},
          "VALIDITY_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento in corso di validità"])},
          "MRZ_FIELDS_SYNTAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllo MRZ"])},
          "MRZ_CHECKSUMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllo MRZ"])},
          "MRZ_EXPECTED_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllo MRZ"])},
          "MRZ_ALIGNEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllo MRZ"])},
          "MRZ_CLASSIFIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllo MRZ"])},
          "FIRST_NAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coerenza identità della persona / MRZ"])},
          "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coerenza identità della persona / MRZ"])},
          "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coerenza identità della persona / MRZ"])},
          "DOCUMENT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di documento coerente con la MRZ"])},
          "EXPIRATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di scadenza coerente con la MRZ"])},
          "OCR_EMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di emissione coerente con la MRZ"])},
          "PERSONAL_NUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di identificazione personale coerente con la MRZ"])},
          "PHOTO_CONFORMITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi della foto"])},
          "PHOTO_LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi della foto"])},
          "VISUAL_SECURITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conformità dei punti di sicurezza del documento"])},
          "PHOTOCOPY_DETECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rilevamento fotocopia"])},
          "SCREENSHOT_DETECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rilevamento screenshot"])},
          "OLD_DRIVING_LICENSE_DOCUMENT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero patente vecchio formato"])},
          "DRIVING_LICENSE_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllo validità patente"])},
          "DRIVING_LICENSE_DIGITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllo validità patente"])},
          "AGE_AT_CERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Età al momento del rilascio coerente"])},
          "MRZ_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllo MRZ"])},
          "MRZ_IDENTITY_COHERENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coerenza identità della persona"])},
          "PICTURE_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi della foto"])},
          "BARCODE_EXPECTED_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice a barre trovato"])},
          "BARCODE_FIELDS_SYNTAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati del codice a barre codificati correttamente"])},
          "BARCODE_SIGNATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma del codice a barre valida"])},
          "TYPE_RECOGNIZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tipo di documento è stato riconosciuto"])},
          "NOT_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di scadenza"])},
          "MRZ_VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La zona leggibile dalla macchina (MRZ) del documento è valida"])},
          "VALIDITY_PERIOD_MODEL_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il modello del periodo di validità corrisponde"])},
          "OCR_ANALYSIS_MRZ_FIRST_NAMES_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I nomi nel MRZ corrispondono all'analisi OCR"])},
          "OCR_ANALYSIS_MRZ_DOCUMENT_NUMBER_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero del documento nel MRZ corrisponde all'analisi OCR"])},
          "DOCUMENT_ASPECT_GENERAL_APPEARANCE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'aspetto generale del documento corrisponde alle aspettative"])},
          "SECURITY_ELEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementi di sicurezza presenti nel documento"])},
          "QUALITY_ABOVE_MINIMUM_THRESHOLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La qualità del documento è superiore alla soglia minima"])},
          "NOT_SPECIMEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il documento non è un campione"])},
          "FRONT_SIDE_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il lato anteriore del documento è stato trovato"])},
          "OCR_ANALYSIS_MRZ_BIRTH_NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il nome di nascita nel MRZ corrisponde all'analisi OCR"])},
          "OCR_ANALYSIS_MRZ_BIRTH_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data di nascita nel MRZ corrisponde all'analisi OCR"])},
          "OCR_ANALYSIS_FAITHFUL_FONT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'analisi OCR indica l'uso di un font fedele nel documento"])},
          "DOCUMENT_ASPECT_PICTURE_PRESENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È presente un'immagine sul documento"])},
          "OCR_ANALYSIS_EXPIRATION_DATE_READ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data di scadenza è stata letta con successo tramite analisi OCR"])},
          "OCR_ANALYSIS_ISSUANCE_DATE_READ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data di emissione è stata letta con successo tramite analisi OCR"])},
          "ISSUANCE_AND_EXPIRATION_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le date di emissione e di scadenza corrispondono"])},
          "NO_TAMPERING_SIGN_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun segno di manomissione è stato trovato sul documento"])},
          "NO_ANNOTATION_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna annotazione è stata trovata sul documento"])},
          "BACK_SIDE_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il retro del documento è stato trovato"])},
          "OCR_ANALYSIS_MRZ_EXPIRATION_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data di scadenza nel MRZ corrisponde all'analisi OCR"])},
          "OCR_ANALYSIS_MRZ_ISSUANCE_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data di emissione nel MRZ corrisponde all'analisi OCR"])},
          "OCR_ANALYSIS_MRZ_LAST_NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cognome nel MRZ corrisponde all'analisi OCR"])},
          "DOC_2D_VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento 2D valido"])},
          "DOC_2D_AND_DOCUMENT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento 2D coerente con le informazioni del documento"])},
          "WORKABLE_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cattura può essere elaborata per la verifica"])},
          "WORKABLE_ELEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'elemento può essere elaborato per la verifica"])},
          "STATUS_ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento accettato"])},
          "FRAUD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento fraudolento"])},
          "DOCUMENT_INSUFFICIENT_QUALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La qualità della cattura del documento non consente la revisione umana"])},
          "DOCUMENT_INCORRECTLY_FRAMED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il documento non è correttamente inquadrato nell'immagine"])},
          "DOCUMENT_SIDE_MISSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È visibile solo un lato del documento (sia il fronte che il retro sono necessari)"])},
          "DOCUMENT_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento scaduto"])},
          "DOCUMENT_UNSUPPORTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di documento non supportato"])},
          "DOCUMENT_COUNTRY_UNAUTHORIZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese di emissione del documento non supportato"])},
          "DOCUMENT_UNSUPPORTED_RECEIPT_ACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di documento non supportato"])},
          "BIOMETRY_INSUFFICIENT_QUALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La qualità dei dati biometrici non consente la revisione umana"])},
          "LIVE_CHECK_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore tecnico con il fornitore"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro motivo, vedere dettagli di seguito"])},
          "ID_PHOTO_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coerenza tra il selfie dell'utente e il documento"])},
          "PASSIVE_LIVENESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condizioni per la cattura del selfie rispettate"])},
          "IS_ALIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movimenti rilevati durante la cattura del selfie"])},
          "SEQUENCE_CONSISTENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movimenti coerenti durante l'esecuzione"])},
          "ORIGINAL_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento originale presentato"])},
          "PICTURE_NOT_TAMPERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto di identità non alterata"])},
          "DOCUMENT_NUMBER_VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validità del numero del documento"])},
          "MULTI_REQUESTS": {
            "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo documento non presenta avvisi relativi a richieste multiple"])},
            "KO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo documento è stato inviato più volte dagli utenti di Meelo"])},
            "WARNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo documento è stato inviato più volte dagli utenti di Meelo"])}
          },
          "MULTI_REQUESTS_INCONSISTENT": {
            "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo documento non presenta avvisi relativi a richieste multiple"])},
            "KO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo documento è stato inviato più volte dagli utenti di Meelo con dati diversi"])},
            "WARNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo documento è stato inviato più volte dagli utenti di Meelo con dati diversi"])}
          },
          "MULTI_REQUESTS_FRAUD": {
            "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo documento non presenta avvisi relativi a richieste multiple"])},
            "KO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo documento è stato inviato più volte dagli utenti di Meelo ed è stato rilevato come fraudolento"])},
            "WARNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo documento è stato inviato più volte dagli utenti di Meelo ed è stato rilevato come fraudolento"])}
          }
        },
        "KBIS": {
          "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di identificazione"])},
          "SIRET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET"])},
          "REGISTRATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di registrazione"])},
          "COMPANY_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome dell'azienda"])},
          "HEAD_QUARTER_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo sede legale"])},
          "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
          "ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
          "PUBLICATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di pubblicazione KBIS"])},
          "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identità del membro"])},
          "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di nascita"])},
          "IDENTIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificazione"])},
          "MAIN_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività principale"])},
          "MEMBERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membri"])},
          "NATURAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persone fisiche"])},
          "LEGAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persone giuridiche"])},
          "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizione"])},
          "BIRTH_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome di nascita"])},
          "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
          "FIRST_NAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
          "LEGAL_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma giuridica"])},
          "COMPANY_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione dell'azienda"])},
          "ESTABLISHMENT_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione dell'azienda"])}
        },
        "TAX_REPORT": {
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni aziendali"])},
          "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di identificazione"])},
          "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di chiusura dell'esercizio"])},
          "OPENING_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di apertura dell'esercizio"])},
          "CONFIDENTIALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento confidenziale"])},
          "FISCAL_PERIOD_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata del periodo fiscale"])},
          "RATIOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicatori finanziari"])},
          "MONTH": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " mese"]), _normalize([_interpolate(_named("n")), " mesi"])])}
        },
        "TAX_NOTICE": {
          "TAX_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo fiscale"])},
          "BANKID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificativo bancario"])},
          "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di conto"])},
          "REMAINING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo rimanente"])},
          "BIRTH_LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome di nascita"])},
          "NUMBER_PARTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di quote"])},
          "FAMILY_SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situazione familiare"])},
          "CHECKS": {
            "FOUND_2D_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento 2D identificato"])},
            "NAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coerenza dei nomi"])},
            "ASSESSMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coerenza della data di emissione"])},
            "REFERENCE_TAX_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coerenza del reddito di riferimento"])},
            "TAX_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coerenza del numero fiscale"])},
            "REFERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coerenza del riferimento"])},
            "REFERENCE_YEAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coerenza dell'anno di riferimento"])},
            "NUMBER_OF_PARTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coerenza del numero di quote"])},
            "NUMBER_OF_REGISTRANTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coerenza del numero di dichiaranti"])}
          }
        },
        "AUTHENTICITY": {
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticità"])},
          "DETAILED_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli dell'analisi"])},
          "NO_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun allarme è stato rilevato dall'analisi del documento."])},
          "FAILED_TASK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore, si prega di riavviare il controllo."])},
          "BINARY_MODIFICATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento modificato dalla sua creazione"])},
          "COHERENCE_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il documento non ha coerenza in termini di dati che raccogliamo."])},
          "AUTOGENERATED_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo documento proviene da un software generatore di documenti falsificati"])},
          "METADATA_MODIFICATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'analisi dei metadati rivela una modifica del documento"])},
          "PIXEL_MODIFICATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una parte dell'immagine è stata modificata"])},
          "SOFTWARE_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ultimo software è un software di fotoritocco (come Photoshop), che può essere utilizzato per ridimensionare (per renderlo autentico) o modificare l'immagine."])},
          "REPORT_FINOVOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report dettagliato Finovox (PDF)"])},
          "ANALYSIS_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le analisi di autenticità possono richiedere diversi minuti."])},
          "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante il processo di OCR. Si prega di controllare l'estensione del file (pdf, jpg, jpeg, png consentiti) prima di riprovare. In caso di problemi persistenti, contattare il supporto."])}
        },
        "PROPERTY_TAX": {
          "PAYMENT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni di pagamento"])},
          "LEGAL_DEBTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debitori legali"])},
          "PROPERTIES_AND_BUILDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proprietà e edifici"])},
          "PAYMENT_DEADLINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scadenza del pagamento"])},
          "REMAINING_AMOUNT_TO_PAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo residuo da pagare"])},
          "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto"])},
          "BANK_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificativo bancario"])},
          "CREDITOR_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del creditore"])},
          "TAX_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo dell'imposta"])},
          "IDENTIFIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificativo"])},
          "RIGHT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diritto"])},
          "DESIGNATION_AND_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designazione e indirizzo"])},
          "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
          "PAYMENT_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sul pagamento"])},
          "REMAINING_AMOUNT_TO_REPAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo rimanente da rimborsare"])},
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sulla notifica fiscale"])},
          "ADDRESSES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzi"])},
          "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrante"])},
          "TAX_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero fiscale"])},
          "REF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimento dell'avviso"])},
          "ROLE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di ruolo"])},
          "TAX_DEPARTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dipartimento fiscale"])},
          "OWNER_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero del proprietario"])},
          "TAX_COMMUNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comune fiscale"])},
          "SERVICE_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del servizio"])},
          "ISSUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di emissione"])},
          "ASSESSMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di valutazione"])}
        },
        "INCOME_TAX": {
          "INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sull'avviso di pagamento"])},
          "ISSUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edizione"])},
          "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
          "FIP_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero FIP"])},
          "SERVICE_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del servizio"])},
          "TAX_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di identificazione fiscale"])},
          "REFERENCE_TAX_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reddito fiscale di riferimento"])},
          "ASSESSMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valutazione"])},
          "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dichiarante"])},
          "ROLE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di ruolo"])},
          "TAX_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo fiscale"])},
          "PAYMENT_INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni di pagamento"])},
          "FAMILY_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli del nucleo familiare"])},
          "REMAINING_AMOUNT_TO_PAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo residuo da pagare"])},
          "BIRTH_LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome di nascita"])},
          "NUMBER_OF_PARTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di quote"])},
          "FAMILY_SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situazione familiare"])},
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatie over de belastingaanslag"])},
          "PAYMENT_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsinformatie"])},
          "MANDATE_UNIQUE_REFERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unieke mandaatreferentie"])},
          "REMAINING_AMOUNT_TO_REPAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resterend terug te betalen bedrag"])},
          "CREDITOR_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van de schuldeiser"])},
          "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekening"])},
          "BANK_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankidentificatie"])},
          "REF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanslagreferentie"])},
          "OWNER_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigenaarsnummer"])},
          "REFERENCE_YEAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referentiebelastingjaar"])}
        },
        "BANK_DETAILS": {
          "BANK_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice banca"])},
          "BRANCH_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice filiale"])},
          "ACCOUNT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di conto"])},
          "KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiave RIB"])},
          "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
          "BIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
          "BANK_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome della banca"])},
          "BANK_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo della banca"])},
          "IBAN_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validità dell'IBAN"])},
          "ACCOUNT_NUMBER_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validità del numero di conto"])},
          "DOCUMENT_ANALYZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento analizzato"])},
          "DIRECT_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domiciliazione"])},
          "CHECKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifiche"])},
          "TYPE_RECOGNIZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tipo di documento è stato riconosciuto"])},
          "QUALITY_ABOVE_MINIMUM_THRESHOLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La qualità del documento è al di sopra della soglia minima"])},
          "IBAN_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il codice IBAN è stato trovato"])},
          "IBAN_FORMAT_VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il formato dell’IBAN è valido"])},
          "IBAN_NOT_PUBLIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KO se questo IBAN è stato trovato su un sito web pubblico"])},
          "BIC_CODE_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il codice BIC è stato trovato"])}
        },
        "PAYSLIP": {
          "PAYSLIP_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sulla Busta Paga"])},
          "PAYMENT_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni di Pagamento"])},
          "HIRE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di Assunzione"])},
          "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
          "SIRET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET"])},
          "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
          "NET_PAYABLE_BEFORE_INCOME_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netto Pagabile Prima delle Imposte sul Reddito"])},
          "NET_PAYABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netto Pagabile Dopo le Imposte sul Reddito"])},
          "PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo di Pagamento"])}
        },
        "FACE_MATCHING": {
          "IN_PROGRESS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllo biometrico in corso."])},
          "IN_PROGRESS_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una volta completata l'analisi da parte del cliente, verranno visualizzati i controlli sottostanti. Si prega di attendere."])},
          "FAILED_OR_CANCELLED_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllo biometrico non disponibile."])},
          "FAILED_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore tecnico durante l'esecuzione del controllo biometrico."])},
          "CANCELLED_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utente ha annullato il controllo biometrico."])}
        }
      }
    },
    "NO_PERSON_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna persona trovata"])},
    "ERROR_MESSAGE_API": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un problema"])},
    "FAILED_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un problema durante l’analisi del documento. Per favore, riprova."])},
    "SOCIAL_SECURITY_CERTIFICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificato di Previdenza Sociale"])},
    "DISABLED_WORKER_CERTIFICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificato di Lavoratore Disabile"])},
    "PROOF_OF_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prova di Residenza"])},
    "FINALIZE_MY_DOCUMENT_SUBMISSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizzare l’invio del mio documento"])}
  },
  "SCORE": {
    "SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio"])},
    "WEB_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web"])},
    "SECTOR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settore"])},
    "COMPANY_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda"])},
    "DEFAULT_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predefinito"])},
    "REQUEST_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID della richiesta"])},
    "HELPER_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consigliamo di effettuare controlli aggiuntivi."])},
    "LOCAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio locale "])},
    "INTERNATIONAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio internazionale"])}
  },
  "FINANCIAL_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi finanziaria dell'azienda"])},
  "TAX_REPORTS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilancio fiscale"])},
    "FISCAL_PERIOD_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata dell'esercizio"])},
    "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di chiusura"])},
    "TURNOVER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatturato"])},
    "NET_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultato netto"])},
    "AUTO_FINANCE_CAPACITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacità di autofinanziamento"])},
    "NET_MARGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margine netto"])},
    "FB_TB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Struttura finanziaria"])},
    "FINANCIAL_LIQUIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidità finanziaria"])},
    "WORKING_CAPITAL_REQUIREMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requisito di capitale circolante"])},
    "WORKING_CAPITAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitale circolante"])},
    "FINANCIAL_SOLDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo finanziario"])},
    "DEBTS_SHORT_TERM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debiti a breve termine / bilancio"])},
    "DEBTS_MIDDLE_LONG_TERM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debiti a medio-lungo termine / bilancio"])},
    "NOT_AVAILABLE_NEED_UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilancio non disponibile. Puoi caricarlo per ottenere un'analisi."])}
  },
  "FINANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanza"])},
  "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disponibile"])},
  "UNAVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non disponibile"])},
  "NO_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non disponibile"])},
  "ENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entità"])},
  "ENTITIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entità"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mese"])},
  "OF_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["del mese"])},
  "EFFECTIVE_RECIPIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficiario effettivo"])},
  "EMPLOYEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dipendente"])},
  "REPRESENTATIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rappresentante"])},
  "WELCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benvenuto"])},
  "LAST_UPDATE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggi aggiornati il"])},
  "VERIFICATION": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esistenza"])},
    "TOOLTIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica dell'esistenza delle informazioni con il richiedente"])}
  },
  "RELATED": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collegamento sufficiente"])},
    "TOOLTIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica dell'associazione delle informazioni con il richiedente"])}
  },
  "FINISH": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi il dossier"])},
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi recuperare il riepilogo in formato PDF prima di chiudere la pagina?"])}
  },
  "FUNCTION": {
    "MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direttore generale"])}
  },
  "SOCIAL_NETWORKS": {
    "MAIL_LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail collegata"])},
    "PHONE_LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono collegato"])},
    "ADDRESS_LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo collegato"])},
    "MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collegato all'e-mail"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collegato al telefono"])},
    "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social network"])},
    "INFORMATIONS": {
      "AGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Età"])},
      "CITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città"])},
      "STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
      "GENDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genere"])},
      "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese"])},
      "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua"])}
    }
  },
  "TELEPHONE": {
    "LINETYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "TIMEZONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuso orario"])},
    "CARRIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operatore attuale"])},
    "ORIGINAL_CARRIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operatore originale"])},
    "GEOLOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geolocalizzazione"])},
    "OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proprietario"])},
    "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero"])}
  },
  "HISTORIC_PAGE": {
    "HISTORIC_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cronologia dei dossier"])},
    "B2B_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi aziendale"])},
    "B2C_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi del cliente"])},
    "TODAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggi"])},
    "LAST_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimi 7 giorni"])},
    "ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti"])}
  },
  "TABLE": {
    "PER_PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per pagina"])},
    "FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartella"])},
    "SUMMARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sintesi"])},
    "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successivo"])},
    "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
    "MARKET_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di mercato"])},
    "JOURNEY_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del percorso"])},
    "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "NUMBER_OF_TAX_NOTICES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di avvisi di pagamento"])},
    "NUMBER_OF_TAX_REPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di bilanci fiscali"])},
    "NUMBER_OF_ID_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di documenti di identità"])},
    "NUMBER_OF_PASSPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di passaporti"])},
    "NUMBER_OF_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di Kbis"])},
    "NUMBER_OF_BANK_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di RIB"])},
    "NUMBER_OF_DRIVING_LICENSES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di patenti di guida"])},
    "NUMBER_OF_RESIDENCE_PERMITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di permessi di soggiorno"])},
    "NUMBER_OF_PAYSLIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di buste paga"])},
    "HOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ora"])},
    "MAIL_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo e-mail"])},
    "POSTAL_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo postale"])},
    "TELEPHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
    "SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio"])},
    "SCORE_SMB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio Frode"])},
    "SCORE_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio Aziendale"])},
    "TAG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
    "STATUS_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato della cartella"])},
    "DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettaglio"])},
    "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
    "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "COMPANY_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ragione sociale"])},
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di identificazione"])},
    "OPERATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operatore"])},
    "SYNTHESIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sintesi"])},
    "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
    "FILE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di fascicolo"])},
    "DATE_AND_TIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data e ora"])},
    "DOCUMENT_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di documento"])},
    "DOCUMENT_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del documento"])}
  },
  "REGEX": {
    "NOT_NULL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non deve essere vuoto."])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero non è valido."])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'indirizzo e-mail non è valido."])},
    "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il formato deve essere GG/MM/AAAA."])},
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deve contenere 9 cifre."])},
    "NATIONAL_REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deve contenere 10 cifre."])},
    "INVALID_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data non valida."])},
    "LETTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deve contenere lettere."])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deve essere nel formato IBAN."])},
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deve contenere solo lettere"])},
    "LINKEDIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il link non è valido."])},
    "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deve contenere solo numeri"])},
    "UPPER_CASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deve contenere solo lettere maiuscole, numeri e trattini bassi."])},
    "FILE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Può contenere solo lettere, numeri e simboli _+%-"])}
  },
  "USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente"])},
  "FIRST_SEEN_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracce di utilizzo sul web"])},
  "NUMBER_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero identificativo azienda"])},
  "EMPLOYER_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica del datore di lavoro"])},
  "ACCOUNTANT_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica del commercialista"])},
  "PROCESS_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processo di lancio open banking"])},
  "INFORMATION_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sull'azienda"])},
  "SCORING_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio fiducia azienda"])},
  "EMAIL_DOMAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominio dell'indirizzo e-mail"])},
  "EMAIL_DOMAIN_CREATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di creazione del dominio dell'indirizzo e-mail"])},
  "EMAIL_DOMAIN_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispondenza con il dominio dell'indirizzo e-mail"])},
  "EMAIL_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispondenza con l'indirizzo e-mail"])},
  "OTHER_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possesso di altre aziende"])},
  "IS_RECENTLY_ACQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquistata di recente"])},
  "SCORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggi finanziari"])},
  "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "EMAIL_CORRELATION_WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispondenza tra dominio email e sito web"])},
  "CHECK_IBAN": {
    "CONTAINER": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica IBAN"])},
      "COMPANY_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome dell'azienda"])}
    },
    "RESULT": {
      "OK": {
        "IBAN_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'IBAN esiste"])},
        "OWNER_TYPE_CORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tipo di titolare è corretto"])},
        "REGISTRATION_NUMBER_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di registrazione corrisponde all'IBAN"])},
        "BIRTHDATE_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data di nascita corrisponde all'IBAN"])},
        "NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il nome corrisponde"])},
        "BANK_CONNECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connessione bancaria riuscita"])},
        "IBAN_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN valido"])},
        "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda conosciuta"])},
        "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda conosciuta"])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispondenza tra il nome e l'IBAN"])},
        "INVALID_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data valida"])},
        "SEPAMAIL_BANK_ELIGIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banca idonea a SEPAMAIL"])},
        "REGISTRATION_NUMBER_ENROLLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda registrata sulla piattaforma"])},
        "ANALYSIS_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'analisi della cronologia dei pagamenti consente di collegare l'azienda con l'IBAN e di determinare una frequenza di pagamento nella media"])},
        "ANALYSIS_COMMUNITY_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'analisi della cronologia dei pagamenti della comunità consente di collegare l'azienda ai dati bancari"])},
        "FACTORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dati bancari non appartengono a una società di factoring"])},
        "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sconosciuto"])},
        "BANK_ACCOUNT_LINKED_TO_COMPANY_FROM_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'analisi dello storico dei pagamenti consente di collegare l'azienda ai dati bancari"])},
        "COMPANY_OR_ESTABLISHMENT_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'azienda è attiva"])},
        "COUNTRY_COMPANY_COHERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'associazione tra il paese dell'azienda e il paese dei dati bancari è coerente"])},
        "FRAUDULENT_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dati bancari non sono stati identificati come fraudolenti"])},
        "OTHER_NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gli altri nomi e cognomi corrispondono"])},
        "PAYMENT_DEFAULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna cessazione di pagamento segnalata per questa società"])},
        "REGISTRATION_NUMBER_MATCHES_TVA_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di partita IVA corrisponde all'IBAN"])},
        "SUSPICIOUS_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dati bancari non sono stati identificati come potenzialmente fraudolenti"])},
        "TOO_MANY_COMPANIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È stata trovata una società per questo IBAN"])},
        "UNKNOWN_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda conosciuta"])},
        "VIRTUAL_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dati bancari non corrispondono a un IBAN virtuale"])},
        "BANK_ACCOUNT_LINKED_TO_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispondenza verificata: I dati bancari appartengono all’azienda verificata"])}
      },
      "KO": {
        "IBAN_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'IBAN non esiste"])},
        "OWNER_TYPE_CORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tipo di titolare è errato"])},
        "REGISTRATION_NUMBER_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di registrazione non corrisponde all'IBAN"])},
        "BIRTHDATE_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data di nascita non corrisponde all'IBAN"])},
        "NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il nome non corrisponde"])},
        "BANK_CONNECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connessione bancaria fallita"])},
        "IBAN_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN non valido"])},
        "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda sconosciuta"])},
        "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda sconosciuta"])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna corrispondenza tra il nome e l'IBAN"])},
        "INVALID_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data non valida"])},
        "SEPAMAIL_BANK_ELIGIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banca non idonea a SEPAMAIL"])},
        "REGISTRATION_NUMBER_ENROLLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda non registrata sulla piattaforma"])},
        "ANALYSIS_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'analisi dello storico dei pagamenti non consente di collegare l'azienda all'IBAN"])},
        "ANALYSIS_COMMUNITY_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'analisi della cronologia dei pagamenti della comunità non consente di collegare l'azienda e i dati bancari"])},
        "FACTORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dati bancari appartengono probabilmente a una società di factoring o vengono utilizzati a tal fine"])},
        "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sconosciuto"])},
        "BANK_ACCOUNT_LINKED_TO_COMPANY_FROM_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'analisi dello storico dei pagamenti non è stata completata perché i dati bancari sono sconosciuti"])},
        "COMPANY_OR_ESTABLISHMENT_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'azienda è chiusa"])},
        "COUNTRY_COMPANY_COHERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'associazione non è possibile, il paese dell'azienda e il paese dei dati bancari sono diversi"])},
        "FRAUDULENT_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dati bancari sono fraudolenti"])},
        "BANK_ACCOUNT_CLOSED_LESS_THAN_13_MONTHS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il conto bancario è stato chiuso meno di 13 mesi fa"])},
        "BANK_ACCOUNT_CLOSED_MORE_THAN_13_MONTHS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il conto bancario è stato chiuso più di 13 mesi fa"])},
        "OTHER_NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gli altri nomi e cognomi non corrispondono"])},
        "OWNER_TYPE_CORRECT_WAITING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica in corso della corrispondenza tra i dati bancari e l'azienda"])},
        "PAYMENT_DEFAULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cessazione di pagamento segnalata per questa società"])},
        "REGISTRATION_NUMBER_MATCHES_TVA_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di partita IVA non corrisponde all'IBAN"])},
        "SEPAMAIL_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEPAMAIL DIAMOND non è in grado di effettuare la verifica"])},
        "SUSPICIOUS_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dati bancari sono potenzialmente fraudolenti"])},
        "TECHNICAL_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore tecnico"])},
        "TOO_MANY_COMPANIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troppe aziende trovate per questo IBAN"])},
        "UNKNOWN_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda sconosciuta"])},
        "VIRTUAL_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dati bancari corrispondono a un IBAN virtuale"])},
        "BANK_ACCOUNT_LINKED_TO_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispondenza non valida: I dati bancari non appartengono all’azienda verificata"])}
      },
      "WARNING": {
        "IBAN_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile verificare l'IBAN"])},
        "OWNER_TYPE_CORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile verificare il tipo di titolare"])},
        "REGISTRATION_NUMBER_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile verificare la corrispondenza tra il numero di registrazione e l'IBAN"])},
        "BIRTHDATE_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile verificare la data di nascita"])},
        "NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il nome non corrisponde completamente"])},
        "BANK_CONNECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connessione bancaria fallita"])},
        "IBAN_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN disattivato (esempio: conto chiuso)"])},
        "ICQX_ORDERING_PARTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICQX del mittente non trasmesso (ICQX è l'identificativo unico di un creditore persona giuridica nella rete SEPAmail)"])},
        "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda non trovata"])},
        "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda non trovata"])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispondenza tra nome e IBAN non verificata"])},
        "INVALID_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data non verificata"])},
        "SEPAMAIL_BANK_ELIGIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banca non idonea per SEPAMAIL"])},
        "REGISTRATION_NUMBER_ENROLLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda non registrata sulla piattaforma"])},
        "ANALYSIS_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'analisi dello storico dei pagamenti non consente di collegare l'azienda all'IBAN e/o rileva una bassa frequenza di pagamenti"])},
        "ANALYSIS_COMMUNITY_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'analisi della cronologia dei pagamenti della comunità non è riuscita perché i dati bancari sono sconosciuti"])},
        "FACTORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dati bancari potrebbero appartenere a una società di factoring o essere utilizzati per questo scopo"])},
        "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sconosciuto"])},
        "BANK_ACCOUNT_LINKED_TO_COMPANY_FROM_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'analisi dello storico dei pagamenti evidenzia che i dati bancari sono conosciuti ma associati a un'altra azienda, oppure che lo storico dei pagamenti è troppo esiguo per confermare l'associazione tra l'azienda e i dati bancari"])},
        "COMPANY_OR_ESTABLISHMENT_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo stabilimento è chiuso, ma la sua società madre è attiva"])},
        "COUNTRY_COMPANY_COHERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'associazione non è possibile, il paese dell'azienda e il paese dei dati bancari sono diversi"])},
        "FRAUDULENT_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dati bancari sono fraudolenti"])},
        "BANK_ACCOUNT_CLOSED_LESS_THAN_13_MONTHS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il conto bancario è stato chiuso meno di 13 mesi fa"])},
        "BANK_ACCOUNT_CLOSED_MORE_THAN_13_MONTHS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il conto bancario è stato chiuso più di 13 mesi fa"])},
        "OTHER_NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gli altri nomi e cognomi non corrispondono completamente"])},
        "OWNER_TYPE_CORRECT_WAITING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica in corso della corrispondenza tra i dati bancari e l'azienda"])},
        "PAYMENT_DEFAULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cessazione di pagamento segnalata per questa società"])},
        "REGISTRATION_NUMBER_MATCHES_TVA_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica impossibile tra il numero di partita IVA e l'IBAN"])},
        "SEPAMAIL_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEPAMAIL DIAMOND non è in grado di effettuare la verifica"])},
        "SUSPICIOUS_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dati bancari sono potenzialmente fraudolenti"])},
        "TECHNICAL_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore tecnico"])},
        "TOO_MANY_COMPANIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troppe aziende trovate per questo IBAN"])},
        "UNKNOWN_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda sconosciuta"])},
        "VIRTUAL_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dati bancari corrispondono a un IBAN virtuale"])},
        "BANK_ACCOUNT_LINKED_TO_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La corrispondenza tra i dati bancari e l’azienda non è verificabile o le informazioni non sono idonee per il controllo"])}
      },
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi dell'IBAN"])},
      "VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifiche"])},
      "BANK_CONNECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connessione con la banca"])},
      "typeTitulaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo titolare"])},
      "dateNaissance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di nascita"])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "autreNom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro nome"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La richiesta di verifica non è stata eseguita a causa di un errore tecnico"])},
      "IBAN_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'IBAN esiste"])},
      "IBAN_NOT_EXISTS_ANYMORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'IBAN non esiste più e il conto è stato chiuso da meno di 13 mesi"])},
      "IBAN_NOT_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'IBAN non esiste o il conto è stato chiuso da più di 13 mesi"])},
      "IBAN_CAN_BE_VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'IBAN non può essere verificato: l'utente ha richiesto che il suo conto non sia verificabile con DIAMOND o il conto è in valuta straniera o il conto non è compatibile con operazioni di addebiti e bonifici, ed è quindi fuori dal campo di applicazione, ..."])},
      "HOLDER_TYPE_CORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tipo di titolare inserito è corretto"])},
      "HOLDER_TYPE_INCORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tipo di titolare inserito è incorretto"])},
      "SIREN_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di identificazione inserito corrisponde all'IBAN verificato"])},
      "SIREN_NOT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di identificazione inserito non corrisponde all'IBAN verificato"])},
      "SIREN_CANNOT_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La banca non è in grado di verificare il numero di identificazione (ad esempio perché non lo conosce)"])},
      "BIRTHDATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data di nascita inserita corrisponde all'IBAN verificato"])},
      "BIRTHDATE_NOT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data di nascita inserita non corrisponde all'IBAN verificato"])},
      "BIRTHDATE_CANNOT_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La banca non è in grado di verificare la data di nascita inserita (ad esempio perché non la conosce)"])},
      "OWNER_TYPE_CORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tipo di proprietario è corretto"])},
      "REGISTRATION_NUMBER_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di registrazione corrisponde all'IBAN"])},
      "BIRTHDATE_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data di nascita corrisponde all'IBAN"])},
      "NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il nome corrisponde"])},
      "IBAN_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN non valido"])},
      "ICQX_ORDERING_PARTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICQX dell'ordinante"])},
      "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIREN"])},
      "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di registrazione"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "INVALID_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data non valida"])},
      "SEPAMAIL_BANK_ELIGIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banca idonea a SEPAMAIL"])},
      "REGISTRATION_NUMBER_ENROLLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di registrazione iscritto"])},
      "ANALYSIS_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi della cronologia dei pagamenti"])},
      "ANALYSIS_COMMUNITY_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi della cronologia dei pagamenti comunitari"])},
      "FACTORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factoring"])},
      "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sconosciuto"])},
      "REASON_CODE": {
        "IncorrectAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN non valido"])},
        "UnrecognisedInitiatingParty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICQX del Donneur d’Ordre"])},
        "InvalidIdentificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di identificazione"])},
        "MissingName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "InvalidData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati non validi"])},
        "BICInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banca non idonea al controllo SEPAMAIL"])}
      }
    },
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembra che la banca interrogata stia riscontrando un problema di connessione con SepaMail. Grazie per la vostra comprensione."])},
    "MEELO_RECOMMENDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raccomandazione Meelo"])},
    "RECOMMENDATION": {
      "DECISION": {
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conforme"])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione"])},
        "risky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allerta"])}
      },
      "MESSAGE": {
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'analisi dell'IBAN non contiene avvisi."])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'analisi dell'IBAN contiene alcuni avvisi."])},
        "risky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'analisi dell'IBAN contiene almeno un avviso importante."])}
      }
    }
  },
  "INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni"])},
  "SYNTHESIS": {
    "FOLDER_STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato della cartella"])},
    "ANALYST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analista"])},
    "LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collegato"])},
    "VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificato"])},
    "JOURNEY_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID univoco"])},
    "PHONE_AND_SOCIAL_NETWORKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono e social network"])},
    "LINKED_TO_MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collegato all'indirizzo email"])},
    "LINKED_TO_PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collegato al telefono"])},
    "BUSINESS_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di identificazione"])},
    "VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica"])}
  },
  "WARNING_LIST": {
    "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'azienda"])},
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di identificazione"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun avviso durante la nostra analisi"])},
    "MESSAGE_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione, i seguenti parametri sono stati identificati in precedenti casi di frode"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista di avvertimento Meelo"])},
    "MESSAGE_ALERT_SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione, il numero di registrazione inserito è stato identificato in un precedente caso di frode. Per maggiori informazioni, contatta il supporto al +33 3 92 25 27 30."])},
    "MESSAGE_NO_ALERT_SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun avviso durante la nostra analisi."])},
    "MESSAGE_NO_ALERT_ACCOUNTANT_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non registrato nella Lista di Avviso, la invitiamo a effettuare controlli aggiuntivi in caso di dubbio."])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di telefono"])},
    "EMAIL_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo email"])},
    "MESSAGE_ALERT_REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di registrazione è stato identificato in un caso di frode precedente."])},
    "MESSAGE_ALERT_PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di telefono è stato identificato in un caso di frode precedente."])},
    "MESSAGE_ALERT_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'indirizzo email è stato identificato in un caso di frode precedente."])},
    "NOT_VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non verificato."])}
  },
  "SELECT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona le analisi che desideri avviare"])},
  "RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultati"])},
  "PHONE_INDICATION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un indicatore e inserisci un numero senza spazi o caratteri tra i numeri."])},
  "BIRTHDATE_INDICATION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altamente consigliato per visualizzare l'ecosistema del cliente."])},
  "EXAMPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esempio"])},
  "FROM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da"])},
  "DOMAIN_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sul dominio"])},
  "DOMAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominio"])},
  "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dichiarante"])},
  "REGISTRAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosting provider"])},
  "ADDITIONAL_CONTROLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlli aggiuntivi"])},
  "COMPANY_DOMICILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sede dell'azienda"])},
  "EMAIL_DOMAIN_CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di creazione del dominio dell'indirizzo email"])},
  "WEBSITE_DOMAIN_CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di creazione del dominio del sito web"])},
  "MORE_THAN_THREE_YEARS_WITHOUT_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo di inattività superiore a 3 anni"])},
  "LOCATION_AT_RISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizione a rischio"])},
  "OPERATOR_AT_RISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operatore a rischio"])},
  "INITIAL_RISKY_OPERATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operatore iniziale a rischio"])},
  "NO_WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per ulteriori informazioni, avvia i punteggi"])},
  "HAS_OTHER_COMPANIES_IN_COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altre aziende in procedura collettiva"])},
  "HAS_OTHER_COMPANIES_IN_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altre aziende radiate"])},
  "HAS_OTHER_COMPANIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altra azienda"])},
  "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "SUPPORT_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problemi? Non esitare a contattarci"])},
  "SIDEBAR": {
    "KIT_B2C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi del cliente privato"])},
    "KIT_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi dell'azienda"])},
    "KIT_OB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking"])},
    "KIT_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi del documento"])},
    "HISTORICAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cronologia"])},
    "MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il mio account"])},
    "BATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch"])},
    "HELP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiuto"])},
    "KIT_RECOVERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recupero"])},
    "MEELO_KIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meelo Kit"])},
    "BACKOFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ufficio di retro"])},
    "PORTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portale"])},
    "MEELO_ACADEMY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accademia Meelo"])},
    "DOCUMENT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi del documento/i"])},
    "USERS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei utenti"])},
      "PROFILE_AND_ACCESS_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione profili e accesso"])},
      "USER_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione degli utenti"])},
      "GROUP_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione dei gruppi"])},
      "LOCATION_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione dei luoghi"])}
    },
    "LIST_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione delle liste"])},
    "HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "MONITORING": {
      "MY_USAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei consumi"])},
      "COMPANY_USAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumi aziendali"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoraggio dei consumi"])}
    },
    "RULES_ENGINE": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motore di regole"])},
      "COMPANY_RULES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regole dell'azienda"])},
      "SETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insiemi di regole"])}
    },
    "COMPANY": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La mia azienda"])},
      "MANAGE_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione delle liste"])}
    }
  },
  "BREADCRUMBS": {
    "COMPANY_INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sull'azienda"])},
    "APPLICANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedente"])},
    "DECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decisione"])},
    "JUSTIFICATORIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giustificazioni"])},
    "SCORE_CHOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezione del punteggio"])},
    "RECEIVER_CHOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezione del destinatario"])},
    "UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "BATCH_RUN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esecuzione del batch"])}
  },
  "PROJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progetto"])},
  "PRODUCT": {
    "VEHICLES_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore IVA inclusa dei veicoli"])},
    "CONTRIBUTION_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo del contributo"])}
  },
  "CGI": {
    "DECISION": {
      "SUCCESS": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo passo!"])},
        "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passa alla raccolta dei documenti giustificativi"])},
        "BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva e Continua"])}
      },
      "FAILED": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi completata!"])},
        "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parere sfavorevole"])},
        "BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concludere"])}
      }
    },
    "DOCUMENTS": {
      "BLOCK": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica documenti giustificativi"])}
      },
      "ID_CARD": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento d'identità"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faccia anteriore e retro del tuo documento di identità"])}
      },
      "BANK_DETAILS": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estratto conto bancario"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estratto conto bancario del tuo conto principale"])}
      },
      "KBIS": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificato di Registrazione Aziendale"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificato di Registrazione Aziendale rilasciato entro gli ultimi 3 mesi"])}
      },
      "TAX_REPORT": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dichiarazione fiscale"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima dichiarazione fiscale con almeno 2 anni di storia"])}
      }
    },
    "CONGRATULATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ben fatto"])},
    "END_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai completato la compilazione della cartella sul portale Meelo."])}
  },
  "TITLE_ACTION_DOCUMENT_BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modalità di acquisizione"])},
  "ONLY_PDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo PDF"])},
  "MULTI_REQUESTS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-richiesta"])},
    "DESCRIPTION_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nell'ultimo mese, è stato creato uno o più fascicoli da"])},
    "DESCRIPTION_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["con informazioni simili e/o identiche."])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun fascicolo simile o identico inserito negli ultimi 30 giorni."])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di record con la stessa email"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di record con lo stesso numero di telefono"])},
    "REGISTRATIONNUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di record con lo stesso numero di registrazione"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di fascicoli con lo stesso IBAN"])}
  },
  "RECOVERY": {
    "CLIENT_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID cliente"])},
    "REQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta"])},
    "FINANCIAL_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi finanziaria"])},
    "ANALYSIS_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di esecuzione dell'analisi"])},
    "BANK_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni bancarie"])},
    "CUSTOMER_RELIABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affidabilità del cliente"])},
    "REJECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifiuto"])},
    "ON_AVERAGE_PER_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in media al mese"])},
    "INTERVENTION_FEES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tariffe di intervento"])},
    "THIRD_PARTY_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avviso a terzi"])},
    "GAMBLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giochi d'azzardo"])},
    "FINANCIAL_SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situazione finanziaria"])},
    "AVERAGE_DISPOSABLE_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reddito disponibile medio"])},
    "LOWEST_DISPOSABLE_INCOME_OVER_THE_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reddito disponibile più basso nel periodo"])},
    "LOWEST_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reddito più basso"])},
    "LACK_OF_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assenza di reddito"])},
    "AVERAGE_MONTHLY_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reddito medio mensile"])},
    "FINANCIAL_PRESSURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pressione finanziaria"])},
    "CREDITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crediti"])},
    "DEBT_COLLECTION_AGENCIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenzie di recupero crediti"])},
    "RISK_LEVEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livello di rischio"])},
    "LOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basso"])},
    "MODERATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderato"])},
    "RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rischioso"])},
    "LACK_OF_INCOME_SOURCES_DETECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È stata rilevata una o più fonti di reddito"])},
    "LACK_OF_INCOME_SOURCES_NOT_DETECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun reddito rilevato"])}
  },
  "BATCH": {
    "CLICK_ON_SCORES_BELOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fare clic su uno o più punteggi qui sotto per scegliere i punteggi da eseguire tramite il batch."])},
    "MANUAL_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istruzioni"])},
    "MANUAL_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per un utilizzo ottimale del batch, ti consigliamo di scaricare il modello proposto sopra. Sarà composto dalla struttura ideale, adatta a tutti i punteggi selezionati in precedenza. Successivamente, potrai compilare il file Excel con i tuoi dati e caricarlo più in basso nella pagina."])},
    "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullamento del Batch riuscito"])},
    "DOWNLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica il risultato del batch"])},
    "STARTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvio del Batch in corso..."])},
    "RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai appena ricevuto una email con il risultato del batch"])},
    "START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvia il batch"])},
    "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla il batch"])},
    "USAGE_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sull'uso"])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
    "DOWNLOAD_TEMPLATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica il modello"])},
    "LIMIT_PER_DAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["righe / file"])},
    "FILE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del file di output desiderato"])},
    "FILE_TO_EXECUTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File da eseguire"])},
    "SCORE": {
      "REQUEST_ID": {
        "WEB_SCORE_B2C_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio Frode di Identità"])},
        "SMB_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio Frode Identità Professionale"])},
        "WEB_SCORE_B2B_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio di Affidabilità dell'Impresa"])},
        "ENERGY_SCORE_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio Valutazione del Rischio Settore Energia"])},
        "E_REPUTATION_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio E-Reputazione"])},
        "E_PAYMENT_RISK_SCORE_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio di Rischio"])},
        "ALTARES_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio Altares"])},
        "ELLISPHERE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio Ellisphere"])},
        "CREDIT_SAFE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio CreditSafe"])},
        "COMPANY_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica IBAN Aziendale"])},
        "PERSONAL_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica IBAN Personale"])}
      },
      "DESCRIPTION": {
        "WEB_SCORE_B2C_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il punteggio di frode d'identità è un modello che consente di valutare il rischio di frode d'identità di un cliente. Si basa sulla valutazione di diversi criteri: nome, cognome, e-mail, telefono, nonché indirizzo postale o data di nascita."])},
        "SMB_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il punteggio di frode d'identità professionale è un modello che consente di valutare il rischio di frode d'identità di un cliente professionale (nel contesto B2B). Si basa sulla valutazione di diversi criteri: nome, cognome, e-mail, telefono, nonché indirizzo postale o data di nascita."])},
        "WEB_SCORE_B2B_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il servizio di scoring 'Score Confiance Entreprise' è uno strumento di valutazione dell'azienda e del legame tra il richiedente (persona fisica) e l'azienda (persona giuridica)."])},
        "ENERGY_SCORE_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il 'Punteggio Rischio Energia' è un modello progettato appositamente per il settore dell'energia che consente di valutare il rischio di insolvenza del cliente. Si basa sull'valutazione di diversi criteri: l'operatore telefonico / la banca / i dati IRIS sull'indirizzo postale / l'importo mensile (esatto o stimato)."])},
        "E_REPUTATION_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il punteggio E-Reputation è un modello progettato per valutare la soddisfazione e la fiducia dei consumatori verso l'azienda basandosi sui diversi pareri che hanno lasciato."])},
        "E_PAYMENT_RISK_SCORE_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il punteggio di rischio è un modello progettato per valutare la solvibilità di una persona basandosi sulle sue informazioni personali e sulle sue informazioni di pagamento."])},
        "ALTARES_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il punteggio Altares è un modello progettato per valutare il rischio di solvibilità di un'azienda basandosi sui risultati forniti dalla società di valutazioni finanziarie Altares."])},
        "ELLISPHERE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il punteggio Ellisphere è un modello progettato per valutare il rischio di solvibilità di un'azienda basandosi sui risultati forniti dalla società di valutazioni finanziarie Ellisphere."])},
        "CREDIT_SAFE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il punteggio CreditSafe è un modello progettato per valutare il rischio di solvibilità di un'azienda basandosi sui risultati forniti dalla società di valutazioni finanziarie CreditSafe."])},
        "COMPANY_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il Check IBAN Aziendale è un modello progettato per analizzare la coerenza e la connessione tra un IBAN e un'azienda."])},
        "PERSONAL_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il Check IBAN Personale è un modello progettato per analizzare la coerenza e la connessione tra un IBAN e una persona fisica."])}
      }
    }
  },
  "FILE": {
    "CHOOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegliere un file"])}
  },
  "IMPORTANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importante"])},
  "RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rischioso"])},
  "BACKOFFICE": {
    "GROUPS": {
      "COMPANY_GROUPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione dei gruppi"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
      "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
      "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica gruppo"])},
      "CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea gruppo"])},
      "DELETE_WARN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo gruppo è utilizzato dagli utenti, sei sicuro di volerlo eliminare?"])}
    },
    "PLACES": {
      "COMPANY_PLACES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione dei posti"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
      "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
      "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica posto"])},
      "CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea posto"])},
      "DELETE_WARN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo posto è utilizzato dagli utenti, sei sicuro di volerlo eliminare?"])}
    },
    "USERS": {
      "SHORTCUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le scorciatoie"])},
      "COMPANY_USERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti dell'azienda"])},
      "GROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo"])},
      "PLACE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luogo"])},
      "PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilo"])},
      "MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
      "USER_MODIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica dell'utente"])},
      "PROFILE_ASSIGNMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnazioni di Profili"])},
      "USER_DELETION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellazione utente"])},
      "DELETE": {
        "MESSAGE_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stai per eliminare l'utente"])},
        "OF_THE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dell'azienda"])},
        "MESSAGE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utente non potrà più accedere al suo account."])},
        "ACTION_IRREVERSIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quest'azione è irreversibile."])},
        "QUESTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler eliminare questo utente?"])}
      },
      "USER_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilo utente"])},
      "TECHNICAL_ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni tecniche"])},
      "SUPERVISOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervisore"])},
      "USER_ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiunta utente"])},
      "ACTIVE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto attivo"])},
      "UPDATE_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo stato dell'account è stato modificato con successo."])},
      "RESET_PASSWORD": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimpostazione di una password"])},
        "MESSAGE_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stai per inviare un'e-mail di reimpostazione della password all'utente"])},
        "MESSAGE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all'indirizzo e-mail"])},
        "SENDING_MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail di reimpostazione della password inviata"])}
      },
      "RESET_API_KEY": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimpostazione di una chiave API"])},
        "CONFIRM_RESET_USER": {
          "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stai per inviare un'e-mail di reimpostazione della chiave API per l'utente"])},
          "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all'indirizzo e-mail"])},
          "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importante : il collegamento per recuperare la chiave API è valido solo per 30 minuti."])}
        },
        "CONFIRM_RESET_US": {
          "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stai per reimpostare la tua chiave API."])},
          "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per motivi di sicurezza, il valore della tua nuova chiave può essere recuperato solo una volta."])},
          "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa nuova chiave verrà copiata direttamente negli appunti digitali."])},
          "FOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti consigliamo di attaccarlo immediatamente in un luogo sicuro in modo da poterlo riutilizzare in seguito."])}
        },
        "SENDING_MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail di reimpostazione della chiave API inviata"])},
        "NEW_API_KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua nuova chiave API è stata copiata negli appunti"])}
      }
    },
    "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compagnia"])},
    "FUNCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funzione"])},
    "ADVANCED_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente avanzato"])},
    "ADVANCED_USER_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Può creare altri utenti avanzati e/o base e accedere a determinate impostazioni."])},
    "PROFILE_AND_ACCESS": {
      "COMPANY_PROFILES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profili aziendali"])},
      "PERMISSIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizzazioni"])},
      "MESSAGE_PERMISSIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un profilo sopra per visualizzare l'elenco delle autorizzazioni associate."])},
      "CREATED_BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creato da"])},
      "KIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kit"])},
      "MODULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modulo"])},
      "RIGHTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diritti"])},
      "ADDING_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiunta di un profilo"])},
      "PROFILE_MODIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica del profilo"])},
      "SAVE_PERMISSION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono state apportate modifiche, non dimenticare di salvarle utilizzando il pulsante sopra."])},
      "DESCRIPTION_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione del profilo"])},
      "NO_PROFILE_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun profilo trovato"])}
    },
    "PERMISSIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizzazioni"])},
    "MONITORING": {
      "DOWNLOAD_BALANCE_SHEET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica il bilancio"])},
      "TOTAL_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo totale"])},
      "MONTHLY_EVOLUTION_CURVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curva dell'andamento mensile"])},
      "TODAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggi"])},
      "THIS_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa settimana"])},
      "LAST_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La settimana scorsa"])},
      "THIS_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo mese"])},
      "LAST_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il mese scorso"])},
      "GENERAL_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo generale"])},
      "COMPANY_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo per l'azienda"])},
      "DATA_ON_THE_WAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I vostri dati sono in viaggio..."])},
      "CONSUMPTION": {
        "OCR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi del documento di identità"])},
        "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi del documento"])},
        "CHECK_VALUE_COMPANY_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica dell'IBAN B2B"])},
        "CHECK_VALUE_PERSONAL_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica dell'IBAN B2C"])},
        "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi dell'azienda"])},
        "SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi LCB-FT"])},
        "GET_LINKEDIN_PROFILE_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arricchimento LinkedIn"])},
        "OPENBANKING_MEELO_OPENBANKING_PERSONAL_SCORING_MODEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking B2C"])},
        "OPENBANKING_MEELO_OPENBANKING_PROFESSIONAL_SCORING_MODEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking B2B"])},
        "SCORING_ELLISPHERE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio Ellisphère"])},
        "SCORING_CREDIT_SAFE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio CréditSafe"])},
        "SCORING_WEB_SCORE_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio di Fiducia Aziendale"])},
        "SCORING_WEB_SCORE_B2C_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio Identità Individuale"])},
        "SCORING_E_REPUTATION_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio E-Reputazione"])},
        "SCORING_ALTARES_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio Altares"])},
        "SCORING_SMB_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio Identità Professionale"])},
        "SELECTED_USERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo degli utenti selezionati"])},
        "OF_THE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo dell'azienda"])},
        "OF_MY_TEAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo del mio team"])},
        "MY_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il mio consumo"])}
      },
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun dato trovato. Eseguire un'analisi iniziale per poter consultare i consumi."])}
    },
    "RULES_ENGINE": {
      "RULES": {
        "FOR_API_IMPLEMENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per l'implementazione dell'API"])},
        "EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui troverai l'elenco delle regole approvate per la tua azienda."])},
        "RULES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regole"])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "CONTAINED_IN_A_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenuta in un insieme"])},
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
        "GENERIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generica"])},
        "SPECIFIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
        "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azione"])},
        "MODIFY_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica regola"])},
        "RULE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome della regola"])},
        "RULE_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione della regola"])},
        "VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore"])},
        "DEFAULT_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore predefinito"])},
        "PARAMETERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametri"])},
        "ARGUMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argomenti"])},
        "EDIT_PARAMETER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica parametro"])},
        "PARAMETER_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del parametro"])},
        "PARAMETER_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione del parametro"])},
        "FLOAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimale"])},
        "INTEGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intero"])},
        "STRING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testo"])},
        "BOOLEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booleano"])},
        "LIST_STRING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco di testi"])},
        "RULE_PRESENT_IN_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regola presente nei seguenti insiemi"])},
        "RULE_V1_NOT_EDITABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa regola è una regola V1 e non è modificabile. Se desideri modificarla, contatta Meelo."])}
      },
      "SETS": {
        "SETS_RULES_EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per visualizzare le regole di un insieme, seleziona la riga corrispondente nell'area della tabella a sinistra."])},
        "NO_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ci sono insiemi di regole per la tua azienda. Contatta Meelo per crearne uno."])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "SETS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco degli insiemi"])},
        "SETS_RULES_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco delle regole dell'insieme"])},
        "EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui troverai l'elenco degli insiemi di regole creati per la tua azienda."])},
        "ADD_OR_DELETE_RULE_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per qualsiasi modifica relativa all'aggiunta o alla rimozione di una regola in un insieme, contatta Meelo."])}
      }
    },
    "MY_COMPANY": {
      "USEFUL_CONTACTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatti utili"])},
      "KITS_AND_MODULES_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kit e moduli disponibili"])},
      "ADMINISTRATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amministratore"])},
      "SUPPORT_MEELO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supporto a Meelo"])}
    }
  },
  "RESEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerca"])},
  "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
  "PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina"])},
  "ON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["su"])},
  "RESULTS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultati trovati"])},
  "NUMBER_OF_ITEMS_PER_PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di elementi per pagina"])},
  "SUMMARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riassunto"])},
  "DATA_CONTACTABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contattabilità dei dati"])},
  "AND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e"])},
  "UNREACHABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non raggiungibile"])},
  "LOCALE": {
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tedesco"])},
    "EN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglese"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spagnolo"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francese"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiano"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olandese"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portoghese"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgio"])}
  },
  "ADVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recensione"])},
  "E_REPUTATION": {
    "AVERAGE_GRADE_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media voti non trovata"])},
    "LIST_TEN_LAST_REVIEWS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco delle ultime 10 recensioni"])},
    "SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio di E-Reputation di Meelo"])},
    "NEGATIVE_REVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recensione negativa"])},
    "REVIEW_COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento alla recensione"])}
  },
  "NO_COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun commento"])},
  "PRINT": {
    "FOLDER_INFO": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sulla cartella"])},
      "CREATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di creazione"])},
      "FOLDER_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato della cartella"])},
      "ANALYST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analista"])},
      "UNIQ_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID univoco"])}
    },
    "APPLICANT_OB": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedente"])},
      "OB_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi dell'OpenBanking"])},
      "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
      "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "BIRTH_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di Nascita"])},
      "BIRTH_PLACE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luogo di Nascita"])},
      "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo Email"])},
      "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono Cellulare"])},
      "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo Postale"])},
      "BANK_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banca"])},
      "HOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolare"])},
      "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del Conto"])},
      "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "MAIN_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto Principale"])},
      "MONTH_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stipendio Mensile Medio"])},
      "MONTH_RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affitto Mensile Medio"])},
      "MONTH_END_RATIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporto Spese su Entrate"])},
      "MONTH_END_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilancio Mensile"])},
      "BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo Attuale"])},
      "TOP_BANKING_SUPERVISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervisione Bancaria"])},
      "TOP_DEFERRED_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuazione Addebito Differito"])},
      "GLOBAL_TRANSACTIONS_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di Transazioni"])}
    },
    "DOCUMENT_ANALYSIS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi dei Documenti"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
      "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
      "COMPLIANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conformità"])},
      "DOCUMENT_CONTROL": {
        "ID_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carta d'identità"])},
        "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carta d'identità"])},
        "DRIVING_LICENSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patente di guida"])},
        "PASSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passaporto"])},
        "RESIDENCE_PERMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permesso di soggiorno"])},
        "KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS - Azienda o Situazione"])},
        "INDIVIDUAL_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS Avviso di situazione"])},
        "COMPANY_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estratto di KBIS"])},
        "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticità - Analisi di non alterazione digitale"])},
        "TAX_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dichiarazione dei redditi"])},
        "TAX_REPORT_S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dichiarazione dei redditi semplificata"])},
        "TAX_REPORT_C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dichiarazione dei redditi complessa"])},
        "TAX_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avviso fiscale - Reddito o Proprietà"])},
        "INCOME_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta sul reddito"])},
        "PROPERTY_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta immobiliare"])},
        "BANK_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli bancari"])},
        "PAYSLIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busta paga"])},
        "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sconosciuto"])},
        "FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllo biometrico"])},
        "BANK_STATEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estratto conto"])}
      }
    },
    "SCORE_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli del Punteggio"])},
    "CHECK_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi IBAN"])},
    "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrasto al Riciclaggio di Denaro e al Finanziamento del Terrorismo"])},
    "OB_ANALYSIS_DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi Dettagliata dell'OpenBanking"])},
    "ANALYSIS_B2B": {
      "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
      "COMPANY_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sull'azienda"])},
      "LEGAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni legali"])},
      "CLIENT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sul cliente"])},
      "CORPORATE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denominazione sociale"])},
      "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di registrazione"])},
      "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
      "WORKFORCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forza lavoro"])},
      "CREATED_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di creazione"])},
      "VAT_REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di partita IVA"])},
      "ACTIVITY_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice attività"])},
      "LEGAL_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma legale"])},
      "CAPITAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitale"])},
      "ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
      "FIELD_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambito di attività"])},
      "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di chiusura"])},
      "DOMICILIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domiciliazione"])},
      "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrante"])},
      "WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sito web"])},
      "REGISTRAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registratore"])},
      "BUSINESS_OWNERS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azionisti e beneficiari conosciuti"])},
      "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di nascita"])},
      "NATIONALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazionalità"])},
      "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizione"])},
      "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di identificazione"])},
      "WARNING_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista di avvertimento Meelo"])},
      "BUSINESS_REPRESENTS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirigenti e rappresentanti conosciuti"])},
      "SCORE_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli del punteggio"])},
      "COMPANY_SCORE_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio di fiducia dell'azienda"])},
      "COMPANY_FINANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanze aziendali"])},
      "COMPANY_LEGAL_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documenti legali"])},
      "ANNUAL_ACCOUNTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilanci annuali"])},
      "ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sedi"])},
      "COMPANY_EVOLUTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evoluzione aziendale"])},
      "SCORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggi finanziari"])},
      "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Reputazione"])},
      "OB_ANALYSIS_DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi dettagliata di OpenBanking"])},
      "ALERTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allarmi"])},
      "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi IBAN"])},
      "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lotta contro il riciclaggio e il terrorismo"])}
    },
    "COVER_PAGE": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporto sui controlli effettuati"])},
      "CREATED_BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File creato da"])},
      "CREATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File creato il"])},
      "DOC_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi Aziendale"])},
      "DOC_B2C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi Personale"])},
      "DOC_OB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi Open-Banking"])},
      "DOC_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi del Documento"])},
      "DOC_SYNTHESIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sintesi del Documento"])}
    }
  },
  "ENRICHMENT": {
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per avviare una ricerca di coordinate, si prega di incollare di seguito l'URL del profilo LinkedIn del vostro contatto."])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arricchimento dei dati"])},
    "NOPROFIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun profilo LinkedIn trovato."])},
    "NOCORDINATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna coordinata trovata."])},
    "TITLE_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordinate trovate :"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "EMAIL_PRO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email professionale :"])},
    "EMAIL_PERSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email personale :"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono(i):"])},
    "NO_PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun telefono trovato"])},
    "NO_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna email trovata"])},
    "MATCH": {
      "EMAIL_PERSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partita con e-mail personale inserita"])},
      "EMAIL_PRO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partita con e-mail professionale inserita"])},
      "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrisponde al telefono inserito"])}
    }
  },
  "NEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOVITÀ"])},
  "DECLARATION": {
    "FRAUD": {
      "BUTTON_SIDEBAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dichiarare una frode"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dichiarazione di frode"])},
      "EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di compilare il modulo sottostante per i casi di furto d'identità o frode confermata."])},
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Data di ricezione della richiesta fraudolenta"])},
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Tipo di caso"])},
      "MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Indirizzo email dichiarato"])},
      "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Numero di telefono dichiarato"])},
      "AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo stimato della frode"])},
      "URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL del caso"])},
      "AVOID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È stata evitata la frode ?"])},
      "TOOLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con quale/i strumento/i"])},
      "COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quali elementi ti portano a credere che si tratti di una frode ?"])},
      "CONDITION_FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" * Accetto i "])},
      "CONDITION_SECOND_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["termini generali d'uso"])},
      "CONDITION_THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Meelo relativi alla segnalazione manuale di frode"])},
      "PROFESIONAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionale"])},
      "INDIVIDUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privato"])}
    }
  },
  "RECOMMENDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni facoltative, ma altamente raccomandate"])},
  "THANKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GRAZIE"])},
  "CONFIRM_SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua dichiarazione è stata inviata con successo"])},
  "MODULE": {
    "ALTARES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio di Altare"])},
    "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCBFT"])},
    "BUSINESS_OWNERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proprietari di Attività Commerciali Effettivi"])},
    "CHECK_IBAN_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica IBAN B2B"])},
    "COMPANY_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allarmi Aziendali"])},
    "COMPANY_ANNUAL_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilanci Annuali Aziendali"])},
    "COMPANY_ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sedi Aziendali"])},
    "COMPANY_EVOLUTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evoluzione Aziendale"])},
    "COMPANY_FINANCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanze Aziendali"])},
    "COMPANY_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni Aziendali"])},
    "COMPANY_LEGAL_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documenti Legali Aziendali"])},
    "COMPANY_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio di Affidabilità Aziendale"])},
    "CREDIT_SAFE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio di Credito Sicuro"])},
    "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Reputazione"])},
    "OB_ALERTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allarmi Open Banking"])},
    "LITIGATION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato giuridico - Contenzioso"])},
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giudicato su "])},
      "DEMAND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domanda: "])},
      "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizione: "])},
      "MAIN_HEARING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main hearing"])},
      "SUMMARY_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary procedure"])},
      "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sconosciuto"])}
    },
    "FINANCIAL_RISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sezione Analisi del rischio finanziario"])}
  },
  "CARTOGRAPHY": {
    "COMPANY_COLLECTIVE_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda in Procedura Collettiva"])},
    "BUSINESS_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome Commerciale"])},
    "CARTOGRAPHY_OF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartografia di"])},
    "STUDIED_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda analizzata"])},
    "ACTIVE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda Attiva"])},
    "COLLECTIVE_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda in Procedura Collettiva"])},
    "COMPANY_CARTOGRAPHY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartografia Aziendale"])},
    "DEREGISTERED_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda Radiata"])},
    "INDIVIDUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona Fisica"])},
    "COMPANY_ACTIVITIES_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice di attività aziendale"])},
    "COMPANY_ACTIVITIES_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome dell'attività aziendale"])},
    "RECENTLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recentemente"])},
    "UNVERIFIED_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda non verificata"])},
    "OPEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrandire"])}
  },
  "MENU": {
    "MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il mio account"])},
    "MY_CONSUPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il mio consumo"])},
    "PORTAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sul portale"])},
    "LOG_AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi come"])},
    "BACK_OFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back-office"])},
    "LOGOUT_AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnettersi dall'account cliente"])},
    "LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esci"])}
  },
  "CONNECT_AS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi come "])},
    "PARAGRAPH": {
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stai per accedere come"])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dell'azienda"])},
      "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quindi vedrai il portale in base ai permessi concessi a questo utente."])},
      "FOURTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le tue azioni verranno tracciate sotto il nome del cliente e il tuo."])},
      "FIFTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore,"])},
      "SIXTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sii prudente"])},
      "SEVENTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nell'uso del portale come cliente, e"])},
      "EIGHTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rispetta la riservatezza"])},
      "NINTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dei dati dei nostri clienti."])},
      "TENTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non dimenticare di"])},
      "ELEVENTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uscire dall'account cliente"])},
      "TWELFTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["una volta completate le tue azioni."])}
    },
    "TOAST": {
      "LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sei più connesso come"])},
      "LOG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ora sei connesso come"])}
    },
    "BADGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connesso come"])},
    "MODAL": {
      "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda"])},
      "USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente"])}
    }
  },
  "PORTAL_INFORMATION": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sul portale MEELO"])},
    "SUBTITLE": {
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il portale Meelo"])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" : Una raccomandazione in breve"])}
    },
    "DESCRIPTION": {
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i prodotti Meelo a portata di mano per prendere la tua decisione in pochi minuti."])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il nostro portale di supporto alle decisioni ti permette di utilizzare i prodotti Meelo necessari per la corretta verifica del cliente che entra in contatto con te."])},
      "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio frode, verifica dell'identità, open banking, verifica dei documenti... i prodotti Meelo a portata di mano con un'integrazione tecnica minima."])}
    },
    "VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versione del tuo portale"])},
    "ENVIRONMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambiente"])},
    "PRODUCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produzione"])},
    "DEVELOPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sviluppo"])},
    "STAGING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messa in scena"])},
    "GDPR": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDPR"])},
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribuiamo grande importanza alla protezione dei vostri dati personali. In conformità con il Regolamento generale sulla protezione dei dati (GDPR), raccogliamo solo le informazioni necessarie, otteniamo il tuo consenso esplicito, utilizziamo i tuoi dati e quelli dei tuoi clienti in modo legittimo, ne garantiamo la sicurezza, ne limitiamo la conservazione e rispettiamo i tuoi diritti."])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In caso di domande o dubbi riguardanti la presente informativa sulla privacy, è possibile consultare la ns"])},
      "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" o contattare il nostro DPO, all'indirizzo "])}
    },
    "LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pagina dedicata"])}
  },
  "MAINTENANCE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I seguenti servizi potrebbero presentare problemi: "])},
    "FINISHED_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricordate di aggiornare il vostro gate per un utilizzo ottimale."])},
    "ONGOING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manutenzione in corso"])},
    "FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manutenzione terminata"])},
    "SERVICE": {
      "AML_CFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio per la lotta contro il Riciclaggio di Denaro e il Finanziamento del Terrorismo"])},
      "CHECKOUT_PROXY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio di Firma Elettronica"])},
      "DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio di Caricamento Documenti"])},
      "DOCUMENT_PROXY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio di Controllo Documenti"])},
      "DOCUMENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio di Documentazione Esterna"])},
      "IAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio di Autenticazione"])},
      "OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio di Open Banking"])},
      "PORTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portale Meelo"])},
      "RULES_ENGINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motore di Regole"])},
      "SCORING_PROXY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio di Punteggio"])},
      "TASK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio di Gestione delle Attività Interne"])},
      "TAX_NOTICE_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio di Analisi delle Comunicazioni Fiscali"])},
      "TAX_REPORT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio di Analisi delle Dichiarazioni Fiscali"])},
      "TRANSLATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio Interno di Traduzione"])},
      "SHORTENER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio Interno di Riduzione URL"])},
      "CHECK_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio di Arricchimento Aziendale"])},
      "UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio Interno di Caricamento Documenti"])},
      "TRACKETIO_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio di Analisi degli Elementi di Tracciamento Meelo"])},
      "STORAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio Interno di Archiviazione Documenti"])},
      "ALERTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio di Allerta"])},
      "TRACKETIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio di Tracciamento Utenti sul Browser"])},
      "OPEN_REVENUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio di Open Revenue"])},
      "ENRICHMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio di Arricchimento Dati"])},
      "FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio di Ricerca e Corrispondenza Immagini"])},
      "CHECK_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio di Verifica IBAN"])}
    }
  },
  "FOLDER": {
    "WORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cartella"])},
    "STATE": {
      "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In corso"])},
      "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accettato"])},
      "REFUSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifiutato"])},
      "CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creato"])},
      "WAITING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In attesa"])},
      "CANCELED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullato"])},
      "DELETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminato"])},
      "TODO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da trattare"])},
      "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])}
    },
    "DELETION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminazione della cartella"])},
      "CONTENT": {
        "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stai per eliminare il"])},
        "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e i dati che lo costituiscono"])},
        "QUESTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler eliminare questa cartella?"])},
        "QUESTION_MOTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler eliminare questo motivo?"])}
      }
    },
    "EDITION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiornamento dello stato del dossier"])},
      "CONTENT": {
        "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stai per"])},
        "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non potrai più continuare l'inserimento dei dati in questo dossier"])},
        "QUESTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler modificare lo stato del dossier?"])}
      },
      "STATE": {
        "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avviare"])},
        "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accettare"])},
        "REFUSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rifiutare"])},
        "WAITING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attendere"])},
        "CANCELED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annullare"])},
        "DELETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eliminare"])}
      }
    },
    "UPDATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato del fascicolo aggiornato"])},
    "TYPE": {
      "API_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartella API"])},
      "WEB_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartella web"])},
      "JOURNEY_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartella di viaggio"])}
    }
  },
  "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n."])},
  "NOTIFICATIONS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifiche"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna notifica."])},
    "NEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo "])},
    "READ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leggi "])},
    "MARK_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEGNA TUTTO COME LETTO"])},
    "DELETE_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELIMINA TUTTO"])},
    "DATE": {
      "TODAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OGGI"])},
      "YESTERDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IERI"])},
      "OLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VECCHIO"])}
    },
    "TIMESTAMP": {
      "NOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo ora"])},
      "PREFIX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "SUFFIX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa"])},
      "MINUTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minuto"])},
      "MINUTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minuti"])},
      "HOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ora"])},
      "HOURS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ore"])}
    },
    "ACTIONS": {
      "CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
      "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])}
    },
    "SERVICES": {
      "OPEN_BANKING": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi OpenBanking"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi OpenBanking - Fallimento dell'analisi"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'analisi OpenBanking per il dossier "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è completa."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è completo. Fare clic sulla notifica per visualizzare il risultato"])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'analisi di OpenBanking ha riscontrato un problema. Fare clic sulla notifica per visualizzare il file."])}
      },
      "LIVE_CHECK": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiarimento dubbi - Analisi completata"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiarimento dubbi - Analisi fallita"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il chiarimento dubbi del dossier "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è completato."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è completato. Clicca sulla notifica per consultare il risultato."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un problema con il chiarimento dei dubbi. Clicca sulla notifica per consultare il dossier."])}
      },
      "BATCH_CHECK_COMPANY": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi aziendale - Batch completato"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi aziendale - Batch fallito"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il batch di analisi aziendale "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è completato."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è completato."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il batch di analisi aziendale ha riscontrato un problema."])}
      },
      "BATCH_CHECK_IBAN": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi IBAN - Batch completato"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi IBAN - Batch fallito"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il batch di analisi IBAN "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è completato."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è completato."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il batch di analisi IBAN ha riscontrato un problema."])}
      },
      "BATCH_SCORING_PROXY": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring - Batch completato"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring - Batch fallito"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il batch di analisi scoring "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è completato."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è completato."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il batch di analisi scoring ha riscontrato un problema."])}
      },
      "FACE_MATCHING": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllo biometrico - Analisi completata"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllo biometrico - Analisi fallita"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il controllo biometrico del fascicolo "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è completato."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è completato. Clicca sulla notifica per visualizzare il risultato."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il controllo biometrico ha riscontrato un problema. Clicca sulla notifica per visualizzare il fascicolo."])}
      }
    }
  },
  "STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
  "OF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di"])},
  "THE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["il"])},
  "SCORE_CREDIT_SAFE": {
    "RISK_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rischio finanziario"])},
    "PAYMENT_TREND_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andamento dei pagamenti"])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun dato trovato."])},
    "OTHER_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altre informazioni aggiuntive"])},
    "PRESENCEOFJUDGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presenza di giudizio"])},
    "PRESENCEOFPRIVILEGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presenza di privilegio"])},
    "PRESENCEOFSANCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presenza della sanzione della DGCCRF"])},
    "HEADBAND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per saperne di più sulla salute finanziaria di questa azienda, attiva il tuo abbonamento CreditSafe"])},
    "RISK": {
      "WEAK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basso rischio finanziario"])},
      "MODERATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rischio finanziario moderato"])},
      "HIGH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alto rischio finanziario"])},
      "NOT_SCORED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non verificabile"])}
    },
    "PAYMENT_TREND": {
      "WORSENING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andamento dei pagamenti in peggioramento"])},
      "IMPROVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andamento dei pagamenti in miglioramento"])},
      "STABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andamento dei pagamenti stabile"])},
      "NOT_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andamento dei pagamenti non disponibile"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andamento dei pagamenti non verificabile"])}
    }
  },
  "MALICIOUS_PAYLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati di input non validi"])},
  "RESET_API_KEY": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua chiave API"])},
    "WARNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi visualizzare la tua nuova chiave API solo una volta"])},
    "CANNOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non puoi più visualizzare la tua chiave API, contatta il tuo referente o il supporto Meelo"])},
    "COPY_YOUR_KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiare la tua chiave"])}
  },
  "DQE_CONTROL": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispondenza della linea telefonica - ID mobile"])},
    "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore, la ricerca non è stata completata."])},
    "NO_SEARCH_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo numero di telefono non può essere attualmente verificato dall’operatore."])},
    "MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispondenza"])},
    "KEY": {
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
      "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di nascita"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo postale"])}
    },
    "LAUNCH_MATCHING_SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvia la ricerca di matching"])},
    "NO_GLOBAL_DECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il controllo è stato effettuato ma non può essere completato."])}
  },
  "MOTIVE": {
    "THIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione dei motivi"])},
    "REASON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo ragione"])},
    "TABLE": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichetta"])},
      "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
      "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
      "NODATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun motivo esistente."])}
    },
    "MODAL": {
      "CREATE_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiunta di un motivo"])},
      "UPDATE_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica di un motivo"])},
      "DELETE_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminazione di un motivo"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichetta"])},
      "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
      "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inattivo"])},
      "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
      "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])}
    },
    "TOAST": {
      "SUCCESS": {
        "CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modello creato con successo"])},
        "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modello modificato con successo"])},
        "DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modello eliminato con successo"])}
      },
      "FAILED": {
        "CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore durante la creazione del modello"])},
        "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore durante la modifica del modello"])}
      }
    }
  },
  "CHECK_LIST": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sintesi Globale"])},
    "MEELO_RECOMMENDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raccomandazione Meelo"])},
    "RULES": {
      "credit-safe-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio Finanziario"])},
      "checklist-web-score-b2b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio di Fiducia Aziendale"])},
      "checklist-smb-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio di Fiducia Identità"])},
      "company-radiation-and-collective-proceedings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda Cancellata o in Procedura Collettiva"])},
      "id-document-analysis-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documenti di Identità"])},
      "document-authenticity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticità del Documento"])},
      "check-iban-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "phone-carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono a Rischio"])},
      "warning-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista di Avviso"])},
      "checklist-web-score-b2c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio di Fiducia Identità"])},
      "ob-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio OpenBanking"])},
      "e-reputation-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Reputation"])}
    },
    "RECOMMENDATION": {
      "DECISION": {
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buona"])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
        "risky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rischiosa"])}
      },
      "MESSAGE": {
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il fascicolo non contiene avvisi; le verifiche degli elementi del fascicolo sono state completate ed è possibile procedere."])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il fascicolo contiene alcune avvertenze; ti consigliamo di essere prudente e di effettuare un controllo approfondito (ad esempio, ricontattare il datore di lavoro, il contabile)."])},
        "risky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il fascicolo contiene un avvertimento importante; si consiglia vivamente di effettuare un controllo approfondito prima di procedere positivamente con questo fascicolo."])}
      }
    },
    "NUMBER_OF_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di verifiche effettuate"])}
  },
  "CLIENT_CONTEXT": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contesto"])},
    "COMPANIES": {
      "MEELO": {
        "INIT_MEELO": {
          "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
          "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
          "maidenName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome da nubile"])},
          "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civiltà"])},
          "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di nascita"])},
          "socialSecurityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di previdenza sociale"])},
          "familySituation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situazione familiare"])},
          "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo email"])},
          "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di telefono"])},
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
          "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° e via"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città"])},
          "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice postale"])},
          "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese"])},
          "disabledWorker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lavoratore disabile"])}
        },
        "CLIENT_MEELO": {
          "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
          "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
          "maidenName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome da nubile"])},
          "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civiltà"])},
          "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di nascita"])},
          "socialSecurityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di previdenza sociale"])},
          "familySituation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situazione familiare"])},
          "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo email"])},
          "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di telefono"])},
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
          "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° e via"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città"])},
          "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice postale"])},
          "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese"])},
          "disabledWorker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lavoratore disabile"])}
        }
      },
      "VORWERK": {
        "INIT_VORWERK": {
          "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
          "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
          "maidenName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome da nubile"])},
          "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civiltà"])},
          "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di nascita"])},
          "socialSecurityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di previdenza sociale"])},
          "familySituation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situazione familiare"])},
          "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo email"])},
          "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di telefono"])},
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
          "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° e via"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città"])},
          "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice postale"])},
          "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese"])},
          "disabledWorker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lavoratore disabile"])}
        },
        "CLIENT_VORWERK": {
          "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
          "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
          "maidenName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome da nubile"])},
          "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civiltà"])},
          "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di nascita"])},
          "socialSecurityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di previdenza sociale"])},
          "familySituation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situazione familiare"])},
          "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo email"])},
          "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di telefono"])},
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
          "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° e via"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città"])},
          "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice postale"])},
          "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese"])},
          "disabledWorker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lavoratore disabile"])}
        }
      }
    }
  },
  "SSO": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizza un’altra soluzione di accesso"])}
  },
  "OR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O"])}
}