<template>
  <div class="synthesis">
    <PrintCoverPage :doc-creator="dataFolder.analyst" :doc-creation-date="dataFolder.createdAt"
                    :contextData="contextData" />
    <div class="pagebreak">
      <BoxOutputCheckList v-if="hasCheckList && (isScoreFraud || canShowBoxOutputCompanyInfo)" :data="checkList" />
      <div :class="`${isScoreSmb || isScoreCompany ? 'synthesis-informations' : 'synthesis-informations-b2c'}`">
        <ContentCard v-if="folder.journeyId.value" class="synthesis-folder" :title="translate('TABLE.FOLDER')">
          <LabelValuePrinter v-for="data in folder" :label="data.text" :value="data.value" />
        </ContentCard>
        <ContentCard v-if="isScoreSmb || isScoreCompany" class="synthesis-applicant" :title="translate('APPLICANT')">
          <div class="synthesis-applicant-left-block">
            <div v-if="applicantData || contextData.firstname_synthesis"
                 class="synthesis-applicant-left-block-applicant">
              <LabelValuePrinter v-for="applicant in getApplicantDataB2B()"
                                 :label="applicant.label"
                                 :value="applicant.value"
                                 :copyToClipboard="applicant.copy_to_clipboard" />
            </div>
          </div>
        </ContentCard>
      </div>
      <ContentCard v-if="isScoreFraud" class="synthesis-applicant-b2c" :title="translate('APPLICANT')">
        <template v-for="(firstname, index) in contextData.firstname_synthesis" :key="index">
          <div class="synthesis-applicant-b2c-left-block">
            <div class="synthesis-applicant-b2c-left-block-applicant">
              <LabelValuePrinter v-for="applicant in getApplicantDataB2C(firstname, index)"
                                 :label="applicant.label"
                                 :value="applicant.value"
                                 :copyToClipboard="applicant.copy_to_clipboard" />
            </div>
          </div>
        </template>
        <template v-for="score in scoreFraudData">
          <div class="synthesis-applicant-b2c-right-block">
            <div class="synthesis-applicant-b2c-right-block-score">
              <span class="synthesis-applicant-b2c-right-block-score__title">{{ translate('CONFIDENCE_INDEX') }}</span>
              <ScoreCircleChart
                class="circle-chart score-fraud"
                :circle-value="Math.round(score)"
                :circle-width="'32%'"
                :circle-color="colorB2C"
                :helper-text-display="true"
                circle-title="" tooltip="" type-score="" />
            </div>
          </div>
        </template>
      </ContentCard>
      <BoxOutputCompanyInfo
        v-if="canShowBoxOutputCompanyInfo"
        :show-information-company-content="checkCompanyLegalUnitData"
        :check-company="companyInfoTransformer(checkCompanyCountryData, { ...checkCompanyLegalUnitData, companyCountry: checkCompanyCountryData })"
        :company-causes="causesB2BDataV?.company"
        :permissions="permissionsSorted"
        :can-company-info="companyInfoBlock"
        class="pagebreak"
      />
      <BoxOutputWarningListRegistrationNumber
        v-if="isCompanyWarningList"
        :is-loading="false"
        :siren-warning-list="isCompanyWarningList"
        class="warning-list warning-list-siren" />
      <template v-else>
        <OutputWarningList
          v-if="canShowOutputWarningList"
          :warning-list="getWarningListData()" />
      </template>
      <div v-if="!isScoreFraud" class="company-document">
        <BoxOutputOwnerRepresentative
          v-if="canShowBoxOutputForBusinessOwner"
          :owner-representatives="businnessOwnerTransformer(checkCompanyCountryData, checkCompanyShareHoldersData)"
          :permission-to-select="permissionsSorted.includes('KIT_B2B_BUSINESS_OWNERS_SELECTION')"
          :can-person="businessOwnersBlock"
          :title="translate('COMPANY.BUSINESS_OWNERS_KNOWN')"
          :type="'businessOwner'" />
        <BoxOutputOwnerRepresentative
          v-if="canShowBoxForRepresentative"
          :owner-representatives="representativeTransformer(checkCompanyCountryData, checkCompanyRepresentativesData)"
          :permission-to-select="permissionsSorted.includes('KIT_B2B_REPRESENTATIVES_SELECTION')"
          :can-person="representativesBlock" :title="translate('COMPANY.REPRESENTATIVES_KNOWN')"
          :type="'representative'" />
      </div>
      <ScoringDetailedBox
        v-if="!isNil(causesFraudData)"
        v-for="(causes, index) in contextData?.causes_web_score_b2c"
        :key="index"
        :customer-profile="prefillClientForm"
        :causes-fraud="causes"
        :email="contextData.email_synthesis[index]"
        :phone="contextData.phone_synthesis[index]"
        :can-scoring="scoringDetailedBoxBlock"
        :permissions="permissionsSorted"
        :search-image="searchImageProfilesData"
        :company="user?.company?.name"
        :dqe-loader="false"
        :dqe-control-result="dqeControlData"
      />
      <template v-if="isScoreSmb" v-for="(score, index) in scoreSMBData" :key="index">
        <BoxOutputScoreSMB
          :scoreSMB="score"
          :causesSMB="contextData?.smb_causes[index]"
          :applicant="applicantData"
          :color="colorSMB"
          :permissions="permissionsSorted" :can-score-s-m-b="scoreSMBBlock"
          :email="contextData.email_synthesis[index]"
          :phone="contextData.phone_synthesis[index]"
          :search-image="searchImageProfilesData"
          class="pagebreak"
          :display-multi-requests="!causesB2BDataV"
          :company="user?.company?.name"
        />
      </template>
      <BoxOutputScoreCompany
        v-if="isScoreCompany"
        :scoringB2B="scoreB2BDataV"
        :check-company="checkCompanyDataCompany"
        :causesB2B="causesB2BDataV"
        :color="colorB2B" :permissions="permissionsSorted" :permissions-company-alert="permissionsCompanyAlert"
        :can-company-score="companyScoreBlock"
        class="score-company pagebreak"
        :display-multi-requests="causesB2BDataV"
        :company="user?.company?.name"
      />
      <BoxOutputCompanyLitigation
        v-if="causesB2BDataV && isCountryFR" class="litigation-document" :data="causesB2BDataV"
        :can-company-litigation="companyLitigationBlock" />
      <BoxOutputCompanyCartography v-if="causesB2BDataV" :dataChart="causesB2BDataV.graph"
                                   :can-cartography="cartographyBlock" />
      <CheckIbanBox
        v-if="checkIbanData"
        :check-iban="checkIbanData"
        :birthdate="contextData?.applicant?.birthDate"
        :is-france="isCountryFR || isCountryFR && checkIbanData?.subType === 'COMPANY_IBAN' || checkIbanData?.subType === 'PERSONAL_IBAN'"
        :can-check-iban="permissionsSorted.includes('KIT_B2C_CHECK_IBAN_B2C_GLOBAL') || permissionsSorted.includes('KIT_B2B_CHECK_IBAN_B2B_GLOBAL')"
        class="pagebreak" />
      <BoxOutputAmlcft
        v-if="amlCftMatchesData"
        :amlcft="amlCftMatchesData[0]"
        :show-content="amlCftMatchesData"
        :can-amlcft="canAmlcft"
      />
      <BoxOutputDoc
        v-if="hasDocument"
        :can-show="true"
        :desc="translate('DOCUMENT_CONTROL.ID_ANALYSIS_DESCRIPTION')"
        :is-loading="false"
        :context-data="contextData"
        :can-upload-document="false"
        :can-restitute-document="true"
        title="DOCUMENT_CONTROL.ID_ANALYSIS_TITLE"
        doc-category="ID"
      />
      <BoxOutputDoc
        v-if="hasDocument"
        :can-show="true"
        :desc="translate('DOCUMENT_CONTROL.DOCUMENT_ANALYSIS_DESCRIPTION')"
        :is-loading="false"
        :context-data="contextData"
        :can-upload-document="false"
        :can-restitute-document="true"
        doc-category="OTHER"
        title="DOCUMENT_CONTROL.DOCUMENT_ANALYSIS_TITLE"
      />
      <BoxOutputCompanyFinances
        v-if="canShowBoxOutputCompanyFinances"
        :tax-reports="taxReportsSummaryData"
        :loader="taxReportLoading"
        :can-company-finances="companyFinancesBlock"
        class="pagebreak"
      />
      <div v-if="!isScoreFraud" class="company-document">
        <BoxOutputCompanyLegalDoc
          v-if="canShowBoxOutputCompanyLegalDoc"
          :legal-doc="checkCompanyAttachmentsData?.legalActs"
          :registration-number="checkCompanyLegalUnitData.registrationNumber"
          :journey-id="journeyId"
          :can-company-legal-doc="companyLegalDocBlock"
          class="pagebreak" />
        <BoxOutputAnnualAccounts
          v-if="canShowBoxOutputAnnualAccounts"
          :tax-reports="checkCompanyAttachmentsData?.taxReports"
          :journey-id="journeyId"
          :can-annual-accounts="annualAccountsBlock"
          class="pagebreak"
        />
      </div>
      <div v-if="!isScoreFraud" class="company-document">
        <BoxOutputEstablishments
          v-if="canShowBoxOutputEstablishments"
          :establishments="establishmentTransformer(checkCompanyCountryData, checkCompanyEstablishmentsData)"
          :can-establishments="establishmentsBlock"
          class="pagebreak" />
        <BoxOutputBodaccAnnouncements
          v-if="canShowBoxOutputBodaccAnnouncements"
          :announcements="bodaccAnnouncementTransformer(checkCompanyCountryData, checkCompanyLegalObservationData)"
          :can-bodacc-announcements="bodaccAnnouncementsBlock"
          class="pagebreak"
        />
      </div>
      <BoxOutputScoreCreditSafe
        v-if="causesCreditSafeData && scoreCreditSafeData"
        :score-credit-safe="scoreCreditSafeData"
        :causes-credit-safe="causesCreditSafeData"
        :is-company-f-r="isCountryFR"
        :color-credit-safe-loc="colorCreditSafeLoc"
        :color-credit-safe-inter="colorCreditSafeInter"
        :can-credit-safe="permissionsSorted.includes('KIT_B2B_CREDIT_SAFE_GLOBAL')"
        class="pagebreak" />
      <!--    <BoxOutputEnrichment v-if="size(enrichmentData) > 0" :enrichment="enrichmentData" :client-form="prefillClientForm"
                               :can-enrichment="canEnrichment"/>-->
      <BoxOutputAccountantVerification
        v-if="canShowBoxOutputAccountantVerification"
        :warning-list="warningListCombinedData"
        :accountant-verification-data="accountantVerificationData"
        :is-loading="false"
      />
      <BoxOutputEReputation v-if="canShowBoxOutputEReputation" :scoring-e-reputation="scoreEReputationData"
                            :color="colorEReputation" :e-reputation-data="causesEReputationData"
                            :permissions="permissionsSorted" :can-e-reputation="eReputationBlock" />
      <BoxOutputOpenBanking v-if="(Object.keys(obFormattedFields).length > 0)" :ob-data="obFormattedFields"
                            :color-score="colorScoreOB" :can-open-banking="openBankingResumeBlock" />
      <BoxOutputOpenBankingDetail class="pagebreak"
                                  v-if="(Object.keys(obFormattedFields).length > 0) && checkDataAvailability"
                                  :ob-data="obFormattedFields" :permissions="permissionsSorted"
                                  :can-open-banking="openBankingDetailBlock" />
      <BoxOutputOpenBankingAlert v-if="(Object.keys(obFormattedFields).length > 0) && checkDataAvailability"
                                 :ob-data="obFormattedFields" :can-open-banking="openBankingAlertBlock"
                                 class="pagebreak" />
      <BoxOutputCheckEmployer v-if="canShowBoxOutputCheckEmployer" :check-employer-data="checkCompanyLegalUnitData"
                              :check-warning-list="warningListData"
                              :check-procedure="checkEmployerCollectiveProceedingsData" />
      <BoxOutputClientContext v-if="folder.journeyId.value" :journey-id="journeyId" :toggle-expanded="true" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ContentCard from '@/components/card/ContentCard.vue';
import { formatLocaleDate, locale, translate } from '@/i18n';
import LabelValuePrinter from '@/components/LabelValuePrinter.vue';
import ScoreCircleChart from '@/components/ScoreCircleChart.vue';
import VerificationResult from '@/components/VerificationResult.vue';
import ScoringDetailedBox from '@/components/scoring-detailed-box/ScoringDetailedBox.vue';
import ScoringDetailedBoxSMB from '@/components/scoring-detailed-box/ScoringDetailedBoxSMB.vue';
import ScoringAltares from '@/components/box-output/BoxOutputScoreAltares.vue';
import Button from '@/components/button/Button.vue';
import TextInput from '@/components/input/TextInput.vue';
import Loader from '@/components/loader/Loader.vue';
import OBTable from '@/components/OBTable.vue';
import AmlcftResult from '@/components/box-output/BoxOutputAmlcft.vue';
import BoxOutputAmlcft from '@/components/box-output/BoxOutputAmlcft.vue';
import templateMixin from '@/mixins/templateMixin';
import openBankingMixin from '@/mixins/openBankingMixin';
import BoxOutputScoreSMB from '@/components/box-output/BoxOutputScoreSMB.vue';
import BoxOutputCheckIbanB2b from '@/components/box-output/BoxOutputCheckIbanB2b.vue';
import CheckIbanResult from '@/components/check-iban/CheckIbanResult.vue';
import CheckIbanBox from '@/components/check-iban/CheckIbanBox.vue';

import BoxOutputOpenBankingAlert from '@/components/box-output/BoxOutputOpenBankingAlert.vue';
import BoxOutputOpenBankingDetail from '@/components/box-output/BoxOutputOpenBankingDetail.vue';
import BoxOutputOpenBanking from '@/components/box-output/BoxOutputOpenBanking.vue';
import BoxOutputEReputation from '@/components/box-output/BoxOutputEReputation.vue';
import utilsMixin from '@/mixins/utilsMixin';
import BoxOutputCompanyInfo from '@/components/box-output/BoxOutputCompanyInfo.vue';
import BoxOutputScoreCompany from '@/components/box-output/BoxOutputScoreCompany.vue';
import BoxOutputCompanyFinances from '@/components/box-output/BoxOutputCompanyFinances.vue';
import BoxOutputAnnualAccounts from '@/components/box-output/BoxOutputAnnualAccounts.vue';
import BoxOutputCompanyLegalDoc from '@/components/box-output/BoxOutputCompanyLegalDoc.vue';
import BoxOutputScoreAltares from '@/components/box-output/BoxOutputScoreAltares.vue';
import app from '../App.vue';
import BoxOutputEstablishments from '@/components/box-output/BoxOutputEstablishments.vue';
import BoxOutputBodaccAnnouncements from '@/components/box-output/BoxOutputBodaccAnnouncements.vue';
import BoxOutputEnrichment from '@/components/box-output/BoxOutputEnrichment.vue';
import BoxInputEnrichment from '@/components/box-input/BoxInputEnrichment.vue';
import { formatDocumentBeforePrint } from '@/utils/printSynthesis';
import OutputWarningList from '@/components/box-output/BoxOutputWarningList.vue';
import { getFormattedRegistrationNumber } from '@/utils/formatRegistrationNumber';
import BoxOutputDocument from '@/components/box-output/BoxOutputDocument.vue';
import BoxOutputDoc from '@/components/BoxOutputDoc.vue';
import { getBankName } from '@/apiCalls/checkIban';
import BoxOutputCompanyCartography from '@/components/box-output/BoxOutputCompanyCartography.vue';
import { isArray, isEmpty, isNil, isNull, size } from 'lodash';
import BoxOutputCheckEmployer from '@/components/box-output/BoxOutputCheckEmployer.vue';
import BoxOutputCompanyLitigation from '@/components/box-output/BoxOutputCompanyLitigation.vue';
import BoxOutputScoreCreditSafe from '@/components/box-output/BoxOutputScoreCreditSafe.vue';
import { formatLocaleDateTimeUTC } from '@/utils/dateTime';
import { isDataAvalaible } from '@/utils/utils';
import BoxOutputOwnerRepresentative from '@/components/box-output/BoxOutputOwnerRepresentative.vue';
import BoxOutputWarningListRegistrationNumber from '@/components/box-output/BoxOutputWarningListRegistrationNumber.vue';
import PrintCoverPage from '@/components/print/PrintCoverPage.vue';
import {
  bodaccAnnouncementTransformer
} from '@/utils/dataTransformer/config/bodaccAnnouncement/bodaccAnnouncementConfig';
import { businnessOwnerTransformer } from '@/utils/dataTransformer/config/businessOwner/businessOwnerConfig';
import { companyInfoTransformer } from '@/utils/dataTransformer/config/companyInfo/companyInfoConfig';
import { establishmentTransformer } from '@/utils/dataTransformer/config/establishment/establishmentConfig';
import { representativeTransformer } from '@/utils/dataTransformer/config/representative/representativeConfig';
import BoxOutputCheckList from '@/components/box-output/BoxOutputCheckList.vue';
import BoxOutputClientContext from '@/components/box-output/BoxOutputClientContext.vue';
import BoxOutputAccountantVerification from '@/components/box-output/BoxOutputAccountantVerification.vue';

export default defineComponent({
  name: 'Synthesis',
  computed: {
    app() {
      return app;
    },
    hasDocument() {
      return ((this.contextData.kbis_data && this.contextData.kbis_data?.length > 0)
        || (this.contextData.tax_notice_data && this.contextData.tax_notice_data?.length > 0)
        || (this.contextData.tax_report_data && this.contextData.tax_report_data?.length > 0)
        || (this.contextData.tax_notice_analysis && this.contextData.tax_notice_analysis?.length > 0)
        || (this.contextData.authenticity_result && this.contextData.authenticity_result?.length > 0)
        || (this.contextData.payslip_data && this.contextData.payslip_data?.length > 0)
        || (this.contextData.id_check_data && this.contextData.id_check_data?.length > 0)
        || (this.contextData.face_matching && this.contextData.face_matching?.length > 0));
    },
    isCompanyWarningList() {
      return this.permissionsSorted?.includes('KIT_B2B_WARNING_LIST_GLOBAL')
        && !!this.warningListData?.find((warning: any) => {
          return isArray(warning) && (warning.includes('SIREN') || warning.includes('REGISTRATION_NUMBER'));
        });
    },
    canShowBoxOutputForBusinessOwner() {
      return this.checkCompanyLegalUnitData || (this.checkCompanyShareHoldersData && this.checkCompanyShareHoldersData.length > 0);
    },
    canShowBoxForRepresentative() {
      return this.checkCompanyLegalUnitData || (this.checkCompanyRepresentativesData && this.checkCompanyRepresentativesData.length > 0);
    },
    canShowOutputWarningList() {
      if (this.isScoreSmb || this.isScoreCompany) {  //B2B
        return this.permissionsSorted?.includes('KIT_B2B_WARNING_LIST_GLOBAL');
      } else if (this.isScoreFraud) {   //B2C
        return this.permissionsSorted?.includes('KIT_B2C_WEB_SCORE_WARNING_LIST');
      }
      return false;
    },
    canShowBoxOutputCompanyInfo() {
      return this.checkCompanyLegalUnitData && isDataAvalaible(this.checkCompanyLegalUnitData) && !this.isScoreFraud;
    },
    canShowBoxOutputCompanyFinances() {
      return (this.checkCompanyCountryData !== 'ES') && ((this.checkCompanyLegalUnitData && isDataAvalaible(this.checkCompanyLegalUnitData))
        || (this.checkCompanyLegalUnitData && isDataAvalaible(this.checkCompanyLegalUnitData)
          && this.taxReportsSummaryData) && !this.isScoreFraud);
    },
    canShowBoxOutputCompanyLegalDoc() {
      return (this.checkCompanyCountryData !== 'ES') && ((this.checkCompanyLegalUnitData && isDataAvalaible(this.checkCompanyLegalUnitData))
        || (this.checkCompanyAttachmentsData && isDataAvalaible(this.checkCompanyAttachmentsData)));
    },
    canShowBoxOutputAnnualAccounts() {
      return (this.checkCompanyCountryData !== 'ES') && ((this.checkCompanyLegalUnitData && isDataAvalaible(this.checkCompanyLegalUnitData))
        || (this.checkCompanyAttachmentsData && isDataAvalaible(this.checkCompanyAttachmentsData)));
    },
    canShowBoxOutputEstablishments() {
      return (this.checkCompanyLegalUnitData && isDataAvalaible(this.checkCompanyLegalUnitData))
        || (this.checkCompanyEstablishmentsData && isDataAvalaible(this.checkCompanyEstablishmentsData));
    },
    canShowBoxOutputBodaccAnnouncements() {
      return (this.checkCompanyLegalUnitData && isDataAvalaible(this.checkCompanyLegalUnitData))
        || (this.checkCompanyLegalObservationData && isDataAvalaible(this.checkCompanyLegalObservationData));
    },
    canShowBoxOutputEReputation() {
      return (this.checkCompanyLegalUnitData || (this.causesEReputationData && this.scoreEReputationData));
    },
    canShowBoxOutputAccountantVerification() {
      return !isEmpty(this.accountantVerificationData);
    },
    canAmlcft() {
      return (this.isScoreFraud && this.permissionsSorted.includes('KIT_B2C_AMLCFT_GLOBAL'))
        || ((this.isScoreSmb || this.isScoreCompany || this.checkCompanyLegalUnitData) && this.permissionsSorted.includes('KIT_B2B_AMLCFT_GLOBAL'));
    },
    canEnrichment() {
      return this.checkCompanyLegalUnitData
        ? this.permissionsSorted.includes('KIT_B2B_ENRICHMENT_GLOBAL')
        : this.permissionsSorted.includes('KIT_B2C_ENRICHMENT_GLOBAL');
    }
  },
  mixins: [templateMixin, openBankingMixin, utilsMixin],
  methods: {
    isNil,
    size,
    formatLocaleDate,
    getFormattedRegistrationNumber,
    translate,
    isEmpty,
    isDataAvalaible,
    getApplicantDataB2C(firstname: string, index: number) {
      const isApplicantData = this.applicantData && Object.keys(this.applicantData).length > 0;
      return {
        name: {
          label: translate('IDENTITY.NAME'),
          value: isApplicantData ? this.applicantData.lastname : (this.contextData.lastname_synthesis.length > 0 ? this.contextData.lastname_synthesis[index] : '-'),
          copy_to_clipboard: false
        },
        firstname: {
          label: translate('IDENTITY.FIRSTNAME'),
          value: isApplicantData ? this.applicantData.firstname : this.contextData.firstname_synthesis.length > 0 ? firstname : '-',
          copy_to_clipboard: false
        },
        birthdate: {
          label: translate('IDENTITY.BIRTHDATE'),
          value: isApplicantData && this.applicantData.birthDate
            ? formatLocaleDate(this.applicantData.birthDate)
            : this.contextData.birthdate_synthesis && this.contextData.birthdate_synthesis.length > 0 && this.contextData.birthdate_synthesis[index] ? formatLocaleDate(this.contextData.birthdate_synthesis[index]) : '-',
          copy_to_clipboard: false
        },
        email: {
          label: translate('IDENTITY.EMAIL'),
          value: isApplicantData
            ? this.applicantData.email
            : this.contextData.email_synthesis && this.contextData.email_synthesis.length > 0 ? this.contextData.email_synthesis[index] : '-',
          copy_to_clipboard: this.canCopyToClipboard(isApplicantData, this.contextData.email_synthesis)
        },
        phone: {
          label: translate('IDENTITY.PHONE_NUMBER'),
          value: isApplicantData ? this.applicantData.mobilePhone : this.contextData.phone_synthesis.length > 0 ? this.contextData.phone_synthesis[index] : '-',
          copy_to_clipboard: this.canCopyToClipboard(isApplicantData, this.contextData.phone_synthesis)
        },
        iban: {
          label: translate('IDENTITY.IBAN'),
          value: isApplicantData && this.applicantData.hasOwnProperty('iban') ? this.applicantData.iban : '-',
          copy_to_clipboard: isApplicantData && this.applicantData.hasOwnProperty('iban')
        }
      };
    },
    getApplicantDataB2B() {
      return {
        name: {
          label: translate('IDENTITY.NAME'),
          value: !isNil(this.applicantData) ? this.applicantData.lastname : this.contextData.lastname_synthesis ? this.contextData.lastname_synthesis[0] : '-',
          copy_to_clipboard: false
        },
        firstname: {
          label: translate('IDENTITY.FIRSTNAME'),
          value: !isNil(this.applicantData) ? this.applicantData.firstname : this.contextData.firstname_synthesis ? this.contextData.firstname_synthesis[0] : '-',
          copy_to_clipboard: false
        },
        birthdate: {
          label: translate('IDENTITY.BIRTHDATE'),
          value: !isNil(this.applicantData) && this.applicantData.birthDate !== '' ? formatLocaleDate(this.applicantData.birthDate) : !isNil(this.contextData.birthdate_synthesis) && !isNull(this.contextData.birthdate_synthesis[0]) && (this.contextData.birthdate_synthesis[0] !== '') ? formatLocaleDate(this.contextData.birthdate_synthesis[0]) : '-',
          copy_to_clipboard: false
        },
        email: {
          label: translate('IDENTITY.EMAIL'),
          value: !isNil(this.applicantData) ? this.applicantData.email : this.contextData.email_synthesis ? this.contextData.email_synthesis[0] : '-',
          copy_to_clipboard: this.canCopyToClipboard(!isNil(this.applicantData), this.contextData.email_synthesis)
        },
        phone: {
          label: translate('IDENTITY.PHONE_NUMBER'),
          value: !isNil(this.applicantData) ? this.applicantData.mobilePhone : this.contextData.phone_synthesis ? this.contextData.phone_synthesis[0] : '-',
          copy_to_clipboard: this.canCopyToClipboard(!isNil(this.applicantData), this.contextData.phone_synthesis)
        },
        iban: {
          label: translate('IDENTITY.IBAN'),
          value: this.applicantData && this.applicantData.iban ? this.applicantData.iban : '-',
          copy_to_clipboard: this.canCopyToClipboard(this.applicantData, this.applicantData?.iban)
        }
      };
    },
    canCopyToClipboard(data: any, value: any) {
      return data || value && value.length > 0;
    },
    getWarningListData() {
      if (this.causesB2BDataV) return this.causesB2BDataV.warningList; //if both scores -> return B2B WL
      else if (this.causesSMBData) return this.causesSMBData.warningList;
      return this.causesFraudData ? this.causesFraudData.warning_list : {};
    },
    bodaccAnnouncementTransformer,
    businnessOwnerTransformer,
    companyInfoTransformer,
    establishmentTransformer,
    representativeTransformer
  },
  components: {
    BoxOutputClientContext,
    BoxOutputCheckList,
    BoxOutputWarningListRegistrationNumber,
    BoxOutputOwnerRepresentative,
    BoxOutputScoreCreditSafe,
    BoxOutputCompanyLitigation,
    BoxOutputCheckEmployer,
    BoxOutputDocument,
    OutputWarningList,
    BoxInputEnrichment,
    BoxOutputBodaccAnnouncements,
    BoxOutputEstablishments,
    BoxOutputScoreAltares,
    BoxOutputCompanyLegalDoc,
    BoxOutputAnnualAccounts,
    BoxOutputCompanyFinances,
    BoxOutputScoreCompany,
    BoxOutputCompanyInfo,
    BoxOutputEReputation,
    BoxOutputOpenBanking,
    BoxOutputOpenBankingDetail,
    BoxOutputOpenBankingAlert,
    BoxOutputCheckIbanB2b,
    BoxOutputScoreSMB,
    BoxOutputAmlcft,
    CheckIbanResult,
    AmlcftResult,
    OBTable,
    Loader,
    TextInput,
    Button,
    ScoringAltares,
    ScoringDetailedBoxSMB,
    ScoringDetailedBox,
    VerificationResult,
    ScoreCircleChart,
    LabelValuePrinter,
    ContentCard,
    BoxOutputEnrichment,
    BoxOutputCompanyCartography,
    BoxOutputDoc,
    PrintCoverPage,
    CheckIbanBox,
    BoxOutputAccountantVerification
  },
  data: () => ({
    folder: {
      createdAt: { value: '', text: '' },
      state: { value: '', text: '' },
      analyst: { value: '', text: '' },
      journeyId: { value: '', text: '' },
      motive: { value: '', text: '' },
      description: { value: '', text: '' }
    },
    prefillClientForm: {
      lastName: { disabled: false, value: '' },
      firstNames: { disabled: false, value: '' },
      phone: { disabled: false, value: '+33' },
      email: { disabled: false, value: '' },
      companyName: { disabled: false, value: '' },
      city: { disabled: false, value: '' },
      country: { disabled: false, value: '' },
      zipcode: { disabled: false, value: '' },
      street: { disabled: false, value: '' },
      birthDate: { disabled: false, value: '' },
      iban: { disabled: false, value: '' }
    } as any,
    colorScoreOB: ''
  }),
  created() {
    window.addEventListener('beforeprint', formatDocumentBeforePrint);
  },
  watch: {
    applicantData(data: any) {
      this.prefillClientForm.email.value = data?.email;
      this.prefillClientForm.phone.value =
        data?.mobilePhone && data.mobilePhone.length >= 5 ? data.mobilePhone : null;
      this.prefillClientForm.firstNames.value = data?.firstname;
      this.prefillClientForm.lastName.value = data?.lastname;
      this.prefillClientForm.birthDate.value = data?.birthDate ? data.birthDate.split('T')[0] : null;
      const address = data?.addresses ? data.addresses[0] : {};
      this.prefillClientForm.street.value = address?.street1;
      this.prefillClientForm.city.value = address?.city;
      this.prefillClientForm.zipcode.value = address?.zipcode;
      this.prefillClientForm.country.value = address?.country;
      this.prefillClientForm.iban.value = data?.iban;
    },
    async obFormattedFields() {
      this.colorScoreOB = await this.defineColor(
        'score_ob',
        this.obFormattedFields.score,
        this.journeyId
      );
    },
    async 'contextData.check_iban_details'(newValue: any) {
      const iban = this.prefillClientForm.iban.value;
      if (iban && newValue.length > 0) {
        const bank = await getBankName(iban, this.journeyId);
        this.contextData.check_iban_details[0] = Object.assign({}, this.contextData.check_iban_details[0], {
          bank: {
            ...bank.data,
            iban
          }
        });
      }
    },
    dataFolder() {
      this.folder = {
        analyst: {
          value: this.dataFolder.analyst ? this.dataFolder.analyst : '-',
          text: translate('SYNTHESIS.ANALYST')
        },
        createdAt: {
          value: this.dataFolder.createdAt ? formatLocaleDateTimeUTC((this.dataFolder.createdAt).toLocaleString(locale)) : '-',
          text: translate('COMPANY.CREATED_DATE')
        },
        state: {
          value: this.dataFolder.state ? translate(`FOLDER.STATE.${this.dataFolder.state}`) : '-',
          text: translate('SYNTHESIS.FOLDER_STATE')
        },
        motive: {
          value: this.motive ? this.motive.label || '-' : '-',
          text: translate('MOTIVE.THIS')
        },
        description: {
          value: this.motive ? this.motive.description || '-' : '-',
          text: translate('MOTIVE.REASON')
        },
        journeyId: {
          value: `${this.businessId} (${this.journeyId})`,
          text: translate('SYNTHESIS.JOURNEY_ID')
        }
      };
    }
  }
});
</script>